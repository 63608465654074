import React, { useEffect } from "react";
import { Box, Center, usePrevious } from "@chakra-ui/react";
import { CellProps } from "@/cells/cell.types";
import { ConfigType } from ".";
import { useDataStreamQuery } from "./randomTimeseriesChartCell.slice";
import { useAuth } from "@/hooks/auth/auth.hooks";
import deepcopy from "deepcopy";
import { useHighchartStock } from "@/hooks/highcharts/useHighchartStock";
import { assert } from "tsafe";
import { Chart } from "highcharts";
import * as DashboardDefaultFieldConfigModel from "@/models/dashboard/dashboard-default-field-config.model";
import * as DashboardUtilities from "@/utilities/dashboard";

export interface Props<S> extends CellProps<S> {}

export const RandomTimeseriesChartCell = <S extends ConfigType>(
  props: Props<S>
) => {
  const { explicitConfig, dashboardLevelConfig, globalLevelConfig } = props;

  const { token } = useAuth();

  const { data: message } = useDataStreamQuery(
    {
      ...DashboardUtilities.consolidateCellConfig(
        explicitConfig,
        dashboardLevelConfig,
        globalLevelConfig,
      ),
      auth_token: token,
    },
    {
      // Note: this is super important here because it gets the initial data
      // for the pop-out version of the chart when it first opens
      refetchOnMountOrArgChange: true,
    }
  );

  // Get highchart reference
  const [chart, chartRef] = useHighchartStock<HTMLDivElement>({
    title: {
      text: "",
    },
    chart: {
      plotShadow: false,
      reflow: true,
    },
    series: [
      {
        type: "line",
        data: [],
        tooltip: {
          valueDecimals: 2,
        },
      },
    ],
  });

  const prevChart = usePrevious<Chart | null>(chart);

  // Update the chart data
  useEffect(() => {
    if (!message || !chart) return;

    const series = chart!.series[0];

    const doChartInit = prevChart === null;
    const initialDataMessage = message.newDataPoints === null;

    // An initial data message was received or the chart needs to be initialized
    // (i.e. we just opened it in a popup window), so initialize the chart.
    if (doChartInit || initialDataMessage) {
      series.update({
        type: "line",
        data: deepcopy(message.allDataPoints) as any,
      });
    }
    // Otherwise new data points were received, just update the chart with them
    else {
      assert(message.newDataPoints !== null);
      for (let i = 0; i < message.newDataPoints.length; i++) {
        const isLast = i === message.newDataPoints.length - 1;
        const point = message.newDataPoints[i];
        // Only update and shift the view if we added the last point in
        series.addPoint([point[0], point[1]], isLast, isLast);
      }
    }
  }, [message, chart, prevChart]);

  return (
    <Center height="100%">
      <Box width="100%" height="100%" ref={chartRef} />
    </Center>
  );
};
