import React, { useEffect } from "react";
import { Box, Center } from "@chakra-ui/react";
import { CellProps } from "@/cells/cell.types";
import { ConfigType } from ".";
import { useDataStreamQuery } from "./randomPieChartCell.slice";
import { useAuth } from "@/hooks/auth/auth.hooks";
import deepcopy from "deepcopy";
import { useHighchart } from "@/hooks/highcharts/useHighchart";
import * as DashboardUtilities from "@/utilities/dashboard";

export interface Props<S> extends CellProps<S> {}

export const RandomPieChartCell = <S extends ConfigType>(props: Props<S>) => {
  const { explicitConfig, dashboardLevelConfig, globalLevelConfig } = props;

  const { token } = useAuth();

  const { data: message } = useDataStreamQuery({
    ...DashboardUtilities.consolidateCellConfig(
      explicitConfig,
      dashboardLevelConfig,
      globalLevelConfig,
    ),
    auth_token: token,
  });

  // Get highchart reference
  const [chart, chartRef] = useHighchart<HTMLDivElement>({
    title: {
      text: "",
    },
    chart: {
      plotShadow: false,
      type: "pie",
      reflow: true,
    },
    series: [
      {
        type: "pie",
        data: [],
      },
    ],
  });

  // Update the chart data
  useEffect(() => {
    if (!message || !chart) return;

    chart!.series[0].update({
      // This is needed b/c Highcharts will mutate the data object passed in
      data: deepcopy(message.data ?? []),
    } as any);
  }, [message, chart]);

  return (
    <Center height="100%">
      <Box width="100%" height="100%" ref={chartRef} />
    </Center>
  );
};
