import { KEYS } from "@/keys";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import * as DashboardModel from "@/models/dashboard/dashboard.model";
import * as DashboardCellInstanceModel from "@/models/dashboard/dashboard-cell-instance.model";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  DashboardCellInstance,
  DashboardCellInstanceNoIdentifier,
} from "@/models/dashboard/dashboard-cell-instance.model";
import * as Utilities from "@/utilities";
import { Id, PartialOther } from "@/utilities";
import { DashboardInfoMessage } from "@/models/dashboard/dashboard-info-message/dashboard-info-message.model";
import { AuthenticatedCellConfigType } from "@/cells/cell.types";
import queryString from "query-string";
import * as DashboardInfoMessageModel from "@/models/dashboard/dashboard-info-message/dashboard-info-message.model";
import { AppStore } from "@/store/store";

const LIST_KEY = "__LIST__";

type DashboardPatchIn = Id<PartialOther<Omit<Dashboard, "cells">, "id">>;
type DashboardCellPatchIn = Partial<DashboardCellInstanceNoIdentifier>;

type DashboardCellLocationUpdateIn = Pick<
  DashboardCellInstance,
  "identifier" | "location"
>;

interface DashboardUpdateLocationsParam {
  dashboard: Dashboard;
  updatedLocations: DashboardCellLocationUpdateIn[];
}

interface AddDashboardCellParam {
  dashboard: Dashboard;
  cell: DashboardCellInstanceNoIdentifier;
}

interface DeleteDashboardCellParam {
  dashboard: Dashboard;
  dashboardCellIdentifier: string;
}

interface UpdateDashboardCellParam {
  dashboard: Dashboard;
  dashboardCellIdentifier: string;
  updatedCell: DashboardCellPatchIn;
}

interface DashboardInfoParams extends AuthenticatedCellConfigType {
  dashboardId: string;
}

export const dashboardsApiSlice = createApi({
  reducerPath: "dashboardsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: KEYS.API_URL,
    prepareHeaders: Utilities.authenticatedPrepareHeaders,
  }),
  tagTypes: ["Dashboards"],
  endpoints: (builder) => ({
    // --- Get All Dashboards ---
    getAllDashboards: builder.query<Dashboard[], {}>({
      query: () => ({ url: "/dashboards" }),
      transformResponse: (dashboardsJson: unknown[]) =>
        dashboardsJson.map((dashboardJson) =>
          DashboardModel.fromJson(dashboardJson)
        ),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Dashboards" as const,
                id: id!,
              })),
              { type: "Dashboards", id: LIST_KEY },
            ]
          : [{ type: "Dashboards", id: LIST_KEY }],
    }),
    // --- Get Dashboard ---
    getDashboard: builder.query<Dashboard, string>({
      query: (id) => `/dashboards/${id}`,
      transformResponse: (dashboardJson) =>
        DashboardModel.fromJson(dashboardJson),
      providesTags: (result) =>
        result ? [{ type: "Dashboards", id: result.id! }] : [],
    }),
    // --- Create Dashboard ---
    createDashboard: builder.mutation<Dashboard, Dashboard>({
      query: (dashboard) => ({
        url: "/dashboards",
        method: "POST",
        body: DashboardModel.toJson(dashboard, false),
      }),
      transformResponse: (dashboardJson) =>
        DashboardModel.fromJson(dashboardJson),
      invalidatesTags: [{ type: "Dashboards", id: LIST_KEY }],
    }),
    // --- Update Dashboard ---
    updateDashboard: builder.mutation<Dashboard, DashboardPatchIn>({
      query: (dashboard) => ({
        url: `/dashboards/${dashboard.id!}`,
        method: "PATCH",
        body: DashboardModel.toJsonPartial(dashboard, false),
      }),
      transformResponse: (dashboardJson) =>
        DashboardModel.fromJson(dashboardJson),
      invalidatesTags: (_, __, dashboard) => [
        { type: "Dashboards", id: dashboard!.id! },
      ],
    }),
    // --- Delete Dashboard ---
    deleteDashboard: builder.mutation<void, string>({
      query: (id) => ({
        url: `/dashboards/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, __, id) => [{ type: "Dashboards", id }],
    }),
    // --- Add Dashboard Cell ---
    addDashboardCell: builder.mutation<Dashboard, AddDashboardCellParam>({
      query: ({ dashboard, cell: cellNoId }) => {
        return {
          url: `/dashboards/${dashboard.id!}/cell`,
          method: "POST",
          body: DashboardCellInstanceModel.toJson(
            DashboardCellInstanceModel.newCell(cellNoId)
          ),
        };
      },
      transformResponse: (dashboardJson) =>
        DashboardModel.fromJson(dashboardJson),
      invalidatesTags: (_, __, { dashboard }) => [
        { type: "Dashboards", id: dashboard!.id! },
      ],
    }),
    // --- Delete Dashboard Cell ---
    deleteDashboardCell: builder.mutation<Dashboard, DeleteDashboardCellParam>({
      query: ({ dashboard, dashboardCellIdentifier: cellIdentifier }) => ({
        url: `/dashboards/${dashboard.id!}/cell/${cellIdentifier}`,
        method: "DELETE",
      }),
      transformResponse: (dashboardJson) =>
        DashboardModel.fromJson(dashboardJson),
      invalidatesTags: (_, __, { dashboard }) => [
        { type: "Dashboards", id: dashboard!.id! },
      ],
    }),
    // --- Update Dashboard Cell ---
    updateDashboardCell: builder.mutation<Dashboard, UpdateDashboardCellParam>({
      query: ({
        dashboard,
        dashboardCellIdentifier: cellIdentifier,
        updatedCell,
      }) => ({
        url: `/dashboards/${dashboard.id!}/cell/${cellIdentifier}`,
        method: "PATCH",
        body: DashboardCellInstanceModel.toJsonPartial(updatedCell),
      }),
      transformResponse: (dashboardJson) =>
        DashboardModel.fromJson(dashboardJson),
      invalidatesTags: (_, __, { dashboard }) => [
        { type: "Dashboards", id: dashboard!.id! },
      ],
    }),
    // --- Update Dashboard Cell Locations ---
    updateDashboardCellLocations: builder.mutation<
      Dashboard,
      DashboardUpdateLocationsParam
    >({
      query: ({ dashboard, updatedLocations }) => ({
        url: `/dashboards/${dashboard.id!}/cell-locations`,
        method: "PUT",
        body: updatedLocations.map((updatedLocation) =>
          DashboardCellInstanceModel.toJsonPartial(updatedLocation)
        ),
      }),
      transformResponse: (dashboardJson) =>
        DashboardModel.fromJson(dashboardJson),
      invalidatesTags: (_, __, { dashboard }) => [
        { type: "Dashboards", id: dashboard!.id! },
      ],
    }),
    dashboardInfoListener: builder.query<
      DashboardInfoMessage[],
      DashboardInfoParams
    >({
      query: () => ({
        url: "/cells/fake",
      }),
      transformResponse: () => [],
      async onCacheEntryAdded(
        { auth_token, dashboardId },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const params = queryString.stringify({
          authorization: auth_token,
        });

        const { data: prevInfoMessages } = await cacheDataLoaded;

        const ws = new WebSocket(
          `${KEYS.API_WS_URL}/dashboards/${dashboardId}/ws?${params}`
        );

        try {
          const listener = (event: MessageEvent) => {
            const infoMessage = DashboardInfoMessageModel.fromJson(
              JSON.parse(event.data)
            );

            const nextInfoMessages = [...prevInfoMessages, infoMessage];
            updateCachedData(() => {
              return nextInfoMessages;
            });
          };

          ws.addEventListener("message", listener);
        } catch (err) {
          // Do nothing
          console.error(err);
        }

        await cacheEntryRemoved;

        ws.close();
      },
    }),
  }),
});

// Locally deletes the dashboard from the store cache without calling the
// API delete endpoint.
// Note: we have to pass in the store directly because we get circular
// dependencies otherwise
export const localDeleteDashboard = (store: AppStore, dashboardId: string) => {
  store.dispatch(
    dashboardsApiSlice.util.invalidateTags([
      { type: "Dashboards", id: dashboardId },
    ])
  );
};

export const {
  useGetAllDashboardsQuery,
  useGetDashboardQuery,
  useCreateDashboardMutation,
  useUpdateDashboardMutation,
  useDeleteDashboardMutation,
  useAddDashboardCellMutation,
  useUpdateDashboardCellMutation,
  useDeleteDashboardCellMutation,
  useUpdateDashboardCellLocationsMutation,
  useDashboardInfoListenerQuery,
} = dashboardsApiSlice;
