import * as yup from "yup";
import { YupSchema } from "@/utilities";
import { authenticatedCellSchema } from "@/cells/cell.types";
import * as Utilities from "@/utilities";

export type ConfigType = YupSchema<typeof configSchema>;

export const configSchema = authenticatedCellSchema.shape({
  pollRate: yup.number().positive().nullable(),
  rangeMin: yup
    .number()
    .min(0)
    .nullable()
    // Only check rangeMin against rangeMax when rangeMax exists in model
    .when("rangeMax", (rangeMax: number | undefined) =>
      !Utilities.isNullish(rangeMax)
        ? yup
            .number()
            .max(rangeMax!)
            .typeError("rangeMin must less than or equal to rangeMax")
        : yup.mixed()
    ),
  // Use yup.lazy to avoid cyclic dependencies
  rangeMax: yup.lazy(() =>
    yup
      .number()
      .min(0)
      .nullable()
      // Only check rangeMax against rangeMin when rangeMin exists in model
      .when("rangeMin", (rangeMin: number | undefined) =>
        !Utilities.isNullish(rangeMin)
          ? yup
              .number()
              .min(rangeMin!)
              .typeError("rangeMax must greater than or equal to rangeMin")
          : yup.mixed()
      )
  ),
});
