import React, { FunctionComponent } from "react";
import { Box, Button, Center, Flex, Icon, Text } from "@chakra-ui/react";
import { MdLock } from "react-icons/md";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export const UnauthorizedView: FunctionComponent = (props) => {
  return (
    <>
      <Helmet>
        <title>Skew Dashboard | Unauthorized</title>
      </Helmet>

      <Center height="100%" width="100%">
        <Flex flexDirection="column" alignItems="center" maxWidth="25em">
          <Icon as={MdLock} width="32" height="32" color="red.400" />
          <Text fontSize="3xl" textAlign="center">
            Unauthorized
          </Text>
          <Text fontSize="md" textAlign="center">
            You do not have permission to access this view.
          </Text>
          <Box height="4" />
          <Button colorScheme="blue" as={Link} to="/">
            Home
          </Button>
        </Flex>
      </Center>
    </>
  );
};
