import {
  DashboardCellInstanceLocation,
  dashboardCellInstanceLocation,
} from "./dashboard-cell-instance-location.model";
import { lookupCellRegistryItem } from "@/cells";
import * as yup from "yup";
import { CellConfigBaseType } from "@/cells/cell.types";
import * as Utilities from "@/utilities";
import { v4 as uuidv4 } from "uuid";

export interface DashboardCellInstance<C extends CellConfigBaseType = any> {
  location: DashboardCellInstanceLocation;
  identifier: string;
  cellIdentifier: string;
  config: Partial<C> | null;
  displayName?: string | null | undefined;
}

export const dashboardCellInstanceSchema = yup.object({
  identifier: yup.string().required(),
  cellIdentifier: yup.string().required(),
  displayName: yup.string().nullable(),
  location: dashboardCellInstanceLocation,
  config: yup.object().nullable(),
});

export type DashboardCellInstanceNoIdentifier<
  C extends CellConfigBaseType = any
> = Omit<DashboardCellInstance<C>, "identifier">;

export function fromJson<C = any>(json: any): DashboardCellInstance<C> {
  const jsonCamelCase = Utilities.snakeToCamelCaseObject(json);

  const cellIdentifier = jsonCamelCase["cellIdentifier"];
  const cellRegistry = lookupCellRegistryItem(cellIdentifier);

  if (!cellRegistry) {
    console.error(
      `Warning: cell '${cellIdentifier}' has no cell registry, not parsing cell config.`
    );
  }

  const configSchema = cellRegistry?.configSchema
    ? cellRegistry?.configSchema
    : yup.object();

  const dashboardCellInstanceSchemaWithGenerics =
    dashboardCellInstanceSchema.shape({
      config: configSchema.nullable(),
    });

  const dashboardCellData =
    dashboardCellInstanceSchemaWithGenerics.cast(jsonCamelCase);
  const dashboardCellDataValidated =
    dashboardCellInstanceSchemaWithGenerics.validateSync(dashboardCellData);

  return dashboardCellDataValidated as DashboardCellInstance<C>;
}

export function toJson<C extends CellConfigBaseType = any>(
  cell: DashboardCellInstance<C>
): any {
  return Utilities.camelToSnakeCaseObject(cell);
}

export function toJsonPartial<C extends CellConfigBaseType = any>(
  cell: Partial<DashboardCellInstance<C>>
): any {
  return Utilities.camelToSnakeCaseObject(cell);
}

export function generateCellIdentifier(): string {
  return uuidv4();
}

export function newCell<C extends CellConfigBaseType = any>(
  cellNoId: DashboardCellInstanceNoIdentifier
): DashboardCellInstance<C> {
  const identifier = generateCellIdentifier();
  const newCell = { identifier, ...cellNoId } as DashboardCellInstance<C>;
  return newCell;
}
