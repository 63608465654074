import React, { FunctionComponent, useState } from "react";
import {
  Button,
  Center,
  Flex,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useGetAllPermissionsQuery } from "@/store/permissions/permissions.slice";
import { MdAdd } from "react-icons/md";
import { assert } from "tsafe";
import { PermissionGrantItem } from "./components/PermissionGrantItem";
import { useAuth } from "@/hooks/auth/auth.hooks";
import { PermissionGrant } from "@/models/permissions/permission-grant.model";
import { PermissionModifierModal } from "@/components/modals/PermissionModifierModal/PermissionModifierModal";
import { PermissionRevokeModal } from "@/components/modals/PermissionRevokeModal/PermissionRevokeModal";
import { Helmet } from "react-helmet-async";

export const PermissionsView: FunctionComponent = (props) => {
  const {
    isLoading: isLoadingAllPermissions,
    isSuccess: isSuccessAllPermissions,
    isError: isErrorAllPermissions,
    data: allPermissions,
  } = useGetAllPermissionsQuery({});

  const { currUser } = useAuth();

  const {
    isOpen: isAddPermissionModalOpen,
    onOpen: onOpenAddPermissionModal,
    onClose: onCloseAddPermissionModal,
  } = useDisclosure();

  const {
    isOpen: isEditPermissionModalOpen,
    onOpen: onOpenEditPermissionModal,
    onClose: onCloseEditPermissionModal,
  } = useDisclosure();
  const [currEditPermission, setCurrEditPermission] = useState<PermissionGrant>(
    { email: "", action: "user" }
  );

  const {
    isOpen: isRevokePermissionModalOpen,
    onOpen: onOpenRevokePermissionModal,
    onClose: onCloseRevokePermissionModal,
  } = useDisclosure();
  const [currRevokePermission, setCurrRevokePermission] =
    useState<PermissionGrant>({ email: "", action: "user" });

  const onAddPermissionHandler = () => {
    onOpenAddPermissionModal();
  };

  const onEditPermissionHandler = (permission: PermissionGrant) => {
    setCurrEditPermission(() => permission);
    onOpenEditPermissionModal();
  };

  const onRevokePermissionHandler = (permission: PermissionGrant) => {
    setCurrRevokePermission(() => permission);
    onOpenRevokePermissionModal();
  };

  if (isLoadingAllPermissions)
    return (
      <>
        <Helmet>
          <title>Skew Dashboard | Permissions</title>
        </Helmet>

        <Center height="100%">
          <Spinner size="lg"></Spinner>
        </Center>
      </>
    );

  if (isErrorAllPermissions)
    return (
      <>
        <Helmet>
          <title>Skew Dashboard | Permissions</title>
        </Helmet>

        <Center height="100%">
          <Text>Unable to load permission items.</Text>
        </Center>
      </>
    );

  if (isSuccessAllPermissions) {
    assert(allPermissions);

    return (
      <>
        <Helmet>
          <title>Skew Dashboard | Permissions</title>
        </Helmet>

        <Flex
          padding="3"
          flexDirection="column"
          width="100%"
          height="100%"
          overflow="auto"
        >
          <PermissionModifierModal
            mode="create"
            isOpen={isAddPermissionModalOpen}
            onClose={onCloseAddPermissionModal}
          />

          <PermissionModifierModal
            mode="edit"
            permission={currEditPermission}
            isOpen={isEditPermissionModalOpen}
            onClose={onCloseEditPermissionModal}
          />

          <PermissionRevokeModal
            permission={currRevokePermission}
            isOpen={isRevokePermissionModalOpen}
            onClose={onCloseRevokePermissionModal}
          />

          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <Text fontSize="lg" fontWeight="bold">
              Manage Account Access
            </Text>

            <Button
              colorScheme="dashboard-primary-button"
              leftIcon={<MdAdd size="1.2rem" />}
              size="sm"
              flexShrink={0}
              onClick={onAddPermissionHandler}
            >
              Add New Account
            </Button>
          </Flex>

          {/* Permission grant list */}
          <VStack spacing="2" marginTop="3">
            {allPermissions.map((permission, idx) => (
              <PermissionGrantItem
                key={`permission-grant-item-${permission.id}-${idx}`}
                permission={permission}
                isSelf={currUser.email === permission.email}
                onEditClick={() => onEditPermissionHandler(permission)}
                onRevokeClick={() => onRevokePermissionHandler(permission)}
              />
            ))}
          </VStack>
        </Flex>
      </>
    );
  }

  console.warn("Should not reach here.");
  return null;
};
