import React, { Ref, useImperativeHandle, useMemo } from "react";
import { VStack } from "@chakra-ui/react";
import { getCellConfigRegistryItems } from ".";
import {
  CellAdditionalDataType,
  CellConfigBaseType,
  CellConfigSubmissionHandler,
  CellRegistryItem,
} from "@/cells/cell.types";
import { ModalStatus } from "@/components/modals/ModalBase/ModalBase";
import * as Utilities from "@/utilities";
import { useFieldArray, useForm } from "react-hook-form";
import {
  ConfigFieldFormData,
  ConfigFieldInputItem,
} from "./cell-config-fields.types";
import { Nullish } from "@/utilities";

export interface Props<
  C extends CellConfigBaseType = any,
  A extends CellAdditionalDataType = any
> {
  config: C | Nullish;
  globalLevelDefaultConfig: Partial<C> | Nullish;
  dashboardLevelDefaultConfig: Partial<C> | Nullish;
  status: ModalStatus;
  submissionHandlerRef: Ref<CellConfigSubmissionHandler<C>>;
  cellRegistryItem: CellRegistryItem<C, A>;
}

// This component is used to render a list of config fields

function configToConfigArray<C extends CellConfigBaseType>(
  config: C | Nullish,
  identifiers: string[]
): ConfigFieldInputItem[] {
  return identifiers.map((identifier) => {
    let value: string | undefined =
      config?.[Utilities.snakeToCamelCase(identifier)];
    value = value ? value + "" : "";
    return {
      identifier,
      value,
    };
  });
}

export const CellConfigFieldSettingsRenderer = <
  C extends CellConfigBaseType = any,
  A extends CellAdditionalDataType = any
>(
  props: Props<C, A>
) => {
  const {
    status,
    config,
    submissionHandlerRef,
    cellRegistryItem,
    globalLevelDefaultConfig,
    dashboardLevelDefaultConfig,
  } = props;

  const configFieldIdentifiers = useMemo(() => {
    return cellRegistryItem.commonConfigFields.map((field) => field.identifier);
  }, [cellRegistryItem]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    register,
  } = useForm<ConfigFieldFormData>({
    defaultValues: {
      configFieldInputs: configToConfigArray(config, configFieldIdentifiers),
    },
  });

  const { fields: configFields } = useFieldArray({
    control,
    name: "configFieldInputs",
  });

  const handleSubmitPromise = () => {
    return new Promise<ConfigFieldFormData>((resolve, reject) => {
      handleSubmit(
        (data) => {
          resolve(data);
        },
        (errs) => {
          reject();
        }
      )();
    });
  };

  useImperativeHandle(submissionHandlerRef, () => ({
    submit: async () => {
      const formData = await handleSubmitPromise();
      reset();

      const retConfig: Record<string, any> = {};

      configFieldIdentifiers.forEach((identifier, idx) => {
        const identifierCamel = Utilities.snakeToCamelCase(identifier);
        retConfig[identifierCamel] = formData.configFieldInputs[idx].value;
      });

      return retConfig as Partial<C>;
    },
  }));

  const registryItems = useMemo(
    () => getCellConfigRegistryItems(configFieldIdentifiers),
    [configFieldIdentifiers]
  );

  return (
    <VStack>
      {configFields.map((field, idx) => {
        const regItem = registryItems[idx];
        return (
          <regItem.InputComponent
            key={field.id}
            context="cell-settings"
            status={status}
            error={errors?.configFieldInputs?.[idx].value}
            control={control}
            register={register}
            name={`configFieldInputs.${idx}.value` as const}
            globalLevelDefaultValue={
              globalLevelDefaultConfig?.[
                Utilities.snakeToCamelCase(field.identifier)
              ]
            }
            dashboardLevelDefaultValue={
              dashboardLevelDefaultConfig?.[
                Utilities.snakeToCamelCase(field.identifier)
              ]
            }
          />
        );
      })}
    </VStack>
  );
};
