import firebase from "firebase/app";
import "firebase/auth";

export const firebaseConfig = {
  apiKey: "AIzaSyAxiieKj6UfPk518n6sP6aAuoGe2wCo2fY",
  authDomain: "skew-dashboard-162.firebaseapp.com",
  projectId: "skew-dashboard-162",
  storageBucket: "skew-dashboard-162.appspot.com",
  messagingSenderId: "72242145190",
  appId: "1:72242145190:web:49545b745c559a24475167"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebaseApp.auth();
export const firebaseGoogleProvider = new firebase.auth.GoogleAuthProvider();