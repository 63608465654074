import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
} from "react";
import "./CategoryDropdown.scss";
import { Button, Center, Divider, Flex, Icon, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { useThrottledMeasure } from "@/hooks/utils/useThrottledMeasure";
import {
  ControlledMenu,
  MenuItem,
  SubMenu,
  useMenuState,
} from "@szhsin/react-menu";
import { useMultiHover } from "@/hooks/utils/useMultiHover";
import mergeRefs from "react-merge-refs";
import { MdKeyboardArrowDown } from "react-icons/md";
import classNames from "classnames";

// Note: keep an eye on Chakra b/c they teased at some point that they were
// working on an implementation for sub-menus but it seems like it might've
// died since that was in May 2020...
// TODO: when Chakra finally puts in sub-menu support then convert the menu
// items to be links to the dashboard's they represent

export interface Props<T extends unknown> {
  /**
   * The name displayed in the base button.
   */
  name: string;
  /**
   * The icon that the base button is prefixed with.
   */
  icon: IconType;
  /**
   * Maps the dropdown categories to the data that each has.
   */
  categoryMap: CategoryDropdownMap<T>;
  /**
   * The list of uncategorized data.
   */
  uncategorized?: T[];
  /**
   * Called whenever a data item is clicked
   */
  onDataClick?: (data: T) => unknown;

  /**
   * A transformer function that given a instance of the data
   * returns its id.
   */
  getDataId: (data: T) => string;

  /**
   * A transformer function that given a instance of the data
   * returns its display name.
   */
  getDataName: (data: T) => string;

  /**
   * Returns if the given data item is selected in the dropdown.
   */
  isDataSelected?: (data: T) => boolean;

  /**
   * Returns if the given category is selected in the dropdown
   */
  isCategorySelected?: (category: string | null) => boolean;

  /**
   * Text to display if no values exist in the category dropdown.
   */
  emptyDropdownText?: string;

  /**
   * Disables the category dropdown button.
   */
  isDisabled?: boolean;
}

export interface CategoryDropdownMap<T extends unknown = unknown> {
  [category: string]: T[];
}

export type UncategorizedData<T extends unknown = unknown> = T[];

// Returns the categories from the given category map, sorted.
const getSortedCategoryList = <T extends unknown>(
  categoryMap: CategoryDropdownMap<T>
) => {
  return Object.keys(categoryMap).sort();
};

export const CategoryDropdown = <T extends unknown>(
  props: PropsWithChildren<Props<T>>
): ReactElement | null => {
  const {
    name,
    icon,
    categoryMap,
    uncategorized,
    onDataClick,
    getDataId,
    getDataName,
    isDataSelected = () => false,
    isCategorySelected = () => false,
    emptyDropdownText = "(No Items)",
    isDisabled = false,
  } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef(null);

  const [buttonMeasure, buttonMeasureRef] = useThrottledMeasure();

  const { toggleMenu, ...menuProps } = useMenuState({ transition: true });

  const hovering = useMultiHover([buttonRef, menuRef], 100, 500);

  // Toggle the menu every time the hover changes
  useEffect(() => {
    toggleMenu(hovering);
  }, [hovering]);

  const categoryList = useMemo(
    () => getSortedCategoryList(categoryMap),
    [categoryMap]
  );

  const isEmptyDropdown = useMemo(() => {
    return uncategorized?.length === 0 && Object.keys(categoryMap).length === 0;
  }, [categoryMap, uncategorized]);

  return (
    <>
      <Button
        borderRadius="0"
        colorScheme="dashboard-selection-button"
        _focus={{
          boxShadow: "none",
        }}
        ref={mergeRefs([buttonRef, buttonMeasureRef])}
        onClick={() => toggleMenu()}
        isActive={menuProps.state === "open"}
        flexShrink={0}
        minWidth="15rem"
        isDisabled={isDisabled}
      >
        <Flex direction="row" alignItems="center">
          <Icon as={icon} marginRight="2" />
          <Text marginRight="2">{name}</Text>
        </Flex>
        <Icon as={MdKeyboardArrowDown} />
      </Button>

      <Divider
        orientation="vertical"
        borderColor="dashboard-selection-button.50"
        borderLeftWidth="2px"
      />

      <ControlledMenu
        {...menuProps}
        ref={menuRef}
        anchorRef={buttonRef}
        // Match the width with the size of the base category button
        menuStyles={{
          width: buttonMeasure
            ? `${buttonMeasure.target.clientWidth}px`
            : undefined,
        }}
        menuClassName="CategoryDropdownMenu__menu"
        portal={true}
      >
        {isEmptyDropdown && (
          <Center>
            <Text
              color="gray.400"
              fontStyle="italic"
            >{emptyDropdownText}</Text>
          </Center>
        )}

        {categoryList.map((category) => (
          <SubMenu
            label={category}
            key={`${name}-category-${category}`}
            itemProps={{
              className: ({ hover, active }) =>
                classNames("CategoryDropdownMenu__sub-menu-item", {
                  "CategoryDropdownMenu__sub-menu-item--active":
                    active || isCategorySelected(category),
                  "CategoryDropdownMenu__sub-menu-item--hover": hover,
                }),
            }}
            menuClassName="CategoryDropdownMenu__sub-menu"
          >
            {categoryMap[category].map((data) => (
              <MenuItem
                key={`${name}-category-${category}-${getDataId(data)}`}
                className={({ hover, active }) =>
                  classNames("CategoryDropdownMenu__menu-item", {
                    "CategoryDropdownMenu__menu-item--active":
                      active || isDataSelected(data),
                    "CategoryDropdownMenu__menu-item--hover": hover,
                  })
                }
                onClick={() => {
                  if (onDataClick) onDataClick(data);
                }}
              >
                {getDataName(data)}
              </MenuItem>
            ))}
          </SubMenu>
        ))}
        {/* Only render the uncategorized category if there are some items */}
        {(uncategorized ?? []).length > 0 && (
          <SubMenu
            label={"Uncategorized"}
            itemProps={{
              className: ({ hover, active }) =>
                classNames(
                  "CategoryDropdownMenu__sub-menu-item",
                  "CategoryDropdownMenu__sub-menu-item--uncategorized",
                  {
                    "CategoryDropdownMenu__sub-menu-item--active":
                      active || isCategorySelected(null),
                    "CategoryDropdownMenu__sub-menu-item--hover": hover,
                  }
                ),
            }}
            menuClassName="CategoryDropdownMenu__sub-menu"
          >
            {(uncategorized ?? []).map((data) => (
              <MenuItem
                key={`${name}-uncategorized-category-${getDataId(data)}`}
                className={({ hover, active }) =>
                  classNames("CategoryDropdownMenu__menu-item", {
                    "CategoryDropdownMenu__menu-item--active":
                      active || isDataSelected(data),
                    "CategoryDropdownMenu__menu-item--hover": hover,
                  })
                }
                onClick={() => {
                  if (onDataClick) onDataClick(data);
                }}
              >
                {getDataName(data)}
              </MenuItem>
            ))}
          </SubMenu>
        )}
      </ControlledMenu>
    </>
  );
};
