import { useImperativeHandle } from "react";
import { CellConfigProps } from "@/cells/cell.types";
import {
  FormControl,
  FormLabel,
  VStack,
  Text,
  FormErrorMessage,
  Select,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { ConfigType, AdditionalDataType } from ".";
import { assert } from "tsafe/assert";
import * as DashboardUtilities from "@/utilities/dashboard";
import * as Utilities from "@/utilities";

export interface Props<S, A> extends CellConfigProps<S, A> {}

interface ConfigInputs {
  aggLevel: string;
  expirationType: string;
}

const ID_INPUT_BASE = "trading-breakdown-cell-config";

const AGG_LEVEL_INPUT_ID = `${ID_INPUT_BASE}-agg-level-input`;
const EXPIRATION_TYPE_INPUT_ID = `${ID_INPUT_BASE}-expiration-type-input`;

export const TradingBreakdownCellExtraConfig = (
  props: Props<ConfigType, AdditionalDataType>
) => {
  const {
    submissionHandlerRef,
    explicitConfig,
    dashboardLevelConfig,
    globalLevelConfig,
    status,
    additionalData,
  } = props;

  assert(additionalData);

  const { availableFilters, defaultFilters } = additionalData;

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<ConfigInputs>({
    defaultValues: {
      ...Utilities.toStringValues(
        Utilities.nullishToUndefined(explicitConfig ?? {})
      ),
      aggLevel: defaultFilters.aggLevel,
      expirationType: defaultFilters.expirationType,
    },
  });

  const handleSubmitPromise = () => {
    return new Promise<ConfigInputs>((resolve, reject) => {
      handleSubmit(
        (data) => {
          resolve(data);
        },
        (errs) => {
          reject();
        }
      )();
    });
  };

  useImperativeHandle(submissionHandlerRef, () => ({
    submit: async () => {
      const formConfig = await handleSubmitPromise();
      reset();
      return {
        aggLevel: formConfig.aggLevel,
        expirationType: formConfig.expirationType,
      };
    },
  }));

  return (
    <VStack direction="column">
      <FormControl
        id={AGG_LEVEL_INPUT_ID}
        isInvalid={!!errors.aggLevel}
        isDisabled={status === "submitting"}
      >
        <FormLabel>
          <Text display="inline">Aggregation Level</Text>
        </FormLabel>

        <Select
          placeholder="Select aggregation level"
          {...register("aggLevel", {
            required: "Aggregation level required",
          })}
        >
          {availableFilters.aggLevels.map((agg, idx) => (
            <option key={`${agg}-${idx}`} value={agg}>
              {agg}
            </option>
          ))}
        </Select>
        <FormErrorMessage>
          {errors.aggLevel && errors.aggLevel.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        id={EXPIRATION_TYPE_INPUT_ID}
        isInvalid={!!errors.expirationType}
        isDisabled={status === "submitting"}
      >
        <FormLabel>
          <Text display="inline">Expiration Type</Text>
        </FormLabel>

        <Select
          placeholder="Select expiration type"
          {...register("expirationType", {
            required: "Expiration type required",
          })}
        >
          {availableFilters.expirationTypes.map((et, idx) => (
            <option key={`${et}-${idx}`} value={et}>
              {et}
            </option>
          ))}
        </Select>
        <FormErrorMessage>
          {errors.expirationType && errors.expirationType.message}
        </FormErrorMessage>
      </FormControl>
    </VStack>
  );
};
