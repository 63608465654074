import { DashboardCollaboratorTypeWithOwner } from "@/models/dashboard/dashboard-collaborator-type.model";
import { dashboardCollaboratorHasWeightOf } from "@/models/dashboard/dashboard-collaborator.model";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { useMemo } from "react";
import { useAuth } from "../auth/auth.hooks";

/**
 * Returns true if the user has the given collaborator permission level
 * (or higher) for the provided dashboard. If the user is not a collaborator
 * then false is always returned. If no user email is provided then the
 * currently logged in user is used.
 */
export function useCollaboratorHasPerms(
  dashboard: Dashboard,
  type: DashboardCollaboratorTypeWithOwner,
  email?: string
): boolean {
  const { currUser } = useAuth();

  const hasPerms = useMemo(() => {
    const emailSanitized = email ?? currUser.email;

    return dashboardCollaboratorHasWeightOf(
      dashboard,
      emailSanitized, 
      type
    );
  }, [currUser.email, email, dashboard, type]);
  
  return hasPerms;
}
