import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ConfigType } from ".";
import { KEYS } from "@/keys";
import queryString from "query-string";
import * as Utilities from "@/utilities";

// TODO: need to make these into proper models

interface TradingBreakdownCellMessageDataPoint {
  name: string;
  percentage: number;
}

interface TradingBreakdownCellMessage {
  data: TradingBreakdownCellMessageDataPoint[];
}

const REDUCER_PATH = "cells__tradingBreakdownCellApi";

export const tradingBreakdownCellApiSlice = createApi({
  // How long to wait before closing a ws connection after it is not used.
  keepUnusedDataFor: 5,
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: KEYS.API_URL,
    prepareHeaders: Utilities.authenticatedPrepareHeaders,
  }),
  endpoints: (builder) => ({
    dataStream: builder.query<
    TradingBreakdownCellMessage | null,
      Partial<ConfigType>
    >({
      query: ({ pollRate, aggLevel, baseCurrency, expirationType }) => ({
        url: "/cells/fake",
        // TODO: need to make a model for this?
        params: {
          poll_rate: pollRate,
          base_currency: baseCurrency,
          agg_level: aggLevel,
          expiration_type: expirationType,
        },
      }),
      transformResponse: () => null,
      async onCacheEntryAdded(
        { pollRate, aggLevel, baseCurrency, expirationType, auth_token },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        // TODO: need to make a model for this?
        const params = queryString.stringify({
          poll_rate: pollRate,
          base_currency: baseCurrency,
          agg_level: aggLevel,
          expiration_type: expirationType,
          authorization: auth_token,
        });

        await cacheDataLoaded;

        const ws = new WebSocket(
          `${KEYS.API_WS_URL}/cells/trading_breakdown_cell/ws?${params}`
        );

        try {
          const listener = (event: MessageEvent) => {
            // TODO: need to make a model for this
            const data = JSON.parse(event.data) as TradingBreakdownCellMessage;

            updateCachedData(() => {
              return data;
            });
          };

          ws.addEventListener("message", listener);
        } catch(err) {
          // Do nothing
          console.error(err);
        }

        await cacheEntryRemoved;

        ws.close();
      },
    }),
  }),
});

export const { useDataStreamQuery } = tradingBreakdownCellApiSlice;
