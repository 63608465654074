import React, { FunctionComponent } from "react";
import {
  Button,
  Flex,
  Grid,
  HStack,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { PermissionGrant } from "@/models/permissions/permission-grant.model";
import * as PermissionActionModel from "@/models/permissions/permission-action.model";
import { UserAvatar } from "@/components/avatars/UserAvatar";
import { MdDelete, MdSettings } from "react-icons/md";

export interface Props {
  permission: PermissionGrant;
  isSelf: boolean;
  onEditClick?: () => unknown;
  onRevokeClick?: () => unknown;
}

export const PermissionGrantItem: FunctionComponent<Props> = (props) => {
  const {
    permission,
    isSelf,
    onEditClick,
    onRevokeClick: onDeleteClick,
  } = props;

  // Reference for getting girdTemplateAreas working:
  // https://github.com/chakra-ui/chakra-ui/discussions/3098

  return (
    <Grid
      width="100%"
      padding="4"
      gridTemplateColumns={{
        base: "1fr auto",
        md: "auto 1fr auto",
      }}
      gridTemplateRows={{
        base: "auto auto",
        md: "auto",
      }}
      gridTemplateAreas={{
        base: `'user actions' 'type actions'`,
        md: `'user type actions'`,
      }}
      alignItems="center"
      columnGap="3"
      backgroundColor="white"
      borderRadius="lg"
      borderColor="gray.100"
      borderStyle="solid"
      borderWidth="1px"
      shadow="md"
      overflow="auto"
    >
      {/* Avatar and email display */}
      <HStack gridArea="user" minWidth="12rem" spacing="2">
        <UserAvatar email={permission.email} />

        <Tooltip label={permission.email}>
          <Text
            flexGrow={1}
            flexShrink={1}
            maxWidth="20rem"
            width="200vw"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {permission.email}
          </Text>
        </Tooltip>
      </HStack>

      {/* Account type text */}
      <Flex gridArea="type">
        <Text whiteSpace="nowrap" marginRight="1">
          Account Type:
        </Text>
        <Text whiteSpace="nowrap" fontWeight="bold">
          {PermissionActionModel.toDisplayString(permission.action)}
        </Text>
      </Flex>

      {/* Edit and delete actions */}
      <Wrap gridArea="actions" spacing="1" justify="flex-end">
        <WrapItem>
          <Button
            colorScheme="dashboard-primary-button"
            leftIcon={<MdSettings size="1.1rem" />}
            size="sm"
            isDisabled={isSelf}
            onClick={onEditClick}
          >
            Edit
          </Button>
        </WrapItem>
        <WrapItem>
          <Button
            colorScheme="red"
            leftIcon={<MdDelete size="1.1rem" />}
            size="sm"
            isDisabled={isSelf}
            onClick={onDeleteClick}
          >
            Revoke
          </Button>
        </WrapItem>
      </Wrap>
    </Grid>
  );
};
