import React, { FunctionComponent } from "react";
import { Box, Divider, Flex } from "@chakra-ui/react";
import { FlexProps } from "@chakra-ui/react";

// Source for "Discriminated Union": https://stackoverflow.com/a/56027601
// Source for selecting FlexProp property: https://stackoverflow.com/a/41075862
type OrientationProps =
  | {
      /**
       * Horizontal divider orientation.
       */
      orientation?: "horizontal";
      /**
       * How much width the divider takes up.
       */
      axisSize?: FlexProps["width"];
    }
  | {
      /**
       * Vertical divider orientation.
       */
      orientation?: "vertical";
      /**
       * How much height the divider takes up.
       */
      axisSize?: FlexProps["height"];
    };

type Props = OrientationProps & {
  // Can put any additional props here
};

/**
 * Component that creates a divider that can be hydrated with content that is
 * rendered in the middle of the divider line.
 */
export const HydratableDivider: FunctionComponent<Props> = (props) => {
  const { orientation = "horizontal", axisSize = "100%", children } = props;

  const width = orientation === "horizontal" ? axisSize : undefined;
  const height = orientation === "vertical" ? axisSize : undefined;
  const flexDir = orientation === "horizontal" ? "row" : "column";

  return (
    <Flex
      flexDirection={flexDir}
      width={width}
      height={height}
      alignItems="center"
    >
      <Divider orientation={orientation} />
      {children && (
        <Box marginRight="3" marginLeft="3">
          {props.children}
        </Box>
      )}
      <Divider orientation={orientation} />
    </Flex>
  );
};
