import { DashboardCollaboratorTypeWithOwner } from "@/models/dashboard/dashboard-collaborator-type.model";
import { getCollaboratorType } from "@/models/dashboard/dashboard-collaborator.model";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { useMemo } from "react";
import { useAuth } from "../auth/auth.hooks";

/**
 * Returns the collaborator type of the user in the given dashboard. If no email
 * is provided then the currently logged in user is used.
 */
export function useCollaboratorType(
  dashboard: Dashboard,
  email?: string
): DashboardCollaboratorTypeWithOwner | null {
  const { currUser } = useAuth();

  const collaboratorType = useMemo(() => {
    const emailSanitized = email ?? currUser.email;
    const type = getCollaboratorType(dashboard, emailSanitized);
    return type;
  }, [email, currUser.email, dashboard]);

  return collaboratorType;
}
