import React from "react";
import { Center, Text } from "@chakra-ui/react";
import { CellProps } from "@/cells/cell.types";
import { ConfigType } from ".";
import { useDataStreamQuery } from "./randomNumberCell.slice";
import { useAuth } from "@/hooks/auth/auth.hooks";
import * as DashboardUtilities from "@/utilities/dashboard";

export interface Props<S> extends CellProps<S> {}

export const RandomNumberCell = <C extends ConfigType>(props: Props<C>) => {
  const { explicitConfig, dashboardLevelConfig, globalLevelConfig } = props;

  const { token } = useAuth();

  const { data: message } = useDataStreamQuery({
    ...DashboardUtilities.consolidateCellConfig(
      explicitConfig,
      dashboardLevelConfig,
      globalLevelConfig,
    ),
    auth_token: token,
  });

  return (
    <Center height="100%">
      {message && <Text>Random number: {message.rng}</Text>}
    </Center>
  );
};
