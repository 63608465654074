import React, { FunctionComponent } from "react";
import { Text } from "@chakra-ui/react";
import {
  ConfirmationModal,
  Props as ConfirmationModalProps,
} from "../ConfirmationModal/ConfirmationModal";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { useCreateDashboardMutation } from "@/store/dashboards/dashboards.slice";
import { useAppToasts } from "@/hooks/toasts/useAppToasts";
import { useAppNavigator } from "@/hooks/navigation/use-app-navigator";
import { assert } from "tsafe";
import { useAuth } from "@/hooks/auth/auth.hooks";

export interface Props
  extends Omit<
    ConfirmationModalProps,
    "renderContent" | "confirmationButtonOnClick"
  > {
  dashboard: Dashboard;
}

export const CloneDashboardModal: FunctionComponent<Props> = (props) => {
  const { dashboard, ...rest } = props;

  const [createDashboard] = useCreateDashboardMutation();

  const { currUser } = useAuth();

  const { errorToast } = useAppToasts();
  const { toDashboard } = useAppNavigator();

  const confirmationButtonOnClick = async () => {
    assert(dashboard.id !== null);

    const clonedDashboard: Dashboard = {
      id: null,
      name: `${dashboard.name} (Clone)`,
      category: dashboard.category,
      owner: currUser.email,
      // Remove the current user from the collaborator list
      collaborators: dashboard.collaborators.filter(
        (collaborator) => collaborator.email !== currUser.email
      ),
      cells: [...dashboard.cells],
      dashboardLevelDefaultConfigs: { ...dashboard.dashboardLevelDefaultConfigs },
    };

    try {
      const createdDashboard = await createDashboard(clonedDashboard).unwrap();

      // This set timeout is to fix the state update on unmounted error
      setTimeout(() => {
        // Go to the newly created dashboard
        toDashboard(createdDashboard.id!);
      }, 50);

      return true;
    } catch (err) {
      errorToast("Unable to clone dashboard.");

      return false;
    }
  };

  return (
    <ConfirmationModal
      title="Clone Dashboard"
      confirmationButtonColorScheme="green"
      confirmationButtonContent="Clone"
      isCentered
      {...rest}
      confirmationButtonOnClick={confirmationButtonOnClick}
      renderContent={() => <Text>Clone dashboard "{dashboard.name}"?</Text>}
    />
  );
};
