import * as yup from "yup";

export interface DashboardCellInstanceLocation {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const dashboardCellInstanceLocation = yup.object({
  x: yup.number().integer().min(0).required(),
  y: yup.number().integer().min(0).required(),
  width: yup.number().integer().min(0).required(),
  height: yup.number().integer().min(0).required(),
});
