import React, { useEffect, useMemo } from "react";
import { Box, Center } from "@chakra-ui/react";
import { CellProps } from "@/cells/cell.types";
import { useAuth } from "@/hooks/auth/auth.hooks";
import { ConfigType, AdditionalDataType } from ".";
import { useDataStreamQuery } from "./tradingBreakdownCell.slice";
import { useHighchart } from "@/hooks/highcharts/useHighchart";
import deepcopy from "deepcopy";
import * as DashboardDefaultFieldConfigModel from "@/models/dashboard/dashboard-default-field-config.model";
import * as DashboardUtilities from "@/utilities/dashboard";

export interface Props<S, A> extends CellProps<S, A> {}

export const TradingBreakdownCell = <
  S extends ConfigType,
  A extends AdditionalDataType
>(
  props: Props<S, A>
) => {
  const { explicitConfig, dashboardLevelConfig, globalLevelConfig } = props;
  const { token } = useAuth();

  const { data: message } = useDataStreamQuery({
    ...DashboardUtilities.consolidateCellConfig(
      explicitConfig,
      dashboardLevelConfig,
      globalLevelConfig,
    ),
    auth_token: token,
  });

  const chartData = useMemo(() => {
    return message?.data.map((d) => {
      return {
        name: d.name,
        y: d.percentage,
      };
    });
  }, [message]);

  // Get highchart reference
  const [chart, chartRef] = useHighchart<HTMLDivElement>({
    title: {
      text: "",
    },
    chart: {
      plotShadow: false,
      type: "pie",
      reflow: true,
    },
    series: [
      {
        type: "pie",
        data: [],
      },
    ],
  });

  // Update the chart data
  useEffect(() => {
    if (!chartData || !chart) return;

    chart!.series[0].update({
      // This is needed b/c Highcharts will mutate the data object passed in
      data: deepcopy(chartData ?? []),
    } as any);
  }, [chartData, chart]);

  return (
    <Center height="100%">
      <Box width="100%" height="100%" ref={chartRef} />
    </Center>
  );
};
