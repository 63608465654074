import React, { FunctionComponent } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { Pathname } from "history";
import { useAuth } from "@/hooks/auth/auth.hooks";
import { assert } from "tsafe";

// References:
// - https://reactrouter.com/web/example/auth-workflow
// - https://github.com/remix-run/react-router/blob/main/packages/react-router/modules/Route.js

interface Props extends Omit<RouteProps, "render"> {
  /**
   * The path to redirect to if the user is not authenticated yet.
   *
   * @default "/login"
   */
  unauthenticatedPathname?: Pathname;

  /**
   * The path to redirect to if the user is authorized.
   *
   * @default "/"
   */
  authorizedPathname?: Pathname;
}

/**
 * A wrapper for <Route> that redirects to the the `authorizedPathname`
 * location if the user is authorized.
 */
export const UnauthorizedRoute: FunctionComponent<Props> = (props) => {
  const {
    children,
    component,
    authorizedPathname = "/login",
    unauthenticatedPathname = "/",
    ...rest
  } = props;
  const { status, currUser } = useAuth(false);

  return (
    <Route
      {...rest}
      render={({ location, match, staticContext }) => {
        // Do not render anything if the authentication system is uninitialized
        // or the user is currently authenticating
        if (status === "uninitialized" || status === "authenticating") {
          return null;
        }

        if (status === "unauthenticated")
          return (
            <Redirect
              to={{
                pathname: unauthenticatedPathname,
                state: { from: location },
              }}
            />
          );

        // status === "authenticated"

        assert(currUser !== null);

        // User is authorized already
        if (currUser.permissionAction !== null)
          return (
            <Redirect
              to={{
                pathname: authorizedPathname,
                state: { from: location },
              }}
            />
          );
        
        // User is not authorized

        if (component) {
          const componentProps = { ...staticContext, location, match };
          return React.createElement(component, componentProps);
        }

        return children;
      }}
    />
  );
};
