import { User } from "@/models/user/user.model";
import {
  AuthStatus,
  loginWithEmailPassword,
  loginWithGoogle,
  logout,
  selectAuthData,
  signupWithEmailPassword,
} from "@/store/auth/auth.slice";
import { assert } from "tsafe";
import { useAppDispatch, useAppSelector } from "../store/store.hooks";

interface useAuthReturnBase {
  status: AuthStatus;
  currUser: User | null;
  token: string | null;
  signupWithEmailPassword: (email: string, password: string) => Promise<User>;
  loginWithEmailPassword: (email: string, password: string) => Promise<User>;
  loginWithGoogle: () => Promise<User>;
  logout: () => Promise<void>;
}

interface useAuthReturnAssertAuthenticated extends useAuthReturnBase {
  status: "authenticated";
  currUser: User;
  token: string;
}

export function useAuth(
  assertAuthenticated?: true
): useAuthReturnAssertAuthenticated;
export function useAuth(assertAuthenticated?: false): useAuthReturnBase;
export function useAuth(assertAuthenticated = true): useAuthReturnBase {
  const { status, currUser, token } = useAppSelector(selectAuthData);
  const dispatch = useAppDispatch();

  if (assertAuthenticated)
    assert(status === "authenticated" && currUser && token);

  return {
    status,
    currUser,
    token,
    signupWithEmailPassword: (email: string, password: string) =>
      dispatch(signupWithEmailPassword({ email, password })).unwrap(),
    loginWithEmailPassword: (email: string, password: string) =>
      dispatch(loginWithEmailPassword({ email, password })).unwrap(),
    loginWithGoogle: () => dispatch(loginWithGoogle()).unwrap(),
    logout: () => dispatch(logout()).unwrap(),
  };
}
