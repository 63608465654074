import React, { useImperativeHandle } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  VStack,
} from "@chakra-ui/react";
import { CellConfigProps } from "@/cells/cell.types";
import { ConfigType } from ".";
import { Controller, useForm } from "react-hook-form";
import { useParseInt } from "@/hooks/utils/useParseInt";
import * as Utilities from "@/utilities";
import * as DashboardUtilities from "@/utilities/dashboard";

export interface Props<S> extends CellConfigProps<S> {}

interface ConfigInputs {
  rangeMin: string;
  rangeMax: string;
}

const RANGE_MIN_INPUT_ID = "random-number-cell-config-range-min-input";
const RANGE_MAX_INPUT_ID = "random-number-cell-config-range-max-input";

export const RandomNumberCellExtraConfig = (props: Props<ConfigType>) => {
  const {
    submissionHandlerRef,
    explicitConfig,
    dashboardLevelConfig,
    globalLevelConfig,
    status,
  } = props;

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm<ConfigInputs>({
    defaultValues: {
      ...Utilities.toStringValues(
        Utilities.nullishToUndefined(explicitConfig ?? {})
      ),
    },
  });

  const defaultRangeMin =
    dashboardLevelConfig?.rangeMin ?? globalLevelConfig?.rangeMin ?? 0;
  const defaultRangeMax =
    dashboardLevelConfig?.rangeMax ?? globalLevelConfig?.rangeMax ?? 0;

  const rangeMinStr = watch("rangeMin");
  const rangeMin = useParseInt(rangeMinStr, defaultRangeMin);
  const rangeMaxStr = watch("rangeMax");
  const rangeMax = useParseInt(rangeMaxStr, defaultRangeMax);

  console.log(rangeMaxStr, rangeMinStr);

  const handleSubmitPromise = () => {
    return new Promise<ConfigInputs>((resolve, reject) => {
      handleSubmit(
        (data) => {
          resolve(data);
        },
        (errs) => {
          reject();
        }
      )();
    });
  };

  useImperativeHandle(submissionHandlerRef, () => ({
    submit: async () => {
      const formConfig = await handleSubmitPromise();
      reset();
      return {
        rangeMin: Utilities.tryParseInt(formConfig.rangeMin),
        rangeMax: Utilities.tryParseInt(formConfig.rangeMax),
      };
    },
  }));

  return (
    <VStack direction="column">
      <FormControl id={RANGE_MIN_INPUT_ID} isInvalid={!!errors.rangeMin}>
        <Controller
          control={control}
          rules={{
            required:
              Utilities.isNullish(dashboardLevelConfig?.rangeMin) &&
              Utilities.isNullish(globalLevelConfig?.rangeMin)
                ? "Range minimum parameter required"
                : undefined,
          }}
          name="rangeMin"
          render={({ field: { ref, ...restField } }) => (
            <>
              <FormLabel>Random Minimum</FormLabel>
              <NumberInput
                disabled={status === "submitting"}
                min={0}
                max={rangeMax}
                {...restField}
              >
                <NumberInputField
                  ref={ref}
                  name={restField.name}
                  placeholder={
                    !Utilities.isNullish(defaultRangeMin)
                      ? defaultRangeMin + ""
                      : undefined
                  }
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormErrorMessage>
                {errors.rangeMin && errors.rangeMin.message}
              </FormErrorMessage>
            </>
          )}
        />
      </FormControl>

      <FormControl id={RANGE_MAX_INPUT_ID} isInvalid={!!errors.rangeMax}>
        <Controller
          control={control}
          name="rangeMax"
          rules={{
            required:
              Utilities.isNullish(dashboardLevelConfig?.rangeMax) &&
              Utilities.isNullish(globalLevelConfig?.rangeMax)
                ? "Range maximum parameter required"
                : undefined,
          }}
          render={({ field: { ref, ...restField } }) => (
            <>
              <FormLabel>Random Maximum</FormLabel>
              <NumberInput
                disabled={status === "submitting"}
                min={rangeMin}
                {...restField}
              >
                <NumberInputField
                  ref={ref}
                  name={restField.name}
                  placeholder={
                    !Utilities.isNullish(defaultRangeMax)
                      ? defaultRangeMax + ""
                      : undefined
                  }
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormErrorMessage>
                {errors.rangeMax && errors.rangeMax.message}
              </FormErrorMessage>
            </>
          )}
        />
      </FormControl>
    </VStack>
  );
};
