import {
  PermissionAction,
  PERMISSION_ACTION_VALUES,
} from "@/models/permissions/permission-action.model";
import * as yup from "yup";
import * as Utilities from "@/utilities";

export interface PermissionGrant {
  id?: string | null;
  email: string;
  action: PermissionAction;
}

export const permissionGrantSchema = yup.object({
  id: yup.string().nullable(),
  email: yup.string().required(),
  action: yup
    .mixed<PermissionAction>()
    .oneOf([...PERMISSION_ACTION_VALUES])
    .required(),
}).from("_id", "id");

export function fromJson(json: any): PermissionGrant {
  const jsonCamelCase = Utilities.snakeToCamelCaseObject(json);

  const permissionGrantCasted = permissionGrantSchema.cast(jsonCamelCase);
  const permissionGrant = permissionGrantSchema.validateSync(
    permissionGrantCasted
  );

  return permissionGrant;
}

export function toJson(permission: PermissionGrant, includeId = true): any {
  const permissionCpy = { ...permission };
  if (!includeId) delete permissionCpy["id"];

  return Utilities.camelToSnakeCaseObject(permissionCpy);
}
