import React, { FunctionComponent, useMemo } from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Select,
} from "@chakra-ui/react";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { getAllCellConfigRegistryItems } from "@/cell-config-fields";
import { useForm } from "react-hook-form";
import { MdAdd } from "react-icons/md";

const IDENTIFIER_INPUT_ID = "dashboard-field-config-add-input-identifier-field";

export interface Props {
  dashboard: Dashboard;
  onAddFieldConfig: (fieldIdentifier: string) => unknown;
  currentFieldConfigIdentifiers: string[];
}

interface AddFieldConfigInputs {
  identifier: string;
}

export const DashboardFieldConfigAddInput: FunctionComponent<Props> = (
  props
) => {
  const {
    onAddFieldConfig,
    currentFieldConfigIdentifiers: currentFieldConfigIdentifiersList,
  } = props;

  const currentFieldConfigIdentifiers = useMemo(() => {
    return new Set(currentFieldConfigIdentifiersList);
  }, [currentFieldConfigIdentifiersList]);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<AddFieldConfigInputs>();

  const allCellConfigFields = useMemo(
    () => getAllCellConfigRegistryItems(),
    []
  );

  const availableConfigFields = useMemo(() => {
    return allCellConfigFields.filter(
      (cf) => !currentFieldConfigIdentifiers.has(cf.identifier)
    );
  }, [allCellConfigFields, currentFieldConfigIdentifiers]);

  const handleSubmitPromise = () => {
    return new Promise<AddFieldConfigInputs>((resolve, reject) => {
      handleSubmit(
        (data) => {
          resolve(data);
        },
        (errs) => {
          reject();
        }
      )();
    });
  };

  const onAddClick = async () => {
    try {
      const values = await handleSubmitPromise();
      onAddFieldConfig(values.identifier);

      // Note: can NOT call reset here b/c for whatever reason doing so
      // makes it so the DashboardFieldConfigItemInput does not initialize
      // with the initial value (even trying the delayed setTimeout trick
      // doesn't work)
    } catch(err) {
      // Do nothing
    }
  };

  return (
    <Flex
      flexDirection="row"
      alignItems="flex-start"
      width="100%"
    >
      <FormControl
        id={IDENTIFIER_INPUT_ID}
        isInvalid={!!errors.identifier}
        marginRight="1.5"
      >
        <Select
          placeholder="Select Field"
          {...register("identifier", {
            required: "Field required",
          })}
        >
          {availableConfigFields.map((field, idx) => (
            <option key={`${field.identifier}-${idx}`} value={field.identifier}>
              {field.displayName}
            </option>
          ))}
        </Select>
        <FormErrorMessage>
          {errors.identifier && errors.identifier.message}
        </FormErrorMessage>
      </FormControl>

      <IconButton
        aria-label="Add Field"
        icon={<MdAdd size="1.5rem" />}
        colorScheme="dashboard-primary-button"
        isDisabled={isSubmitting}
        onClick={onAddClick}
      />
    </Flex>
  );
};
