import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import "./DashboardBreadcrumb.scss";
import {
  Breadcrumb,
  BreadcrumbItem,
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  Icon,
  Text,
} from "@chakra-ui/react";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { MdDashboard } from "react-icons/md";
import { useUpdateDashboardMutation } from "@/store/dashboards/dashboards.slice";
import { usePrevious } from "react-use";
import { FaShareAltSquare } from "react-icons/fa";
import { useCollaboratorHasPerms } from "@/hooks/dashboard/useCollaboratorHasPerms";
import * as DashboardCollaboratorTypeModel from "@/models/dashboard/dashboard-collaborator-type.model";
import { useCollaboratorType } from "@/hooks/dashboard/useCollaboratorType";
import { assert } from "tsafe";

export interface Props {
  dashboard: Dashboard;
}

export const DashboardBreadcrumb: FunctionComponent<Props> = (props) => {
  const { dashboard } = props;

  const prevDashboard = usePrevious(dashboard);

  const [currNameInput, setCurrNameInput] = useState(dashboard.name);

  const [updateDashboard] = useUpdateDashboardMutation();

  const submitName = (newName: string) => {
    newName = newName.trim();
    if (newName === "") {
      setCurrNameInput(dashboard.name);
      return;
    }

    // No change
    if (newName === dashboard.name) return;

    // Update the dashboard name
    const newDashboard: Dashboard = { ...dashboard, name: newName };
    updateDashboard(newDashboard);
  };

  const [currCategoryInput, setCurrCategoryInput] = useState(
    dashboard.category ?? ""
  );
  const isCurrCategoryCategorized = useMemo(
    () => currCategoryInput !== "",
    [currCategoryInput]
  );

  // Reinitialize the state whenever the dashboard changes
  useEffect(() => {
    if (prevDashboard?.id !== dashboard.id) {
      setCurrNameInput(dashboard.name);
      setCurrCategoryInput(dashboard.category ?? "");
    }
  }, [dashboard, prevDashboard]);

  const submitCategory = (newCategory: string | null) => {
    newCategory = newCategory?.trim() ?? null;
    if (newCategory === "") newCategory = null;

    // No change
    if (newCategory === dashboard.category) return;

    // Update the dashboard category
    const newDashboard: Dashboard = { ...dashboard, category: newCategory };
    updateDashboard(newDashboard);
  };

  const isOwner = useCollaboratorHasPerms(dashboard, "owner");
  const hasEditorPerms = useCollaboratorHasPerms(dashboard, "editor");

  const collaboratorType = useCollaboratorType(dashboard);
  assert(collaboratorType);

  const baseIcon = isOwner ? MdDashboard : FaShareAltSquare;
  const baseText = isOwner ? "My Dashboards" : dashboard.owner;

  return (
    <Breadcrumb alignItems="center" className="DashboardBreadcrumb">
      {/* Base crumb */}
      <BreadcrumbItem
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        <HStack spacing="1">
          <Icon as={baseIcon}></Icon>
          <Text
            fontSize="sm"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {baseText}
          </Text>
        </HStack>
      </BreadcrumbItem>

      {/* Category crumb */}
      <BreadcrumbItem whiteSpace="nowrap">
        <Editable
          value={currCategoryInput}
          onChange={setCurrCategoryInput}
          onCancel={setCurrCategoryInput}
          onSubmit={submitCategory}
          fontSize="sm"
          placeholder="(Uncategorized)"
          isDisabled={!hasEditorPerms}
          isPreviewFocusable={hasEditorPerms}
        >
          <EditablePreview
            color={isCurrCategoryCategorized ? "white" : "gray.100"}
            fontStyle={isCurrCategoryCategorized ? "normal" : "italic"}
          />
          <EditableInput
            _placeholder={{
              color: "gray.400",
              fontStyle: "italic",
            }}
          />
        </Editable>
      </BreadcrumbItem>

      {/* Name crumb (and collaborator type display, if needed)*/}
      <BreadcrumbItem alignItems="center" whiteSpace="nowrap" padding="1">
        <Editable
          value={currNameInput}
          onChange={setCurrNameInput}
          onCancel={setCurrNameInput}
          onSubmit={submitName}
          fontSize="sm"
          isDisabled={!hasEditorPerms}
          isPreviewFocusable={hasEditorPerms}
        >
          <EditablePreview />
          <EditableInput />
        </Editable>

        {/* User is a collaborator of the dashboard, display their
        collaboration level*/}
        {!isOwner && (
          <Text
            fontSize="sm"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            color="gray.500"
            fontStyle="italic"
            marginLeft="1"
          >
            ({DashboardCollaboratorTypeModel.toDisplayString(collaboratorType)})
          </Text>
        )}
      </BreadcrumbItem>
    </Breadcrumb>
  );
};
