import React, { VoidFunctionComponent } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as Validators from "@/validators";
import { HydratableDivider } from "@/components/data-display/HydratableDivider";
import { FaGoogle } from "react-icons/fa";
import { useState } from "react";
import { useAuth } from "@/hooks/auth/auth.hooks";

interface LoginFormInputs {
  email: string;
  password: string;
}

const EMAIL_INPUT_ID = "login-form-email-input";
const PASSWORD_INPUT_ID = "login-form-password-input";

const LoginForm: VoidFunctionComponent = (props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginFormInputs>();

  const [isLoggingInEmail, setIsLoggingInEmail] = useState<boolean>(false);
  const [isLoggingInGoogle, setIsLoggingInGoogle] = useState<boolean>(false);

  const [globalError, setGlobalError] = useState<String | null>(null);

  const { loginWithEmailPassword, loginWithGoogle } = useAuth(false);

  const loginWithEmailPasswordHandler = async (values: LoginFormInputs) => {
    setIsLoggingInEmail(true);
    const user = await loginWithEmailPassword(values.email, values.password);

    if (!user) {
      setIsLoggingInEmail(false);
      setGlobalError("Unable to login");
    }
  };

  const loginWithGoogleHandler = async () => {
    setIsLoggingInGoogle(true);
    const user = await loginWithGoogle();

    if (!user) {
      setIsLoggingInGoogle(false);
      setGlobalError("Unable to login");
    }
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      shadow="xl"
      width="100%"
      maxWidth="lg"
      padding="5"
      backgroundColor="white"
      rounded="lg"
      as="form"
      onSubmit={handleSubmit(loginWithEmailPasswordHandler)}
      noValidate
    >
      <Text fontSize="2xl">Login</Text>
      {/* Email input */}
      <FormControl isInvalid={!!errors.email}>
        <FormLabel htmlFor={EMAIL_INPUT_ID}>Email</FormLabel>
        <Input
          id={EMAIL_INPUT_ID}
          placeholder="john.doe@example.com"
          {...register("email", {
            required: "Email is required",
            validate: {
              email: Validators.isEmail(),
            },
          })}
          type="email"
          isDisabled={isLoggingInGoogle}
        />
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>
      <Box height="3" />
      {/* Password input */}
      <FormControl isInvalid={!!errors.password}>
        <FormLabel htmlFor={PASSWORD_INPUT_ID}>Password</FormLabel>
        <Input
          id={PASSWORD_INPUT_ID}
          {...register("password", {
            required: "Password is required",
            // TODO: add a password length check or something
          })}
          type="password"
          isDisabled={isLoggingInGoogle}
        />
        <FormErrorMessage>
          {errors.password && errors.password.message}
        </FormErrorMessage>
      </FormControl>
      <Box height="3" />
      {/* Submit button */}
      <Button
        colorScheme="blue"
        type="submit"
        isLoading={isLoggingInEmail}
        isDisabled={isLoggingInGoogle}
      >
        Login
      </Button>
      <Box height="3" />
      {/* Divider line */}
      <HydratableDivider orientation="horizontal" axisSize="100%">
        <Text fontStyle="italic">OR</Text>
      </HydratableDivider>
      <Box height="3" />
      {/* Signin with Google button */}
      <Button
        colorScheme="red"
        onClick={loginWithGoogleHandler}
        isLoading={isLoggingInGoogle}
        isDisabled={isLoggingInEmail}
      >
        <HStack spacing="3">
          <Icon as={FaGoogle} />
          <Text>Signin with Google</Text>
        </HStack>
      </Button>
      <Box height="3" />
      {/* Global error */}
      <FormControl isInvalid={!!globalError}>
        <Center>
          <FormErrorMessage>{globalError}</FormErrorMessage>
        </Center>
      </FormControl>
    </Flex>
  );
};

export default LoginForm;
