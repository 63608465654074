import React, { useMemo } from "react";
import { Flex, forwardRef, HTMLChakraProps, Text } from "@chakra-ui/react";
import * as Utilities from "@/utilities";

// Referenced for the how-to in integrating Chakra-related props:
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/spinner/src/spinner.tsx

interface UserAvatarOptions {
  /**
   * The email that belongs to the user avatar.
   */
  email: string;
}

export interface Props
  extends Omit<HTMLChakraProps<"div">, keyof UserAvatarOptions>,
    UserAvatarOptions {}

export const UserAvatar = forwardRef<Props, "div">((props, ref) => {
  const { email, ...rest } = props;

  const avatarChar = useMemo(() => {
    return email.slice(0, 1).toUpperCase();
  }, [email]);

  const colorStr = useMemo(() => {
    const color = Utilities.stringToColor(email);
    return color.rgb().toString();
  }, [email]);

  return (
    <Flex
      ref={ref}
      borderRadius="50%"
      backgroundColor={colorStr}
      justifyContent="center"
      alignItems="center"
      width="1.8rem"
      height="1.8rem"
      flexShrink={0}
      flexGrow={0}
      {...rest}
    >
      <Text fontWeight="bold" fontSize="md">
        {avatarChar}
      </Text>
    </Flex>
  );
});
