import { useDebouncedEffect } from "@react-hookz/web";
import React, { useEffect, useRef, useState } from "react";

/**
 * Returns true if any of the given refs are being hovered over. To combat state
 * jittering, the hover status will only be updated `delay` milliseconds
 * after the last hover state update but at least once every `maxWait`
 * milliseconds. `maxWait` of 0 disables the max wait functionality.
 */
export function useMultiHover(
  refs: React.RefObject<HTMLElement>[],
  delay = 20,
  maxWait = 500,
) {
  const [hoverStates, setHoverStates] = useState<boolean[]>([]);

  const mouseEnterHandlers = useRef<(() => void)[]>([]);
  const mouseLeaveHandlers = useRef<(() => void)[]>([]);

  const setHoverState = (idx: number, value: boolean) => {
    setHoverStates((hoverStates) => {
      const newHoverStates = [...hoverStates];
      newHoverStates[idx] = value;
      return newHoverStates;
    });
  };

  useEffect(() => {
    // New ref(s) were added, expand the hover state array to account for it
    if (hoverStates.length < refs.length) {
      const numNew = refs.length - hoverStates.length;
      setHoverStates((hoverStates) => {
        const newHoverStates = [...hoverStates];
        newHoverStates.concat(Array(numNew).fill(false));
        return newHoverStates;
      });
    }
    // Ref(s) were removed, since we don't know which ones were removed,
    // reinitialize the hover state array to the current ref size
    else if (hoverStates.length > refs.length) {
      setHoverStates(() => Array(refs.length).fill(false));

      // Note: if we want to just remove off the back we can do this instead
      // const numRemove = hoverStates.length - refs.length;
      // setHoverStates((hoverStates) => {
      //   const newHoverStates = [...hoverStates];
      //   newHoverStates.splice(hoverStates.length, numRemove);
      //   return newHoverStates;
      // });
    }
  }, [refs.length]);

  useEffect(() => {
    mouseEnterHandlers.current = [];
    mouseLeaveHandlers.current = [];

    for (let i = 0; i < refs.length; i++) {
      const ref = refs[i];

      if (ref.current !== null) {
        const mouseEnterHandler = () => {
          setHoverState(i, true);
        };

        const mouseLeaveHandler = () => {
          setHoverState(i, false);
        };

        ref.current.addEventListener("mouseenter", mouseEnterHandler);
        ref.current.addEventListener("mouseleave", mouseLeaveHandler);

        mouseEnterHandlers.current.push(mouseEnterHandler);
        mouseLeaveHandlers.current.push(mouseLeaveHandler);
      }
    }

    return () => {
      for (let i = 0; i < refs.length; i++) {
        const ref = refs[i];

        if (ref.current !== null) {
          ref.current.removeEventListener(
            "mouseenter",
            mouseEnterHandlers.current[i]
          );
          ref.current.removeEventListener(
            "mouseleave",
            mouseLeaveHandlers.current[i]
          );
        }
      }
    };
  }, [refs]);

  const [isHovering, setIsHovering] = useState<boolean>(false);
  useDebouncedEffect(() => {
    setIsHovering(hoverStates.some((hovering) => hovering === true));
  }, [hoverStates], delay, maxWait);

  return isHovering;
}
