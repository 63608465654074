import firebase from "firebase/app";
import { PermissionAction } from "@/models/permissions/permission-action.model";
import { assert } from "tsafe";

export interface User {
  email: string;
  permissionAction: PermissionAction | null;
}

export const fromFirebaseUser = (
  user: firebase.User | null,
): User | null => {
  if (!user) return null;

  assert(user.email);

  return {
    email: user.email,
    permissionAction: null,
  };
};
