import { CellRegistryItem } from "@/cells/cell.types";
import { RandomPieChartCell } from "./RandomPieChartCell";
import { configSchema, ConfigType } from "./randomPieChartCell.models";
import { randomPieChartCellApiSlice } from "./randomPieChartCell.slice";
import { RandomPieChartCellExtraConfig } from "./RandomPieChartCellExtraConfig";
import * as PollRate from "@/cell-config-fields/registry/poll_rate_config_field";
// export { RandomPieChartCell as Cell } from "./RandomPieChartCell";
// export { RandomPieChartCellExtraConfig as ExtraConfig } from "./RandomPieChartCellExtraConfig";
export type { ConfigType } from "./randomPieChartCell.models";
// export { configSchema } from "./randomPieChartCell.models";

export const identifier = "random_pie_chart_cell";

export const storeReducer = randomPieChartCellApiSlice.reducer;
export const storeReducerPath = randomPieChartCellApiSlice.reducerPath;
export const storeMiddleware = randomPieChartCellApiSlice.middleware;

export const cellRegistryItem: CellRegistryItem<ConfigType, any> = {
  CellComponent: RandomPieChartCell,
  CellExtraConfigComponent: RandomPieChartCellExtraConfig,
  configSchema: configSchema,
  commonConfigFields: [
    PollRate.configField,
  ],
  globalLevelDefaultConfigs: {
    pollRate: 15 * 1000,
    numSections: 4,
  }
};