import * as Utilities from "@/utilities";

// Source: https://github.com/jquense/yup/issues/1384#issuecomment-880559097

export type DashboardCollaboratorType =
  typeof DASHBOARD_COLLABORATOR_TYPE_VALUES[number];
export const DASHBOARD_COLLABORATOR_TYPE_VALUES = [
  "viewer",
  "editor",
  "co_owner",
] as const;

export type DashboardCollaboratorTypeWithOwner =
  typeof DASHBOARD_COLLABORATOR_TYPE_VALUES_WITH_OWNER[number];
export const DASHBOARD_COLLABORATOR_TYPE_VALUES_WITH_OWNER = [
  ...DASHBOARD_COLLABORATOR_TYPE_VALUES,
  "owner",
] as const;

export const DASHBOARD_COLLABORATOR_TYPE_WEIGHTS: {
  [collaborationType in DashboardCollaboratorTypeWithOwner]: number;
} = {
  owner: 3,
  co_owner: 2,
  editor: 1,
  viewer: 0,
};

/**
 * Returns true if the given collaborator type's weighting is equal or higher
 * to the target collaborator type.
 */
export function dashboardCollaboratorTypeHasWeightOf(
  collaboratorType: DashboardCollaboratorTypeWithOwner,
  targetCollaboratorType: DashboardCollaboratorTypeWithOwner
): boolean {
  const collaboratorWeight =
    DASHBOARD_COLLABORATOR_TYPE_WEIGHTS[collaboratorType];
  const targetCollaboratorWeight =
    DASHBOARD_COLLABORATOR_TYPE_WEIGHTS[targetCollaboratorType];
  return collaboratorWeight >= targetCollaboratorWeight;
}

export function decode(value: string): DashboardCollaboratorType {
  if (!DASHBOARD_COLLABORATOR_TYPE_VALUES.includes(value as any))
    throw "Corresponding dashboard collaborator type not found";
  return value as DashboardCollaboratorType;
}

export function encode(
  dashboardCollaboratorType: DashboardCollaboratorType
): string {
  return dashboardCollaboratorType;
}

export function toDisplayString(
  dashboardCollaboratorType: DashboardCollaboratorTypeWithOwner
) {
  return Utilities.capitalizeWords(
    dashboardCollaboratorType.replaceAll("_", "-")
  );
}
