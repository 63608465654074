import { KEYS } from "@/keys";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as CellModel from "@/models/cells/cell.model";
import { Cell } from "@/models/cells/cell.model";
import * as Utilities from "@/utilities";

const LIST_KEY = "__LIST__";

export const cellsApiSlice = createApi({
  reducerPath: "cellsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: KEYS.API_URL,
    prepareHeaders: Utilities.authenticatedPrepareHeaders,
  }),
  tagTypes: ["Cells"],
  endpoints: (builder) => ({
    getCells: builder.query<Cell<any, any>[], void>({
      query: () => "/cells",
      transformResponse: (cellsJson: unknown[]) => {
        return cellsJson.map((cellJson) => CellModel.fromJson(cellJson));
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ identifier }) => ({
                type: "Cells" as const,
                id: identifier,
              })),
              { type: "Cells", id: LIST_KEY },
            ]
          : [{ type: "Cells", id: LIST_KEY }],
    }),
    getCell: builder.query<Cell<any, any>, string>({
      query: (identifier) => `/cells/${identifier}`,
      transformResponse: (cellJson) => CellModel.fromJson(cellJson),
      providesTags: (result) =>
        result ? [{ type: "Cells", id: result.identifier }] : [],
    }),
  }),
});

export const { useGetCellsQuery, useGetCellQuery } = cellsApiSlice;
