import { useRef } from "react";
import { useResizeObserver, useThrottledState } from "@react-hookz/web";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ResizeObserver from "resize-observer-polyfill";

/**
 * Measures the component's size, throttling the update rate.
 */
export function useThrottledMeasure<E extends Element>(
  throttle = 100
): [ResizeObserverEntry | undefined, React.RefObject<E>] {
  const ref = useRef<E>(null);
  const [measure, setMeasure] = useThrottledState<
    ResizeObserverEntry | undefined
  >(undefined, throttle);
  useResizeObserver(ref, (entry) => setMeasure(entry));

  return [measure, ref];
}
