import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ConfigType } from ".";
import { KEYS } from "@/keys";
import queryString from "query-string";
import * as Utilities from "@/utilities";

type RandomTimeseriesChartCellMessageDataPoint = [number, number];

interface RandomTimeseriesChartCellMessage {
  type: "initial_data" | "new_data";
  data: RandomTimeseriesChartCellMessageDataPoint[];
}

interface RandomTimeseriesChartDataMessage {
  newDataPoints: RandomTimeseriesChartCellMessageDataPoint[] | null;
  allDataPoints: RandomTimeseriesChartCellMessageDataPoint[];
}

const REDUCER_PATH = "cells__randomTimeseriesChartCellApi";

export const randomTimeseriesChartCellApiSlice = createApi({
  // How long to wait before closing a ws connection after it is not used.
  keepUnusedDataFor: 5,
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: KEYS.API_URL,
    prepareHeaders: Utilities.authenticatedPrepareHeaders,
  }),
  endpoints: (builder) => ({
    dataStream: builder.query<
      RandomTimeseriesChartDataMessage | null,
      Partial<ConfigType>
    >({
      query: ({ pollRate }) => ({
        url: "/cells/fake",
        params: {
          poll_rate: pollRate,
        },
      }),
      transformResponse: () => null,
      async onCacheEntryAdded(
        { pollRate, auth_token },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const params = queryString.stringify({
          poll_rate: pollRate,
          authorization: auth_token,
        });

        const { data: prevData } = await cacheDataLoaded;

        // Keep track of all the data points that have been seen
        let allDataPoints = prevData?.allDataPoints
          ? [...prevData.allDataPoints]
          : [];

        const ws = new WebSocket(
          `${KEYS.API_WS_URL}/cells/random_timeseries_chart_cell/ws?${params}`
        );

        try {
          const listener = (event: MessageEvent) => {
            const newData = JSON.parse(
              event.data
            ) as RandomTimeseriesChartCellMessage;

            let nextMessage: RandomTimeseriesChartDataMessage;
            if (newData.type === "initial_data") {
              nextMessage = {
                newDataPoints: null,
                allDataPoints: newData.data,
              };
              allDataPoints = [...newData.data];
            } else {
              // TODO: this excessive copying of allDataPoints might become
              // a performance issue later on. I should find a way to just
              // mutate it.
              // I was getting this error when trying to do it earlier:
              // https://stackoverflow.com/questions/52492093/typeerror-cannot-add-property-1-object-is-not-extensible-at-array-push-anon
              allDataPoints = [...allDataPoints, ...newData.data];
              nextMessage = {
                newDataPoints: newData.data,
                allDataPoints: allDataPoints,
              };
            }

            updateCachedData((currRng) => {
              return nextMessage;
            });
          };

          ws.addEventListener("message", listener);
        } catch {
          // Do nothing
        }

        await cacheEntryRemoved;

        ws.close();
      },
    }),
  }),
});

export const { useDataStreamQuery } = randomTimeseriesChartCellApiSlice;
