import * as yup from "yup";
import { YupSchema } from "@/utilities";
import { authenticatedCellSchema } from "@/cells/cell.types";
import {
  BaseCurrency,
  BASE_CURRENCY_VALUES,
} from "@/cell-config-fields/registry/base_currency_config_field";

export type ConfigType = YupSchema<typeof configSchema>;

export const configSchema = authenticatedCellSchema.shape({
  pollRate: yup.number().positive().nullable(),
  baseCurrency: yup
    .mixed<BaseCurrency>()
    .oneOf([...BASE_CURRENCY_VALUES])
    .nullable(),
  aggLevel: yup.string().nullable(),
  expirationType: yup.string().nullable(),
});

export type AdditionalDataType = YupSchema<typeof additionalDataSchema>;
export type AdditionalDataAvailableFilters = YupSchema<
  typeof additionalDataAvailableFiltersSchema
>;
export type AdditionalDataDefaultFilters = YupSchema<
  typeof additionalDataDefaultFiltersSchema
>;

const additionalDataAvailableFiltersSchema = yup.object({
  aggLevels: yup.array().of(yup.string().required()).min(0).required(),
  expirationTypes: yup.array().of(yup.string().required()).min(0).required(),
});

const additionalDataDefaultFiltersSchema = yup.object({
  aggLevel: yup.string().required(),
  expirationType: yup.string().required(),
});

export const additionalDataSchema = yup.object({
  availableFilters: additionalDataAvailableFiltersSchema,
  defaultFilters: additionalDataDefaultFiltersSchema,
});
