import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  ThemingProps,
} from "@chakra-ui/react";
import * as Utilities from "@/utilities";

type ButtonStatus = "idle" | "submitting";

export type ModalStatus = "idle" | "submitting";

export interface ModalBaseFooterButtons extends ThemingProps {
  content: JSX.Element | Element | string;
  onClick: () => Promise<boolean> | boolean;
  doCloseOnClick?: boolean;
}

export interface Props extends Omit<ModalProps, "children"> {
  isOpen: boolean;
  title?: JSX.Element | Element | string;
  footerButtons: ModalBaseFooterButtons[];
  onClose: () => void;
  renderContent?: (modalStatus: ModalStatus) => JSX.Element | Element | string;
}

export const ModalBase: FunctionComponent<Props> = (props) => {
  const { isOpen, title, footerButtons, onClose, renderContent, ...rest } =
    props;

  const getInitialButtonStatuses = useCallback<() => ButtonStatus[]>(() => {
    return footerButtons.map(() => "idle");
  }, [footerButtons]);

  const [buttonStatuses, setButtonStatuses] = useState<ButtonStatus[]>(
    getInitialButtonStatuses()
  );

  const setButtonStatus = useCallback(
    (status: ButtonStatus, buttonIdx: number) => {
      setButtonStatuses((statuses) => {
        const newStatuses = [...statuses];
        newStatuses[buttonIdx] = status;
        return newStatuses;
      });
    },
    [setButtonStatuses]
  );

  const anyButtonsSubmitting = useMemo(() => {
    return buttonStatuses.some((status) => status === "submitting");
  }, [buttonStatuses]);

  const buttons = useMemo(() => {
    return footerButtons.map((button, idx) => {
      const {
        content,
        onClick,
        colorScheme,
        variant,
        orientation,
        size,
        styleConfig,
        doCloseOnClick = false
      } = button;

      const status = buttonStatuses[idx];

      const clickHandler = async () => {
        const clickStatus = onClick();

        if (Utilities.isPromise(clickStatus))
          setButtonStatus("submitting", idx);

        const close = await clickStatus;

        setButtonStatus("idle", idx);

        if (doCloseOnClick && close) onClose();
      };

      return (
        <Button
          key={`modal-base-button-${idx}`}
          isDisabled={anyButtonsSubmitting}
          isLoading={status === "submitting"}
          colorScheme={colorScheme ?? "blue"}
          variant={variant}
          orientation={orientation}
          size={size}
          styleConfig={styleConfig}
          onClick={clickHandler}
        >
          {content}
        </Button>
      );
    });
  }, [
    footerButtons,
    buttonStatuses,
    anyButtonsSubmitting,
    setButtonStatus,
    onClose,
  ]);

  return (
    <Modal {...rest} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {renderContent &&
            renderContent(anyButtonsSubmitting ? "submitting" : "idle")}
        </ModalBody>

        <ModalFooter>
          <HStack spacing="2">{buttons}</HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
