import React, { VoidFunctionComponent } from "react";
import { Box, Flex, Image, VStack } from "@chakra-ui/react";
import LoginForm from "./form/LoginForm";

import logo from "@/images/162_logo_digital_capital.png";
import { Helmet } from "react-helmet-async";

export const LoginView: VoidFunctionComponent = (props) => {
  return (
    <>
      <Helmet>
        <title>Skew Dashboard | Login</title>
      </Helmet>

      <Flex
        id="LoginView"
        height="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <VStack spacing="5" padding="5" width="100%">
          <Box maxWidth="xl">
            <Image src={logo} />
          </Box>
          <LoginForm />
        </VStack>
      </Flex>
    </>
  );
};
