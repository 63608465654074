import { CellRegistry, CellRegistryItem } from "./cell.types";

import * as RandomNumberCell from "@/cells/cell_registry/random_number_cell";
import * as RandomPieChartCell from "@/cells/cell_registry/random_pie_chart_cell";
import * as RandomTimeseriesChartCell from "@/cells/cell_registry/random_timeseries_chart_cell";
import * as TradingBreakdownCell from "@/cells/cell_registry/trading_breakdown_cell";

const cell_registry: CellRegistry = {
  [RandomNumberCell.identifier]: RandomNumberCell.cellRegistryItem,
  [RandomPieChartCell.identifier]: RandomPieChartCell.cellRegistryItem,
  [RandomTimeseriesChartCell.identifier]:
    RandomTimeseriesChartCell.cellRegistryItem,
  [TradingBreakdownCell.identifier]: TradingBreakdownCell.cellRegistryItem,
};

export function lookupCellRegistryItem(
  cell_identifier: string
): CellRegistryItem<any, any> | null {
  const item = cell_registry[cell_identifier];

  return item ? item : null;
}
