import { ValidateResult } from "react-hook-form";

export const isEmail = (errorMessage: string = "Invalid email") => {
  return (email: string): ValidateResult => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = emailRegex.test(email.toLowerCase());
    return isValidEmail || errorMessage;
  };
};
