import React, { FunctionComponent, useEffect, useRef } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
  Text,
  Select,
} from "@chakra-ui/react";
import {
  ConfirmationModal,
  Props as ConfirmationModalProps,
} from "../ConfirmationModal/ConfirmationModal";
import { useForm } from "react-hook-form";
import { useAppToasts } from "@/hooks/toasts/useAppToasts";
import {
  PermissionAction,
  PERMISSION_ACTION_VALUES,
} from "@/models/permissions/permission-action.model";
import * as PermissionActionModel from "@/models/permissions/permission-action.model";
import { PermissionGrant } from "@/models/permissions/permission-grant.model";
import * as Validators from "@/validators";
import { useGrantPermissionMutation } from "@/store/permissions/permissions.slice";

interface BaseProps
  extends Omit<
    ConfirmationModalProps,
    "renderContent" | "confirmationButtonOnClick" | "initialFocusRef"
  > {}

interface CreateProps extends BaseProps {
  mode: "create";
}

interface EditProps extends BaseProps {
  mode: "edit";
  permission: PermissionGrant;
}

export type Props = CreateProps | EditProps;

const EMAIL_INPUT_ID = "permission-modifier-modal-email-input";
const ACTION_INPUT_ID = "permission-modifier-modal-action-input";

interface CreateFormInputs {
  email: string;
  action: PermissionAction;
}

export const PermissionModifierModal: FunctionComponent<Props> = (props) => {
  const { mode, ...rest } = props;
  const { isOpen } = rest;

  const [grantPermission] = useGrantPermissionMutation();

  const { errorToast } = useAppToasts();

  // Weird quirk with the typings
  // https://stackoverflow.com/a/58033283
  const initRef = useRef<HTMLInputElement | null>(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<CreateFormInputs>();

  // Reset the state every time the modal is reopened
  useEffect(() => {
    if (isOpen)
      reset(
        mode === "edit"
          ? (props as EditProps).permission
          : {
              action: "user",
            }
      );
  }, [isOpen, mode]);

  const handleSubmitPromise = () => {
    return new Promise<CreateFormInputs>((resolve, reject) => {
      handleSubmit(
        (data) => {
          resolve(data);
        },
        (errs) => {
          reject();
        }
      )();
    });
  };

  const confirmationButtonOnClickWrapped = async () => {
    try {
      const data = await handleSubmitPromise();

      try {
        await grantPermission({
          email: data.email!,
          newAction: data.action,
        }).unwrap();

        return true;
      } catch (err) {
        errorToast(
          `Unable to ${mode === "create" ? "add" : "edit"} permission.`
        );
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  const { ref: registerNameInputRef, ...registerNameInputRest } = register(
    "email",
    {
      required: "Email is required",
      validate: {
        email: Validators.isEmail(),
      },
    }
  );

  return (
    <ConfirmationModal
      isCentered
      title={mode === "create" ? "Add Permission" : "Edit Permission"}
      confirmationButtonContent="Edit"
      {...rest}
      confirmationButtonOnClick={confirmationButtonOnClickWrapped}
      initialFocusRef={initRef}
      renderContent={(status) => (
        <VStack spacing={2} width="100%">
          <FormControl id={EMAIL_INPUT_ID} isInvalid={!!errors.email}>
            <FormLabel>Email</FormLabel>
            <Input
              placeholder="Enter email..."
              disabled={mode === "edit" || status === "submitting"}
              {...registerNameInputRest}
              // How to share the ref: https://react-hook-form.com/faqs#Howtosharerefusage
              ref={(e) => {
                registerNameInputRef(e);
                initRef.current = e;
              }}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl id={ACTION_INPUT_ID} isInvalid={!!errors.action}>
            <FormLabel>
              <Text display="inline">Action</Text>
            </FormLabel>

            <Select
              placeholder="Select action"
              {...register("action", {
                required: "Action required",
              })}
            >
              {PERMISSION_ACTION_VALUES.map((action, idx) => (
                <option key={`${action}-${idx}`} value={action}>
                  {PermissionActionModel.toDisplayString(action)}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.action && errors.action.message}
            </FormErrorMessage>
          </FormControl>
        </VStack>
      )}
    />
  );
};
