import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import {
  CellAdditionalDataType,
  CellConfigBaseType,
  CellConfigSubmissionHandler,
  CellRegistryItem,
} from "@/cells/cell.types";
import {
  ConfirmationModal,
  Props as ConfirmationModalProps,
} from "@/components/modals/ConfirmationModal/ConfirmationModal";
import { Cell } from "@/models/cells/cell.model";
import { CellConfigProps } from "@/cells/cell.types";
import { DashboardCellInstance } from "@/models/dashboard/dashboard-cell-instance.model";
import { useForm } from "react-hook-form";
import { CellConfigFieldSettingsRenderer } from "@/cell-config-fields/CellConfigFieldSettingsRenderer";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import * as Utilities from "@/utilities";

export type DashboardCellInstanceSettings<C extends CellConfigBaseType> = Pick<
  DashboardCellInstance<C>,
  "displayName" | "config"
>;

export interface Props<
  C extends CellConfigBaseType,
  A extends CellAdditionalDataType
> extends Omit<
      ConfirmationModalProps,
      "renderContent" | "confirmationButtonOnClick"
    >,
    Omit<
      CellConfigProps<C, A>,
      | "explicitConfig"
      | "dashboardLevelConfig"
      | "globalLevelConfig"
      | "status"
      | "updateConfig"
      | "submissionHandlerRef"
    > {
  cell: Cell<C>;
  dashboard: Dashboard;
  currentSettings: DashboardCellInstanceSettings<C>;
  onSettingsChanged: (newSettings: DashboardCellInstanceSettings<C>) => void;
  cellDefaultDisplayName: string | null;
  cellDisplayName: string;
  cellRegistryItem: CellRegistryItem<Partial<C>, A> | null;
}

interface SettingsInputsBase {
  displayName: string;
}

type SettingsInputs<C extends CellConfigBaseType> = SettingsInputsBase | C;

const DISPLAY_NAME_INPUT_ID =
  "dashboard-grid-cell-settings-modal-display-name-input";

export const DashboardGridCellSettingsModal = <
  C extends CellConfigBaseType,
  A extends CellAdditionalDataType
>(
  props: Props<C, A>
) => {
  const {
    cell,
    currentSettings,
    additionalData,
    onSettingsChanged,
    onClose,
    isOpen,
    cellDefaultDisplayName,
    cellDisplayName,
    cellRegistryItem,
    dashboard,
    ...rest
  } = props;

  const [modalSettings, setModalSettings] =
    useState<DashboardCellInstanceSettings<C>>(currentSettings);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<SettingsInputs<C>>();

  // Note: for whatever reason setting the default values in useForm does
  // not work but doing it this way does.
  // Source: https://stackoverflow.com/a/67947960
  useEffect(() => {
    reset({
      displayName: currentSettings.displayName ?? "",
    });
  }, [currentSettings.displayName, reset]);

  // Weird quirk with the typings
  // https://stackoverflow.com/a/58033283
  const initRef = useRef<HTMLInputElement | null>(null);

  const handleSubmitPromise = () => {
    return new Promise<SettingsInputs<C>>((resolve, reject) => {
      handleSubmit(
        (data) => {
          resolve(data as SettingsInputs<C>);
        },
        (errs) => {
          reject();
        }
      )();
    });
  };

  // This is a somewhat hacky way to allow this component run functions from
  // within the CellSettingsComponent implementation. In our case, we want to
  // await for a submission function as defined internally by
  // CellSettingsComponent. This is useful for things like allowing the the
  // CellSettingsComponent to validate a form before deciding whether to submit
  // or not.
  // So far this looks promising: https://stackoverflow.com/a/37950970
  const commonConfigSubmissionHandlerRef = useRef<
    CellConfigSubmissionHandler<C>
  >({});
  const extraConfigSubmissionHandlerRef = useRef<
    CellConfigSubmissionHandler<C>
  >({});

  const resetModalSettings = useCallback(() => {
    setModalSettings(currentSettings);
  }, [currentSettings, setModalSettings]);

  useEffect(() => {
    // Whenever we open the modal again, reset the internal modal settings to
    // the initial settings prop
    if (isOpen) {
      resetModalSettings();
    }
  }, [isOpen, resetModalSettings]);

  const onCloseWrapped = () => {
    // TODO: do something?

    onClose();
  };

  const confirmationButtonOnClick = async () => {
    let settings = modalSettings;

    try {
      // Submit the local form with the display name input
      const data = await handleSubmitPromise();

      settings = {
        ...settings,
        displayName: data.displayName.length > 0 ? data.displayName : null,
      };

      const commonConfigSubmitFunc =
        commonConfigSubmissionHandlerRef.current.submit;
      const extraConfigSubmitFunc =
        extraConfigSubmissionHandlerRef.current.submit;

      // Run the submission functions for the common config component and
      // the extra config component and then combine them

      let commonConfig: Partial<C> | null = {};
      if (commonConfigSubmitFunc) commonConfig = await commonConfigSubmitFunc();

      let extraConfig: Partial<C> | null = {};
      if (extraConfigSubmitFunc) extraConfig = await extraConfigSubmitFunc();

      // Update settings
      settings = {
        ...settings,
        config: {
          ...(settings.config ?? {}),
          ...(commonConfig ?? {}),
          ...(extraConfig ?? {}),
        } as Partial<C>,
      };

      // Note: we remove the empty entries after merging so that we are
      // able to remove existing config fields by setting them to null/undefined
      if (settings.config)
        settings.config = Utilities.removeEmptyEntries(settings.config);

      // TODO: remove
      // console.log("new settings", settings); 

      onSettingsChanged(settings);

      reset();

      return true;
    } catch (err) {
      return false;
    }
  };

  if (!cellRegistryItem) return null;

  const CellExtraConfigComponent = cellRegistryItem.CellExtraConfigComponent;

  const { ref: registerDisplayNameInputRef, ...registerDisplayNameInputRest } =
    register("displayName");

  return (
    <ConfirmationModal
      isCentered
      title={`${cellDisplayName} Settings`}
      confirmationButtonContent="Update"
      {...rest}
      confirmationButtonOnClick={confirmationButtonOnClick}
      onClose={onCloseWrapped}
      isOpen={isOpen}
      initialFocusRef={initRef}
      renderContent={(status) => (
        <Box>
          <VStack direction="column">
            <FormControl
              id={DISPLAY_NAME_INPUT_ID}
              isInvalid={!!errors.displayName}
            >
              <FormLabel>Display Name</FormLabel>
              <Input
                placeholder={cellDefaultDisplayName ?? undefined}
                _placeholder={{
                  color: "gray.400",
                  fontStyle: "italic",
                }}
                disabled={status === "submitting"}
                {...registerDisplayNameInputRest}
                // How to share the ref: https://react-hook-form.com/faqs#Howtosharerefusage
                ref={(e) => {
                  registerDisplayNameInputRef(e);
                  initRef.current = e;
                }}
              />
              <FormErrorMessage>
                {errors.displayName && errors.displayName.message}
              </FormErrorMessage>
            </FormControl>
          </VStack>

          <Box height="3" />

          {/* Common config inputs that are registered in the cell config
          registry */}
          <CellConfigFieldSettingsRenderer
            config={modalSettings.config}
            globalLevelDefaultConfig={
              cellRegistryItem.globalLevelDefaultConfigs
            }
            dashboardLevelDefaultConfig={
              dashboard.dashboardLevelDefaultConfigs as Partial<C> | null
            }
            status={status}
            submissionHandlerRef={commonConfigSubmissionHandlerRef}
            cellRegistryItem={cellRegistryItem}
          />

          <Box height="2" />

          {/* Additional non-common config inputs */}
          {CellExtraConfigComponent && (
            <CellExtraConfigComponent
              explicitConfig={modalSettings.config}
              dashboardLevelConfig={
                dashboard.dashboardLevelDefaultConfigs as Partial<C> | null
              }
              globalLevelConfig={cellRegistryItem.globalLevelDefaultConfigs}
              additionalData={additionalData}
              status={status}
              submissionHandlerRef={extraConfigSubmissionHandlerRef}
            />
          )}
        </Box>
      )}
    />
  );
};
