import { useHistory } from "react-router-dom";

interface UseAppNavigatorReturn {
  homePath: () => string;
  dashboardPath: (id: string) => string;

  toHome: () => void;
  toDashboard: (id: string) => void;
}

export function useAppNavigator(): UseAppNavigatorReturn {
  const history = useHistory();

  const toHome = () => {
    history.push("/");
  };

  const toDashboard = (id: string) => {
    history.push(`/dashboard/${id}`);
  };

  return {
    homePath: () => "/",
    dashboardPath: (id) => `/dashboard/${id}`,
    toHome,
    toDashboard,
  };
}