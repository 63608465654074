import { useAppToasts } from "@/hooks/toasts/useAppToasts";
import { DashboardDefaultFieldConfig } from "@/models/dashboard/dashboard-default-field-config.model";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { useUpdateDashboardMutation } from "@/store/dashboards/dashboards.slice";
import { VStack } from "@chakra-ui/react";
import React, { FunctionComponent, useRef } from "react";
import { assert } from "tsafe";
import {
  ConfirmationModal,
  Props as ConfirmationModalProps,
} from "../ConfirmationModal/ConfirmationModal";
import { DashboardFieldConfigForm } from "./components/DashboardFieldConfigForm";

export interface Props
  extends Omit<
    ConfirmationModalProps,
    "renderContent" | "confirmationButtonOnClick" | "initialFocusRef"
  > {
  dashboard: Dashboard;
}

export interface SubmissionHandlerRef<T> {
  submit?: () => Promise<T>;
}

export const DashboardSettingsModal: FunctionComponent<Props> = (props) => {
  const { dashboard, ...rest } = props;

  const fieldConfigSubmissionHandlerFormRef = useRef<
    SubmissionHandlerRef<DashboardDefaultFieldConfig | null>
  >({});

  const [updateDashboard] = useUpdateDashboardMutation();

  const { errorToast } = useAppToasts();

  const handleConfirmationButtonOnClick = async () => {
    assert(fieldConfigSubmissionHandlerFormRef.current.submit);
    // Gather new config data from the config component
    const configData =
      await fieldConfigSubmissionHandlerFormRef.current.submit();

    if (!configData) {
      errorToast("Unable to update dashboard default field config settings.");
      return false;
    }

    const updatedDashboard: Dashboard = {
      ...dashboard,
      dashboardLevelDefaultConfigs: { ...configData },
    };

    // TODO: remove
    // console.log("Updated dashboard settings", updatedDashboard); 

    try {
      await updateDashboard(updatedDashboard).unwrap();

      return true;
    } catch (err) {
      errorToast("Unable to update dashboard default field config settings.");
      return false;
    }
  };

  return (
    <ConfirmationModal
      isCentered
      title="Dashboard Settings"
      confirmationButtonContent="Update"
      {...rest}
      confirmationButtonOnClick={handleConfirmationButtonOnClick}
      renderContent={(status) => (
        <VStack spacing={2}>
          {/* Note: we have to put the form in here because using useFieldArray
          does not work well with the renderContent render prop.
          TODO: at some point in the future I should look for a way to get rid
          of the renderContent render prop b/c it's a bit of a pain. */}
          <DashboardFieldConfigForm
            dashboard={dashboard}
            submissionHandlerRef={fieldConfigSubmissionHandlerFormRef}
            status={status}
          />
        </VStack>
      )}
    />
  );
};
