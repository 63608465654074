import {
  CellConfigFieldsRegistry,
  CellConfigFieldsRegistryItem,
} from "./cell-config-fields.types";

import * as PollRate from "./registry/poll_rate_config_field";
import * as BaseCurrency from "./registry/base_currency_config_field";

const cell_config_fields_registry: CellConfigFieldsRegistry = {
  [PollRate.configField.identifier]: PollRate.configField,
  [BaseCurrency.configField.identifier]: BaseCurrency.configField,
};

export function lookupCellConfigFieldsRegistryItem(
  identifier: string
): CellConfigFieldsRegistryItem<any> | null {
  return cell_config_fields_registry[identifier] ?? null;
}

export function hasCellConfigFieldsRegistryItem(identifier: string): boolean {
  return !!lookupCellConfigFieldsRegistryItem(identifier);
}

export function getAllCellConfigRegistryItems(): CellConfigFieldsRegistryItem<any>[] {
  return Object.values(cell_config_fields_registry).map(
    (e) => e as CellConfigFieldsRegistryItem<any>
  );
}

export function getCellConfigRegistryItems(
  identifiers: string[]
): CellConfigFieldsRegistryItem<any>[] {
  return identifiers.map((identifier) => {
    const regItem = lookupCellConfigFieldsRegistryItem(identifier);

    if (!regItem) throw `Cell config registry item '${identifiers}' not found`;

    return regItem;
  });
}
