import React, { FunctionComponent, useState } from "react";
import {
  Props as ConfirmationModalProps,
} from "../ConfirmationModal/ConfirmationModal";
import { VStack } from "@chakra-ui/react";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { CollaboratorAddInput } from "./components/CollaboratorAddInput";
import { DashboardCollaboratorType } from "@/models/dashboard/dashboard-collaborator-type.model";
import { CollaboratorList } from "./components/CollaboratorList";
import update from "immutability-helper";
import { useUpdateDashboardMutation } from "@/store/dashboards/dashboards.slice";
import { useAppToasts } from "@/hooks/toasts/useAppToasts";
import { UpdateAndApplyModal } from "../UpdateAndAppyModal/UpdateAndApplyModal";

export interface Props
  extends Omit<
    ConfirmationModalProps,
    "renderContent" | "confirmationButtonOnClick" | "initialFocusRef"
  > {
  dashboard: Dashboard;
}

export const ShareDashboardModal: FunctionComponent<Props> = (props) => {
  const { dashboard: initialDashboard, ...rest } = props;

  const [dashboard, setDashboard] = useState<Dashboard>(initialDashboard);

  const { errorToast } = useAppToasts();

  const [updateDashboard] = useUpdateDashboardMutation();

  const onCollaboratorAdd = (
    email: string,
    type: DashboardCollaboratorType
  ) => {
    setDashboard((prevDashboard) =>
      update(prevDashboard, {
        collaborators: {
          $push: [
            {
              email,
              type,
            },
          ],
        },
      })
    );
  };

  const onCollaboratorTypeChange = (
    collaboratorIdx: number,
    newType: DashboardCollaboratorType
  ) => {
    setDashboard((prevDashboard) =>
      update(prevDashboard, {
        collaborators: {
          [collaboratorIdx]: {
            $merge: {
              type: newType,
            },
          },
        },
      })
    );
  };

  const onCollaboratorRemove = (collaboratorIdx: number) => {
    setDashboard((prevDashboard) =>
      update(prevDashboard, {
        collaborators: {
          $splice: [[collaboratorIdx, 1]],
        },
      })
    );
  };

  const onConfirmationButtonClick = async () => {
    try {
      await updateDashboard(dashboard).unwrap();
      return true;
    } catch (err) {
      errorToast("Unable to update dashboard sharing settings.");
      return false;
    }
  };

  return (
    <UpdateAndApplyModal
      title="Share Dashboard"
      updateButtonContent="Update"
      applyButtonContent="Apply"
      isCentered
      size="2xl"
      {...rest}
      updateButtonOnClick={onConfirmationButtonClick}
      applyButtonOnClick={onConfirmationButtonClick}
      renderContent={() => (
        <VStack spacing="4">
          <CollaboratorAddInput
            dashboard={dashboard}
            onCollaboratorAdd={onCollaboratorAdd}
          />
          <CollaboratorList
            dashboard={dashboard}
            onCollaboratorTypeChange={onCollaboratorTypeChange}
            onCollaboratorRemove={onCollaboratorRemove}
          />
        </VStack>
      )}
    />
  );
};
