import React, { FunctionComponent } from "react";
import { Flex, IconButton, Select, Text, Tooltip } from "@chakra-ui/react";
import {
  DashboardCollaboratorType,
  DashboardCollaboratorTypeWithOwner,
  DASHBOARD_COLLABORATOR_TYPE_VALUES,
} from "@/models/dashboard/dashboard-collaborator-type.model";
import * as DashboardCollaboratorTypeModel from "@/models/dashboard/dashboard-collaborator-type.model";
import { MdClose } from "react-icons/md";
import { UserAvatar } from "@/components/avatars/UserAvatar";

export interface Props {
  email: string;
  type: DashboardCollaboratorTypeWithOwner;
  isSelf: boolean;
  onCollaboratorTypeChange?: (newType: DashboardCollaboratorType) => unknown;
  onRemove?: () => unknown;
}

export const CollaboratorListItem: FunctionComponent<Props> = (props) => {
  const { email, type, isSelf, onRemove, onCollaboratorTypeChange } = props;

  const isOwner = type === "owner";

  const onChangeCollaborationTypeSelect = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (onCollaboratorTypeChange)
      onCollaboratorTypeChange(e.target.value as DashboardCollaboratorType);
  };

  const onClickRemoveCollaborator = () => {
    if (onRemove) onRemove();
  };

  return (
    // Reference for min-width: 0 stuff:
    // https://stackoverflow.com/a/43938523
    <Flex
      flexDirection="row"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex alignItems="center" flexShrink={1} flexGrow={2} minWidth="0" marginRight="1">
        <UserAvatar email={email} />

        <Flex marginLeft="1.5" flexShrink={1} flexGrow={1} minWidth="0">
          <Text
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            minWidth="0"
          >
            {email}
          </Text>
          {isSelf && (
            <Text color="gray.500" marginLeft="1">
              (you)
            </Text>
          )}
        </Flex>
      </Flex>

      <Flex
        alignItems="center"
        justifyContent="flex-end"
        flexShrink={0}
        flexGrow={0}
      >
        {isOwner && (
          <Text textAlign="left" justifySelf="flex-end">
            Owner
          </Text>
        )}

        {/* Collaborator selection dropdown */}
        {!isOwner && (
          <Select
            value={type}
            onChange={onChangeCollaborationTypeSelect}
            size="sm"
            isDisabled={isSelf}
            minWidth="8rem"
          >
            {DASHBOARD_COLLABORATOR_TYPE_VALUES.map((type, idx) => (
              <option key={`${type}-${idx}`} value={type}>
                {DashboardCollaboratorTypeModel.toDisplayString(type)}
              </option>
            ))}
          </Select>
        )}

        {/* Remove collaborator button */}
        {!isOwner && (
          <Tooltip label="Remove Collaborator">
            <IconButton
              aria-label="Remove Collaborator"
              colorScheme="transparent"
              size="sm"
              variant="ghost"
              color="black"
              onClick={onClickRemoveCollaborator}
              icon={<MdClose size="1.5em" />}
            />
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};
