import React, { FunctionComponent } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import { CellConfigFieldInputProps } from "@/cell-config-fields/cell-config-fields.types";
import { Controller } from "react-hook-form";
import { useUuid } from "@/hooks/utils/useUuid";
import { MdRemove } from "react-icons/md";
import * as Utilities from "@/utilities";

export interface Props extends CellConfigFieldInputProps<number> {}

function getDefaultValueRepresentation(
  globalLevelDefaultValue?: number,
  dashboardLevelDefaultValue?: number
) {
  if (dashboardLevelDefaultValue !== undefined)
    return dashboardLevelDefaultValue / (1000 * 60) + "";
  if (globalLevelDefaultValue !== undefined)
    return globalLevelDefaultValue / (1000 * 60) + "";

  return undefined;
}

export const PollRateConfigFieldInput: FunctionComponent<Props> = (props) => {
  const {
    status,
    error,
    control,
    name,
    globalLevelDefaultValue,
    dashboardLevelDefaultValue,
    removeFieldConfig,
    context,
  } = props;

  const id = useUuid({ prefix: "poll-rate-input-" });

  // Reference on how to use number inputs with Chakra and React Hook Form:
  // References:
  // - https://github.com/chakra-ui/chakra-ui/issues/2832#issuecomment-748390514
  // - https://codesandbox.io/s/chakra-ui-react-hook-form-hmgl1?file=/src/App.tsx
  return (
    <FormControl
      id={id}
      isInvalid={!!error}
      isDisabled={status === "submitting"}
    >
      <Controller
        control={control}
        rules={{
          required:
            Utilities.isNullish(dashboardLevelDefaultValue) &&
            Utilities.isNullish(globalLevelDefaultValue)
              ? "Rate parameter required"
              : undefined,
        }}
        name={name}
        render={({ field: { ref, ...restField } }) => {
          return (
            <>
              <FormLabel>
                <Text display="inline">Rate </Text>
                <Text display="inline" color="gray.500">
                  (minutes)
                </Text>
              </FormLabel>
              <HStack width="100%">
                <NumberInput
                  flexGrow={1}
                  min={0.01}
                  precision={2}
                  step={0.1}
                  {...restField}
                  // Convert from milliseconds to minutes
                  value={
                    restField.value
                      ? parseFloat(restField.value + "") / (1000 * 60)
                      : undefined
                  }
                  onChange={(newVal) => {
                    // Convert from minutes to milliseconds
                    const val = parseFloat(newVal) * (1000 * 60);
                    restField.onChange(isNaN(val) ? null : val);
                  }}
                >
                  <NumberInputField
                    ref={ref}
                    name={restField.name}
                    placeholder={getDefaultValueRepresentation(
                      globalLevelDefaultValue,
                      dashboardLevelDefaultValue
                    )}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>

                {/* Conditionally show the remove button only when being 
                    rendered in the dashboard settings modal */}
                {context === "dashboard-settings" && (
                  <IconButton
                    aria-label="Remove Poll Rate"
                    icon={<MdRemove size="1.5rem" />}
                    colorScheme="dashboard-primary-button"
                    onClick={removeFieldConfig}
                  />
                )}
              </HStack>

              <FormErrorMessage>{error && error.message}</FormErrorMessage>
            </>
          );
        }}
      />
    </FormControl>
  );
};
