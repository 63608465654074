import React, { FunctionComponent } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { Pathname } from "history";
import { useAuth } from "@/hooks/auth/auth.hooks";
import { assert } from "tsafe";

// References:
// - https://reactrouter.com/web/example/auth-workflow
// - https://github.com/remix-run/react-router/blob/main/packages/react-router/modules/Route.js

interface Props extends Omit<RouteProps, "render"> {
  /**
   * The path to redirect to if the user is authenticated.
   *
   * @default "/"
   */
  authenticatedPathname?: Pathname;

  /**
   * The path to redirect to if the user is unauthorized.
   *
   * @default "/get-access"
   */
  unauthorizedPathname?: Pathname;
}

/**
 * A wrapper for <Route> that redirects to the the `authenticatedPathname`
 * location if the user has already been authenticated.
 */
export const UnauthenticatedRoute: FunctionComponent<Props> = (props) => {
  const {
    children,
    component,
    authenticatedPathname = "/",
    unauthorizedPathname = "/get-access",
    ...rest
  } = props;
  const { status, currUser } = useAuth(false);

  return (
    <Route
      {...rest}
      render={({ location, match, staticContext }) => {
        // Do not render anything if the authentication system is uninitialized
        if (status === "uninitialized") return null;

        if (status === "authenticated") {
          assert(currUser !== null);

          // User is authorized
          if (currUser.permissionAction !== null)
            return (
              <Redirect
                to={{
                  pathname: authenticatedPathname,
                  state: { from: location },
                }}
              />
            );
          // User is unauthorized
          else
            return (
              <Redirect
                to={{
                  pathname: unauthorizedPathname,
                  state: { from: location },
                }}
              />
            );
        }

        // status === "unauthenticated" || status === "authenticating"

        if (component) {
          const componentProps = { ...staticContext, location, match };
          return React.createElement(component, componentProps);
        }

        return children;
      }}
    />
  );
};
