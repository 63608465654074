import React, { FunctionComponent, useMemo } from "react";
import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { Link } from "react-router-dom";
import { assert } from "tsafe";
import * as Utilities from "@/utilities";
import * as DashboardCollaboratorModel from "@/models/dashboard/dashboard-collaborator.model";
import * as DashboardCollaboratorTypeModel from "@/models/dashboard/dashboard-collaborator-type.model";

export interface Props {
  dashboard: Dashboard;
  selfEmail: string;
}

export const DashboardCard: FunctionComponent<Props> = (props) => {
  const { dashboard, selfEmail } = props;

  const accentColor = useMemo(() => {
    const stringId = `${dashboard.id}-${dashboard.name}-${dashboard.owner}`;
    const clr = Utilities.stringToColor(stringId, 0.5, 0.85);
    return clr.rgb().toString();
  }, [dashboard]);

  const isOwner = dashboard.owner === selfEmail;

  const collaboratorTypeDisplay = useMemo(() => {
    const type = DashboardCollaboratorModel.getCollaboratorType(
      dashboard,
      selfEmail
    );
    assert(type);
    return DashboardCollaboratorTypeModel.toDisplayString(type);
  }, [dashboard, selfEmail]);

  return (
    <Flex
      height="12rem"
      width="18rem"
      flexDir="column"
      backgroundColor="white"
      borderRadius="lg"
      borderColor="gray.100"
      borderStyle="solid"
      borderWidth="1px"
      overflow="hidden"
      shadow="md"
      as={Link}
      to={`/dashboard/${dashboard.id}`}
    >
      <Flex
        paddingY="3"
        paddingX="4"
        flexGrow={1}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        backgroundColor={accentColor}
        overflow="hidden"
      >
        <Tooltip label={dashboard.name}>
          <Text
            width="100%"
            fontWeight="bold"
            fontSize="lg"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            textAlign="center"
          >
            {dashboard.name}
          </Text>
        </Tooltip>

        {!isOwner && (
          <Tooltip label={dashboard.owner}>
            <Text
              width="100%"
              fontStyle="italic"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              textAlign="center"
            >
              {dashboard.owner}
            </Text>
          </Tooltip>
        )}
      </Flex>
      <Flex
        paddingY="3"
        paddingX="4"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        {dashboard.category ? (
          <Text
            width="100%"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            textAlign="center"
          >
            {dashboard.category}
          </Text>
        ) : (
          <Text
            fontStyle="italic"
            color="gray.500"
            width="100%"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            textAlign="center"
          >
            Uncategorized
          </Text>
        )}
        <Text fontStyle="italic">({collaboratorTypeDisplay})</Text>
      </Flex>
    </Flex>
  );
};
