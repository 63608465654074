import React, { useImperativeHandle } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  VStack,
} from "@chakra-ui/react";
import { CellConfigProps } from "@/cells/cell.types";
import { ConfigType } from ".";
import { Controller, useForm } from "react-hook-form";
import * as Utilities from "@/utilities";
import * as DashboardUtilities from "@/utilities/dashboard";

export interface Props<S> extends CellConfigProps<S> {}

interface ConfigInputs {
  numSections: string;
}

const NUM_SECTIONS_INPUT_ID = "random-pie-chart-cell-config-range-min-input";

export const RandomPieChartCellExtraConfig = (props: Props<ConfigType>) => {
  const {
    submissionHandlerRef,
    status,
    explicitConfig,
    dashboardLevelConfig,
    globalLevelConfig,
  } = props;

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<ConfigInputs>({
    defaultValues: {
      ...Utilities.toStringValues(
        Utilities.nullishToUndefined(explicitConfig ?? {})
      ),
    },
  });

  const handleSubmitPromise = () => {
    return new Promise<ConfigInputs>((resolve, reject) => {
      handleSubmit(
        (data) => {
          resolve(data);
        },
        (errs) => {
          reject();
        }
      )();
    });
  };

  useImperativeHandle(submissionHandlerRef, () => ({
    submit: async () => {
      const formConfig = await handleSubmitPromise();
      reset();
      return {
        numSections: Utilities.tryParseInt(formConfig.numSections),
      };
    },
  }));

  const defaultNumSections =
    dashboardLevelConfig?.numSections ?? globalLevelConfig?.numSections;

  return (
    <VStack direction="column">
      <FormControl
        id={NUM_SECTIONS_INPUT_ID}
        isInvalid={!!errors.numSections}
        isDisabled={status === "submitting"}
      >
        <Controller
          control={control}
          rules={{
            required:
              Utilities.isNullish(dashboardLevelConfig?.numSections) &&
              Utilities.isNullish(globalLevelConfig?.numSections)
                ? "Number of sections required"
                : undefined,
          }}
          name="numSections"
          render={({ field: { ref, ...restField } }) => (
            <>
              <FormLabel>Number of Sections</FormLabel>
              <NumberInput min={0} {...restField}>
                <NumberInputField
                  ref={ref}
                  name={restField.name}
                  placeholder={
                    !Utilities.isNullish(defaultNumSections)
                      ? defaultNumSections + ""
                      : undefined
                  }
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormErrorMessage>
                {errors.numSections && errors.numSections.message}
              </FormErrorMessage>
            </>
          )}
        />
      </FormControl>
    </VStack>
  );
};
