import React, { FunctionComponent } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Select,
  Text,
} from "@chakra-ui/react";
import { CellConfigFieldInputProps } from "@/cell-config-fields/cell-config-fields.types";
import { BaseCurrency, BASE_CURRENCY_VALUES } from ".";
import { useUuid } from "@/hooks/utils/useUuid";
import { MdRemove } from "react-icons/md";
import * as Utilities from "@/utilities";

export interface Props extends CellConfigFieldInputProps<BaseCurrency> {}

function getPlaceholderText(
  dashboardLevelDefaultValue?: BaseCurrency,
  globalLevelDefaultValue?: BaseCurrency
): string {
  if (dashboardLevelDefaultValue)
    return `${dashboardLevelDefaultValue} (dashboard default)`;
  else if (globalLevelDefaultValue)
    return `${globalLevelDefaultValue} (global default)`;
  return "Select base currency";
}

export const BaseCurrencyConfigFieldInput: FunctionComponent<Props> = (
  props
) => {
  const {
    status,
    error,
    name,
    dashboardLevelDefaultValue,
    globalLevelDefaultValue,
    register,
    removeFieldConfig,
    context,
  } = props;

  const id = useUuid({ prefix: "base-currency-input-" });

  const placeholderText = getPlaceholderText(
    dashboardLevelDefaultValue,
    globalLevelDefaultValue
  );

  return (
    <FormControl
      id={id}
      isInvalid={!!error}
      isDisabled={status === "submitting"}
    >
      <FormLabel>
        <Text display="inline">Base Currency</Text>
      </FormLabel>

      <HStack>
        <Select
          placeholder={placeholderText}
          {...register(name, {
            required:
              Utilities.isNullish(dashboardLevelDefaultValue) &&
              Utilities.isNullish(globalLevelDefaultValue)
                ? "Base currency required"
                : undefined,
          })}
        >
          {BASE_CURRENCY_VALUES.map((bs, idx) => (
            <option key={`${bs}-${idx}`} value={bs}>
              {bs}
            </option>
          ))}
        </Select>

        {/* Conditionally show the remove button only when being 
            rendered in the dashboard settings modal */}
        {context === "dashboard-settings" && (
          <IconButton
            aria-label="Remove Poll Rate"
            icon={<MdRemove size="1.5rem" />}
            colorScheme="dashboard-primary-button"
            onClick={removeFieldConfig}
          />
        )}
      </HStack>

      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
};
