import React, { FunctionComponent, useMemo } from "react";
import {
  Button,
  Center,
  Spinner,
  Text,
  Flex,
  Wrap,
  WrapItem,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useAuth } from "@/hooks/auth/auth.hooks";
import { useGetAllDashboardsQuery } from "@/store/dashboards/dashboards.slice";
import { MdAdd, MdPerson } from "react-icons/md";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { DashboardCard } from "./components/DashboardCard";
import { CreateDashboardModal } from "@/components/modals/CreateDashboardModal/CreateDashboardModal";
import { Helmet } from "react-helmet-async";

export const HomeView: FunctionComponent = (props) => {
  const { currUser } = useAuth();

  const {
    isOpen: isCreateDashboardModalOpen,
    onOpen: onOpenCreateDashboardModal,
    onClose: onCloseCreateDashboardModal,
  } = useDisclosure();

  const {
    isLoading: isLoadingAllDashboards,
    isSuccess: isSuccessAllDashboards,
    isError: isErrorAllDashboards,
    data: allDashboardsData,
  } = useGetAllDashboardsQuery({});

  const [ownedDashboards, sharedDashboards] = useMemo(() => {
    const _ownedDashboards: Dashboard[] = [];
    const _sharedDashboards: Dashboard[] = [];

    if (allDashboardsData)
      for (let currDashboard of allDashboardsData) {
        if (currDashboard.owner === currUser.email)
          _ownedDashboards.push(currDashboard);
        else _sharedDashboards.push(currDashboard);
      }

    return [_ownedDashboards, _sharedDashboards];
  }, [allDashboardsData, currUser.email]);

  const isAdmin = currUser.permissionAction === "admin";

  const hasOwnedDashboards = ownedDashboards.length > 0;
  const hasSharedDashboards = sharedDashboards.length > 0;

  const isLoading = isLoadingAllDashboards;
  const isSuccess = isSuccessAllDashboards;
  const isError = isErrorAllDashboards;

  const handleCreateDashboard = () => {
    onOpenCreateDashboardModal();
  };

  if (isLoading)
    return (
      <>
        <Helmet>
          <title>Skew Dashboard</title>
        </Helmet>

        <Center height="100%">
          <Spinner size="lg"></Spinner>
        </Center>
      </>
    );

  if (isError)
    return (
      <>
        <Helmet>
          <title>Skew Dashboard</title>
        </Helmet>

        <Center height="100%">
          <Text>Unable to load home page.</Text>
        </Center>
      </>
    );

  if (isSuccess)
    return (
      <>
        <Helmet>
          <title>Skew Dashboard</title>
        </Helmet>

        <Flex
          padding="3"
          flexDirection="column"
          width="100%"
          height="100%"
          overflow="auto"
        >
          <CreateDashboardModal
            isOpen={isCreateDashboardModalOpen}
            onClose={onCloseCreateDashboardModal}
          />

          <Flex
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            {/* Welcome text */}
            <Flex flexGrow={1} flexShrink={1} flexWrap="wrap">
              <Text marginRight="1" fontSize="lg" flexGrow={0} flexShrink={0}>
                Welcome,
              </Text>
              <Text
                fontSize="lg"
                fontWeight="bold"
                flexGrow={1}
                flexShrink={1}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {currUser.email}
              </Text>
            </Flex>
            {/* Manage account access button */}
            {isAdmin && (
              <Button
                colorScheme="dashboard-primary-button"
                leftIcon={<MdPerson />}
                size="sm"
                as={Link}
                to="/permissions"
                flexGrow={0}
                flexShrink={0}
              >
                Manage Account Access
              </Button>
            )}
          </Flex>
          {/* Owned dashboard list */}
          <Flex flexDirection="column" marginTop="3">
            <Flex flexDirection="row" alignItems="center">
              <Text fontSize="lg" fontWeight="black" marginBottom="1">
                My Dashboards
              </Text>
              <Tooltip label="Create Dashboard">
                <IconButton
                  aria-label="Create Dashboard"
                  colorScheme="transparent"
                  size="sm"
                  marginLeft="1"
                  variant="ghost"
                  color="black"
                  onClick={handleCreateDashboard}
                  icon={<MdAdd size="1.2rem" />}
                />
              </Tooltip>
            </Flex>
            {hasOwnedDashboards ? (
              <Wrap>
                {ownedDashboards.map((dashboard, idx) => (
                  <WrapItem key={`dashboard-card-${dashboard.id}-${idx}`}>
                    <DashboardCard
                      dashboard={dashboard}
                      selfEmail={currUser.email}
                    />
                  </WrapItem>
                ))}
              </Wrap>
            ) : (
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Text fontStyle="italic">No Created Dashboards</Text>
              </Flex>
            )}
          </Flex>
          {/* Shared dashboard list */}
          <Flex flexDirection="column" marginTop="3">
            <Text fontSize="lg" fontWeight="black" marginBottom="1">
              Shared With Me
            </Text>
            {hasSharedDashboards ? (
              <Wrap>
                {sharedDashboards.map((dashboard, idx) => (
                  <WrapItem key={`dashboard-card-${dashboard.id}-${idx}`}>
                    <DashboardCard
                      dashboard={dashboard}
                      selfEmail={currUser.email}
                    />
                  </WrapItem>
                ))}
              </Wrap>
            ) : (
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Text fontStyle="italic">No Dashboards Shared With Me</Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </>
    );

  console.warn("Should not reach here.");
  return null;
};
