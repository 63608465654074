import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as Utilities from "@/utilities";
import { KEYS } from "@/keys";
import { PermissionGrant } from "@/models/permissions/permission-grant.model";
import * as PermissionGrantModel from "@/models/permissions/permission-grant.model";
import { PermissionAction } from "@/models/permissions/permission-action.model";

const LIST_KEY = "__LIST__";

interface GrantPermissionParams {
  email: string;
  newAction: PermissionAction;
}

export const permissionsApiSlice = createApi({
  reducerPath: "permissionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: KEYS.API_URL,
    prepareHeaders: Utilities.authenticatedPrepareHeaders,
  }),
  tagTypes: ["Permissions"],
  endpoints: (builder) => ({
    getAllPermissions: builder.query<PermissionGrant[], {}>({
      query: () => ({ url: "/permissions" }),
      transformResponse: (permissionsJson: unknown[]) =>
        permissionsJson.map((permissionJson) =>
          PermissionGrantModel.fromJson(permissionJson)
        ),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Permissions" as const,
                id: id!,
              })),
              { type: "Permissions", id: LIST_KEY },
            ]
          : [{ type: "Permissions", id: LIST_KEY }],
    }),
    getPermission: builder.query<PermissionGrant, string>({
      query: (email) => ({ url: `/permissions/${email}` }),
      transformResponse: (permissionJson) =>
        PermissionGrantModel.fromJson(permissionJson),
      providesTags: (result) =>
        result ? [{ type: "Permissions", id: result.id! }] : [],
    }),
    grantPermission: builder.mutation<PermissionGrant, GrantPermissionParams>({
      query: ({ email, newAction }) => ({
        url: `/permissions/${email}`,
        method: "PUT",
        body: {
          action: newAction,
        },
      }),
      transformResponse: (permissionJson) =>
        PermissionGrantModel.fromJson(permissionJson),
      invalidatesTags: (permission, __, _) => [
        { type: "Permissions", id: LIST_KEY },
      ],
    }),
    revokePermission: builder.mutation<PermissionGrant, string>({
      query: (email) => ({
        url: `/permissions/${email}`,
        method: "DELETE",
      }),
      transformResponse: (permissionJson) =>
        PermissionGrantModel.fromJson(permissionJson),
      invalidatesTags: (permission, __) => [
        { type: "Permissions", id: permission?.id! },
      ],
    }),
  }),
});

export const {
  useGetAllPermissionsQuery,
  useGetPermissionQuery,
  useGrantPermissionMutation,
  useRevokePermissionMutation,
} = permissionsApiSlice;
