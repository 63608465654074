import React, { FunctionComponent } from "react";
import { PartialSome, WithPrefix } from "@/utilities/types";
import {
  ModalBase,
  ModalBaseFooterButtons,
  Props as ModalBaseProps,
} from "../ModalBase/ModalBase";

export type UpdateButtonProps = WithPrefix<
  PartialSome<ModalBaseFooterButtons, "content">,
  "updateButton"
>;

export type ApplyButtonProps = WithPrefix<
  PartialSome<ModalBaseFooterButtons, "content">,
  "applyButton"
>;

export type CancelButtonProps = WithPrefix<
  PartialSome<ModalBaseFooterButtons, "content" | "onClick">,
  "cancelButton"
>;

export interface Props
  extends Omit<ModalBaseProps, "footerButtons">,
    UpdateButtonProps,
    ApplyButtonProps,
    CancelButtonProps {}

export const UpdateAndApplyModal: FunctionComponent<Props> = (props) => {
  const {
    isOpen,
    onClose,
    title,

    updateButtonContent = "Update",
    updateButtonOnClick,
    updateButtonColorScheme = "dashboard-primary-button",
    updateButtonOrientation,
    updateButtonSize,
    updateButtonStyleConfig,
    updateButtonVariant,

    applyButtonContent = "Apply",
    applyButtonOnClick,
    applyButtonColorScheme = "dashboard-primary-button",
    applyButtonOrientation,
    applyButtonSize,
    applyButtonStyleConfig,
    applyButtonVariant,

    cancelButtonContent = "Cancel",
    cancelButtonOnClick = () => true,
    cancelButtonColorScheme = "gray",
    cancelButtonOrientation,
    cancelButtonSize,
    cancelButtonStyleConfig,
    cancelButtonVariant,

    children,
    ...rest
  } = props;

  const footerButtons: ModalBaseFooterButtons[] = [
    {
      onClick: updateButtonOnClick,
      content: updateButtonContent,
      colorScheme: updateButtonColorScheme,
      variant: updateButtonVariant,
      orientation: updateButtonOrientation,
      size: updateButtonSize,
      styleConfig: updateButtonStyleConfig,
      doCloseOnClick: true,
    },
    {
      onClick: applyButtonOnClick,
      content: applyButtonContent,
      colorScheme: applyButtonColorScheme,
      variant: applyButtonVariant,
      orientation: applyButtonOrientation,
      size: applyButtonSize,
      styleConfig: applyButtonStyleConfig,
      doCloseOnClick: false,
    },
    {
      onClick: cancelButtonOnClick,
      content: cancelButtonContent,
      colorScheme: cancelButtonColorScheme,
      variant: cancelButtonVariant,
      orientation: cancelButtonOrientation,
      size: cancelButtonSize,
      styleConfig: cancelButtonStyleConfig,
      doCloseOnClick: true,
    },
  ];

  return (
    <ModalBase
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      footerButtons={footerButtons}
    >
      {children}
    </ModalBase>
  );
};
