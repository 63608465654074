import { CellRegistryItem } from "@/cells/cell.types";
import { RandomTimeseriesChartCell } from "./RandomTimeseriesChartCell";
import { configSchema, ConfigType } from "./randomTimeseriesChartCell.models";
import { randomTimeseriesChartCellApiSlice } from "./randomTimeseriesChartCell.slice";
import * as PollRate from "@/cell-config-fields/registry/poll_rate_config_field";
// export { RandomTimeseriesChartCell as Cell } from "./RandomTimeseriesChartCell";
export type { ConfigType } from "./randomTimeseriesChartCell.models";
// export { configSchema } from "./randomTimeseriesChartCell.models";

export const identifier = "random_timeseries_chart_cell";

export const storeReducer = randomTimeseriesChartCellApiSlice.reducer;
export const storeReducerPath = randomTimeseriesChartCellApiSlice.reducerPath;
export const storeMiddleware = randomTimeseriesChartCellApiSlice.middleware;

export const cellRegistryItem: CellRegistryItem<ConfigType, any> = {
  CellComponent: RandomTimeseriesChartCell,
  configSchema: configSchema,
  popupWindowSizeModifiers: {
    width: 3/4,
    height: 1/2,
  },
  commonConfigFields: [
    PollRate.configField,
  ],
  globalLevelDefaultConfigs: {
    pollRate: 15 * 1000,
  },
};