import * as yup from "yup";
import { CellConfigFieldsRegistryItem } from "../../cell-config-fields.types";
import { PollRateConfigFieldInput } from "./PollRateConfigFieldInput";

export const configField: CellConfigFieldsRegistryItem<number> = {
  identifier: "poll_rate",
  displayName: "Poll Rate",
  dashboardDefaultValue: 1000 * 60, // 1 minute
  valueSchema: yup.number().positive().required(),
  InputComponent: PollRateConfigFieldInput,
};