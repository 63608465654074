import React, { Suspense, lazy, VoidFunctionComponent } from "react";
import { Switch, Route } from "react-router-dom";
import { HomeView } from "@/views/home/HomeView";
import { DashboardView } from "@/views/dashboard/DashboardView";
import { LoginView } from "@/views/login/LoginView";
import { Box } from "@chakra-ui/react";
import { PrivateRoute } from "./routes/PrivateRoute";
import { UnauthenticatedRoute } from "./routes/UnauthenticatedRoute";
import { UnauthorizedRoute } from "./routes/UnauthorizedRoute";
import { AppLayout } from "@/layouts/AppLayout";
import { PermissionsView } from "@/views/permissions/PermissionsView";

const NotFoundView = lazy(
  () =>
    import(
      /* webpackChunkName: "not-found-view" */ "@/views/not-found/NotFoundView"
    )
);

const GetAccessView = lazy(
  () =>
    import(
      /* webpackChunkName: "get-access-view" */ "@/views/get-access/GetAccessView"
    )
);

// Reference for nested Routes and multiple route matching:
// - https://stackoverflow.com/a/47282032
// - https://stackoverflow.com/a/53074599

const RouteView: VoidFunctionComponent = (props) => {
  return (
    <Box id="RouteView" height="100%">
      <Suspense fallback={null}>
        <Switch>
          <PrivateRoute
            exact
            path={["/", "/dashboard/:id", "/permissions"]}
          >
            <AppLayout>
              <Route exact path="/" component={HomeView} />
              <Route exact path="/dashboard/:id" component={DashboardView} />
              <PrivateRoute
                exact
                path="/permissions"
                permissionAction="admin"
                unauthorizedAction="component"
                component={PermissionsView}
              />
            </AppLayout>
          </PrivateRoute>
          <UnauthenticatedRoute
            exact
            path="/login"
            authenticatedPathname="/"
            component={LoginView}
          />
          <UnauthorizedRoute
            exact
            path="/get-access"
            component={GetAccessView}
          />
          <Route component={NotFoundView} />
        </Switch>
      </Suspense>
    </Box>
  );
};

export default RouteView;
