import { useToast } from "@chakra-ui/react";

interface ToastConfig {
  title?: string;
  duration?: number;
}

type ToastFunc = (description: string, config?: ToastConfig) => void;

export interface UseAppToastReturn {
  errorToast: ToastFunc;
  infoToast: ToastFunc;
  successToast: ToastFunc;
  warningToast: ToastFunc;
}

const DEFAULT_DURATION = 3000;

export function useAppToasts(): UseAppToastReturn {
  const toast = useToast();

  const errorToast: ToastFunc = (description, config = {}) => {
    const defaultConfig: ToastConfig = {
      duration: DEFAULT_DURATION,
      title: "Error",
    };
    config = { ...defaultConfig, ...config };

    toast({
      title: config.title,
      description,
      status: "error",
      duration: config.duration,
      isClosable: true,
    });
  };

  const infoToast: ToastFunc = (description, config = {}) => {
    const defaultConfig: ToastConfig = {
      duration: DEFAULT_DURATION,
      title: "Info",
    };
    config = { ...defaultConfig, ...config };

    toast({
      title: config.title,
      description,
      status: "info",
      duration: config.duration,
      isClosable: true,
    });
  };

  const successToast: ToastFunc = (description, config = {}) => {
    const defaultConfig: ToastConfig = {
      duration: DEFAULT_DURATION,
      title: "Success",
    };
    config = { ...defaultConfig, ...config };

    toast({
      title: config.title,
      description,
      status: "success",
      duration: config.duration,
      isClosable: true,
    });
  };

  const warningToast: ToastFunc = (description, config = {}) => {
    const defaultConfig: ToastConfig = {
      duration: DEFAULT_DURATION,
      title: "Warning",
    };
    config = { ...defaultConfig, ...config };

    toast({
      title: config.title,
      description,
      status: "warning",
      duration: config.duration,
      isClosable: true,
    });
  };

  return {
    errorToast,
    infoToast,
    successToast,
    warningToast,
  };
}
