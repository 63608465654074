import { KEYS } from "@/keys";
import { RootState } from "@/store/store";
import axios from "axios";
import Color from "color";
import {
  Id,
  IsNullable,
  IsNullish,
  IsNullishExclusive,
  IsUndefinable,
  NonNullish,
  Nullish,
  PartialRecursive,
  ReplaceTypes,
  ReplaceTypesRecursive,
} from "./types";

export * from "./types";
export * from "./dashboard";

/**
 * Returns a new Axios instance for the API.
 */
export function getAxiosInstance() {
  return axios.create({
    baseURL: KEYS.API_URL,
  });
}

// Source: https://github.com/then/is-promise/blob/master/index.js
export function isPromise(obj: any) {
  return (
    !!obj &&
    (typeof obj === "object" || typeof obj === "function") &&
    typeof obj.then === "function"
  );
}

/**
 * Returns a promise that delays its resolution by `timeout` milliseconds.
 */
export function delayPromise(timeout: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
}

/**
 * Trims the trailing character string from the given string.
 */
export function trimTrailingChars(s: string, charToTrim: string) {
  // Source: https://stackoverflow.com/a/23266144
  var regExp = new RegExp(charToTrim + "+$");
  var result = s.replace(regExp, "");

  return result;
}

export const isArray = function (a: any) {
  return Array.isArray(a);
};

export const isObject = function (o: any) {
  return o === Object(o) && !isArray(o) && typeof o !== "function";
};

// Source: https://stackoverflow.com/a/54246501
/**
 * Converts the given string from camelCase to snake_case.
 */
export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

// Source: https://matthiashager.com/converting-snake-case-to-camel-case-object-keys-with-javascript
/**
 * Converts the given string from snake_case to camelCase.
 * If `keepLeadingUnderscore` is set then the leading underscore will not be
 * converted to camel case (e.x. "_foo_bar" will not become "FooBar").
 */
export const snakeToCamelCase = (s: string, keepLeadingUnderscore = true) => {
  return s.replace(/([_][a-z])/gi, (_, $1, idx) => {
    // Do not convert leading match at the start of the string (i.e. keep
    // leading underscore intact)
    return idx === 0 && keepLeadingUnderscore
      ? $1
      : $1.toUpperCase().replace("_", "");
  });
};

// Source: https://matthiashager.com/converting-snake-case-to-camel-case-object-keys-with-javascript
/**
 * Recursively converts the object's keys to use snake_case.
 */
export function camelToSnakeCaseObject(o: { [key: string]: any }): {
  [key: string]: any;
} {
  if (isObject(o)) {
    const n: { [key: string]: any } = {};

    Object.keys(o).forEach((k: string) => {
      n[camelToSnakeCase(k)] = camelToSnakeCaseObject(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i: any) => {
      return camelToSnakeCaseObject(i);
    });
  }

  return o;
}

/**
 * Recursively converts the object's keys to use camelCase.
 */
export function snakeToCamelCaseObject(o: { [key: string]: any }): {
  [key: string]: any;
} {
  if (isObject(o)) {
    const n: { [key: string]: any } = {};

    Object.keys(o).forEach((k: string) => {
      n[snakeToCamelCase(k)] = snakeToCamelCaseObject(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i: any) => {
      return snakeToCamelCaseObject(i);
    });
  }

  return o;
}

/**
 * A helper to setup the authenticated headers for Redux Toolkit's
 * prepareHeaders field.
 */
export const authenticatedPrepareHeaders = async (
  headers: Headers,
  api: { getState: () => unknown }
) => {
  const { getState } = api;

  const state = getState() as RootState;

  const token = state.auth.token;

  if (token) headers.set("Authorization", `Bearer ${token}`);

  return headers;
};

// Source: https://www.digitalocean.com/community/tutorials/js-capitalizing-strings
/**
 * Capitalize all words in a string.
 */
export const capitalizeWords = (s: string): string => {
  return s.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
};

/**
 * Returns a promise that resolves after `ms` milliseconds.
 */
export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

// Source: https://stackoverflow.com/a/7616484
/**
 * Implementation of Java's hashCode method for strings.
 */
export const stringToHash = (s: string): number => {
  let hash = 0,
    i,
    chr;
  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

// Inspiration: https://martin.ankerl.com/2009/12/09/how-to-create-random-colors-programmatically/
/**
 * Takes a string an returns a unique color corresponding to it.
 * Internally, the string is hashed and that hash value is used to modulate the
 * hue value, so each string should have its own (relatively) unique color.
 * The saturation and lightness of the color are constant.
 */
export const stringToColor = (
  s: string,
  saturation: number = 0.5,
  lightness: number = 0.75
) => {
  // Source: https://gist.github.com/0x263b/2bdd90886c2036a1ad5bcf06d6e6fb37
  const range = (hash: number, min: number, max: number) => {
    const diff = max - min;
    const x = ((hash % diff) + diff) % diff;
    return x + min;
  };

  const hash = stringToHash(s);
  const hue = range(hash, 0, 360);
  const color = Color(`hsl(${hue}, ${saturation * 100}%, ${lightness * 100}%)`);
  return color;
};

/**
 * Performs set union between sets a and b.
 */
export const setUnion = <T = any>(a: Set<T>, b: Set<T>): Set<T> => {
  // Source: https://2ality.com/2015/01/es6-set-operations.html
  return new Set<T>([...a, ...b]);
};

/**
 * Performs set intersection between sets a and b.
 */
export const setIntersection = <T = any>(a: Set<T>, b: Set<T>): Set<T> => {
  // Source: https://2ality.com/2015/01/es6-set-operations.html
  return new Set<T>([...a].filter((x) => b.has(x)));
};

/**
 * Performs set intersection between sets a and b (i.e. a \ b).
 */
export const setDifference = <T = any>(a: Set<T>, b: Set<T>): Set<T> => {
  // Source: https://2ality.com/2015/01/es6-set-operations.html
  return new Set([...a].filter((x) => !b.has(x)));
};

/**
 * Returns a new object with any null, undefined or empty string entries
 * removed. If recursive is set, then this process is applied recursively to the
 * object.
 */
export function removeEmptyEntries<T = Object>(
  obj: T,
  recursive?: false
): Partial<T>;
export function removeEmptyEntries<T = Object>(
  obj: T,
  recursive: true
): PartialRecursive<T>;
export function removeEmptyEntries<T = Object>(
  obj: T,
  recursive = false
): Partial<T> {
  // Source: https://stackoverflow.com/a/38340730
  if (recursive) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v !== null && v !== undefined && v !== "")
        .map(([k, v]) => [
          k,
          v === Object(v) ? removeEmptyEntries(v, recursive) : v,
        ])
    ) as Partial<T>;
  } else {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([_, v]) => v !== null && v !== undefined && v !== ""
      )
    ) as Partial<T>;
  }
}

/**
 * Attempts to call parseInt. If it fails, then the default value is returned
 * (by default this is `undefined`).
 */
export function tryParseInt<F extends any = undefined>(
  string: string,
  radix?: number,
  failedVal?: F
): number | F | undefined {
  const intVal = parseInt(string, radix);
  return isNaN(intVal) ? failedVal : intVal;
}

/**
 * Returns if the given value is nullish (`null` or `undefined`).
 */
export function isNullish(val: any) {
  return val === null || val === undefined;
}

type NullishToUndefined<T extends Record<string, any>> = Id<
  {
    [K in keyof T]: IsNullable<T[K]> extends true ? Exclude<T[K], null> : T[K];
  }
>;

/**
 * Converts all nullish values (`null` or `undefined`) in the object to
 * `undefined`.
 */
export function nullishToUndefined<T extends Record<string, any>>(
  obj: T
): NullishToUndefined<T> {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => {
      if (v === null) return [k, undefined];
      else return [k, v];
    })
  ) as NullishToUndefined<T>;
}

type NullishToNull<T extends Record<string, any>> = Id<
  {
    [K in keyof T]: IsNullable<T[K]> extends true
      ? Exclude<T[K], undefined>
      : T[K];
  }
>;

/**
 * Converts all nullish values (`null` or `undefined`) in the object to `null`.
 */
export function nullishToNull<T extends Record<string, any>>(
  obj: T
): NullishToNull<T> {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => {
      if (v === null) return [k, undefined];
      else return [k, v];
    })
  ) as NullishToNull<T>;
}

type ToStringValuesEmptyStringNullish<T extends Record<string, any>> = Id<
  {
    [K in keyof T]: string;
  }
>;
type ToStringValuesKeepNullish<T extends Record<string, any>> = Id<
  {
    [K in keyof T]: IsNullishExclusive<T[K]> extends true
      ? string | Nullish
      : IsNullable<T[K]> extends true
      ? string | null
      : IsUndefinable<T[K]> extends true
      ? string | undefined
      : string;
  }
>;

/**
 * Converts every key of the given object to its string representation.
 * If `emptyStringNullish` is `true` then `null` or `undefined` values are
 * replaced with empty strings, `""`.
 */
export function toStringValues<T extends Record<string, any>>(
  obj: T,
  emptyStringNullish?: true
): ToStringValuesEmptyStringNullish<T>;
export function toStringValues<T extends Record<string, any>>(
  obj: T,
  emptyStringNullish: false
): ToStringValuesKeepNullish<T>;
export function toStringValues<T extends Record<string, any>>(
  obj: T,
  emptyStringNullish = true
): ToStringValuesEmptyStringNullish<T> {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => {
      if (!emptyStringNullish && isNullish(v)) return [k, v];
      if (v.toString !== undefined) return [k, v.toString()];
      if (isNullish(v)) return [k, ""];
      else return [k, v];
    })
  ) as ToStringValuesEmptyStringNullish<T>;
}
