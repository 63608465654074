import { CellConfigFieldsRegistryItem } from "@/cell-config-fields/cell-config-fields.types";
import { ModalStatus } from "@/components/modals/ModalBase/ModalBase";
import { Nullish, YupSchema } from "@/utilities";
import { Ref } from "react";
import * as yup from "yup";

export type AuthenticatedCellConfigType = YupSchema<
  typeof authenticatedCellSchema
>;

export const authenticatedCellSchema = yup.object({
  // TODO: make camelCase
  auth_token: yup.string().optional(),
});

export interface CellProps<C, A = null> {
  explicitConfig: Partial<C> | Nullish;
  dashboardLevelConfig: Partial<C> | Nullish;
  globalLevelConfig: Partial<C> | Nullish;
  additionalData: A | null;
}

export interface CellConfigSubmissionHandler<C extends any> {
  submit?: () => Promise<Partial<C> | null> | (Partial<C> | null);
}

export interface CellConfigProps<C, A = null> {
  explicitConfig: Partial<C> | Nullish;
  dashboardLevelConfig: Partial<C> | Nullish;
  globalLevelConfig: Partial<C> | Nullish;
  additionalData: A | null;
  status: ModalStatus;
  submissionHandlerRef: Ref<CellConfigSubmissionHandler<C>>;
}

export type CellComponent<
  C extends any,
  A extends any
> = React.FunctionComponent<CellProps<C, A>>;

export type CellExtraConfigComponent<
  C extends any,
  A extends any = null
> = React.FunctionComponent<CellConfigProps<C, A>>;

export type CellConfigBaseType = Record<string, any>;
export type CellAdditionalDataType = Record<string, any>;

export type CellConfigSchema<
  C extends CellConfigBaseType = CellConfigBaseType
> = yup.ObjectSchema<any>; // TODO: see if we can enforce the typings to C
export type CellAdditionalDataSchema<
  A extends CellAdditionalDataType = CellAdditionalDataType
> = yup.ObjectSchema<any>; // TODO: see if we can enforce the typings to A

export type CellAdvancedDisplayNameFunc<C extends any> = (
  explicitConfig: Partial<C> | Nullish,
  dashboardLevelConfig: Partial<C> | Nullish,
  globalLevelConfig: Partial<C> | Nullish,
) => string;

export interface CellPopupWindowSizeModifiers {
  width?: number;
  height?: number;
}

export const CELL_POPUP_WINDOW_SIZE_MODIFIER_DEFAULTS: Required<CellPopupWindowSizeModifiers> =
  {
    width: 1 / 2, // 1/2 (50%) of the screen width
    height: 1 / 2, // 1/2 (50%) of the screen height
  };

export interface CellRegistryItem<
  C extends CellConfigBaseType,
  A extends CellAdditionalDataType
> {
  CellComponent: CellComponent<C, A>;
  CellExtraConfigComponent?: CellExtraConfigComponent<C, A>;
  advancedDisplayName?: CellAdvancedDisplayNameFunc<C>;

  configSchema?: CellConfigSchema<C>;
  additionalDataSchema?: CellAdditionalDataSchema<A>;

  // Global level config for this cell
  globalLevelDefaultConfigs?: Partial<C>;

  // How much of the total screen size the cell popup window's
  // size be up initially
  popupWindowSizeModifiers?: CellPopupWindowSizeModifiers;

  // The comment config fields that the cell will use, which can be configured
  // in the dashboard settings
  commonConfigFields: CellConfigFieldsRegistryItem<any>[];
}

export interface CellRegistry {
  [cellIdentifier: string]: CellRegistryItem<any, any> | undefined;
}
