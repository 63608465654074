
/**
 * Attempts to parse the given value string as an int. If it fails, `defaultVal`
 * is used.
 */
export function useParseInt(valueStr: string, defaultVal?: number): number;
export function useParseInt(
  valueStr: string,
  defaultVal: number | null = 0
): number | null {
  const val = parseInt(valueStr);;
  return isNaN(val) ? defaultVal : val;
}
