import React, { FunctionComponent } from "react";
import { VStack } from "@chakra-ui/react";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { CollaboratorListItem } from "./CollaboratorListItem";
import { useAuth } from "@/hooks/auth/auth.hooks";
import { DashboardCollaboratorType } from "@/models/dashboard/dashboard-collaborator-type.model";

export interface Props {
  dashboard: Dashboard;
  onCollaboratorTypeChange?: (
    collaboratorIdx: number,
    newType: DashboardCollaboratorType
  ) => unknown;
  onCollaboratorRemove?: (collaboratorIdx: number) => unknown;
}

export const CollaboratorList: FunctionComponent<Props> = (props) => {
  const { dashboard, onCollaboratorTypeChange, onCollaboratorRemove } = props;

  const { currUser } = useAuth();

  const handleCollaboratorTypeChange = (
    collaboratorIdx: number,
    newType: DashboardCollaboratorType
  ) => {
    if (onCollaboratorTypeChange)
      onCollaboratorTypeChange(collaboratorIdx, newType);
  };

  const handleCollaboratorRemove = (collaboratorIdx: number) => {
    if (onCollaboratorRemove) onCollaboratorRemove(collaboratorIdx);
  };

  return (
    <VStack spacing="2" width="100%">
      <CollaboratorListItem
        email={dashboard.owner}
        type="owner"
        isSelf={currUser.email === dashboard.owner}
      />

      {dashboard.collaborators.map((collaborator, idx) => (
        <CollaboratorListItem
          key={`${collaborator.email}-${idx}`}
          email={collaborator.email}
          type={collaborator.type}
          isSelf={currUser.email === collaborator.email}
          onCollaboratorTypeChange={(newType) =>
            handleCollaboratorTypeChange(idx, newType)
          }
          onRemove={() => handleCollaboratorRemove(idx)}
        />
      ))}
    </VStack>
  );
};
