import React, { FunctionComponent, useState } from "react";
import {
  Flex,
  Text,
  IconButton,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
} from "@chakra-ui/react";
import {
  MdFullscreen,
  MdFullscreenExit,
  MdMoreHoriz,
  MdSettings,
  MdShare,
} from "react-icons/md";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { FaCopy, FaPlus, FaTrash } from "react-icons/fa";
import useFullscreen from "@/hooks/utils/useFullscreen";
import { DashboardBreadcrumb } from "../DashboardBreadcrumb/DashboardBreadcrumb";
import { CreateDashboardModal } from "@/components/modals/CreateDashboardModal/CreateDashboardModal";
import { DeleteDashboardModal } from "@/components/modals/DeleteDashboardModal/DeleteDashboardModal";
import { ShareDashboardModal } from "@/components/modals/ShareDashboardModal/ShareDashboardModal";
import { useCollaboratorHasPerms } from "@/hooks/dashboard/useCollaboratorHasPerms";
import { CloneDashboardModal } from "@/components/modals/CloneDashboardModal/CloneDashboardModal";
import { DashboardSettingsModal } from "@/components/modals/DashboardSettingsModal/DashboardSettingsModal";

interface Props {
  dashboard: Dashboard;
}

export const DashboardControls: FunctionComponent<Props> = (props) => {
  const { dashboard } = props;

  const {
    isOpen: isCreateDashboardModalOpen,
    onOpen: onOpenCreateDashboardModal,
    onClose: onCloseCreateDashboardModal,
  } = useDisclosure();

  const {
    isOpen: isCloneDashboardModalOpen,
    onOpen: onOpenCloneDashboardModal,
    onClose: onCloseCloneDashboardModal,
  } = useDisclosure();

  const {
    isOpen: isDeleteDashboardModalOpen,
    onOpen: onOpenDeleteDashboardModal,
    onClose: onCloseDeleteDashboardModal,
  } = useDisclosure();

  const {
    isOpen: isShareDashboardModalOpen,
    onOpen: onOpenShareDashboardModal,
    onClose: onCloseShareDashboardModal,
  } = useDisclosure();

  const {
    isOpen: isDashboardSettingsModalOpen,
    onOpen: onOpenDashboardSettingsModal,
    onClose: onCloseDashboardSettingsModal,
  } = useDisclosure();

  // Current user can only share if they are a collaborator of type co-owner
  // or higher
  const hasCoOwnerPerms = useCollaboratorHasPerms(dashboard, "co_owner");

  // Current user can only delete the dashboard if they are the owner of it
  const hasOwnerPerms = useCollaboratorHasPerms(dashboard, "owner");

  // Fullscreen for the whole page
  const [isFullscreen, setFullscreen] = useState(false);
  useFullscreen(document.documentElement, isFullscreen, {
    onClose: () => setFullscreen(false),
  });

  const handleFullscreenToggle = () => {
    setFullscreen(!isFullscreen);
  };

  const handleDashboardSettings = () => {
    onOpenDashboardSettingsModal();
  };

  const handleShareDashboard = () => {
    onOpenShareDashboardModal();
  };

  const handleNewDashboard = () => {
    onOpenCreateDashboardModal();
  };

  const handleCloneDashboard = () => {
    onOpenCloneDashboardModal();
  };

  const handleDeleteDashboard = () => {
    onOpenDeleteDashboardModal();
  };

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor="app-blue.tertiary"
      color="white"
      padding="2"
      overflowX="auto"
      overflowY="hidden"
      className="inline-scrollbar"
    >
      <DashboardBreadcrumb dashboard={dashboard} />

      <CreateDashboardModal
        isOpen={isCreateDashboardModalOpen}
        onClose={onCloseCreateDashboardModal}
      />

      <CloneDashboardModal 
        isOpen={isCloneDashboardModalOpen}
        onClose={onCloseCloneDashboardModal}
        dashboard={dashboard}
      />

      <DeleteDashboardModal
        isOpen={isDeleteDashboardModalOpen}
        onClose={onCloseDeleteDashboardModal}
        dashboard={dashboard}
        redirectToHomePage
      />

      <ShareDashboardModal
        isOpen={isShareDashboardModalOpen}
        onClose={onCloseShareDashboardModal}
        dashboard={dashboard}
      />

      <DashboardSettingsModal 
        isOpen={isDashboardSettingsModalOpen}
        onClose={onCloseDashboardSettingsModal}
        dashboard={dashboard} 
      />

      <Flex flexDirection="row">

        {/* Share button */}
        {hasCoOwnerPerms && (
          <Tooltip label="Share Dashboard">
            <IconButton
              aria-label="Share Dashboard"
              colorScheme="transparent"
              size="sm"
              variant="ghost"
              color="white"
              onClick={handleShareDashboard}
              icon={<MdShare size="1.5em" />}
            />
          </Tooltip>
        )}

        {/* Settings button */}
        {hasCoOwnerPerms && (
          <Tooltip label="Dashboard Settings">
            <IconButton
              aria-label="Dashboard Settings"
              colorScheme="transparent"
              size="sm"
              variant="ghost"
              color="white"
              onClick={handleDashboardSettings}
              icon={<MdSettings size="1.5em" />}
            />
          </Tooltip>
        )}

        {/* Fullscreen button */}
        <Tooltip label="Fullscreen Dashboard">
          <IconButton
            aria-label="Fullscreen Dashboard"
            colorScheme="transparent"
            size="sm"
            variant="ghost"
            color="white"
            onClick={handleFullscreenToggle}
            icon={
              isFullscreen ? (
                <MdFullscreenExit size="1.5em" />
              ) : (
                <MdFullscreen size="1.5em" />
              )
            }
          />
        </Tooltip>

        {/* More options */}
        <Menu closeOnSelect={false}>
          <MenuButton
            as={IconButton}
            aria-label="More Options"
            colorScheme="transparent"
            size="sm"
            variant="ghost"
            color="white"
            icon={<MdMoreHoriz size="1.5em" />}
          ></MenuButton>
          <MenuList
            bgColor="dashboard-selection-dropdown.primary"
            border="none"
          >
            {/* New dashboard button */}
            <MenuItem
              _hover={{ bgColor: "dashboard-selection-dropdown.secondary" }}
              _active={{ bgColor: "dashboard-selection-dropdown.secondary" }}
              _focus={{ bgColor: "dashboard-selection-dropdown.secondary" }}
              icon={<FaPlus size="1.5em" />}
              onClick={handleNewDashboard}
              closeOnSelect={true}
            >
              New Dashboard
            </MenuItem>

            {/* Clone dashboard button */}
            <MenuItem
              _hover={{ bgColor: "dashboard-selection-dropdown.secondary" }}
              _active={{ bgColor: "dashboard-selection-dropdown.secondary" }}
              _focus={{ bgColor: "dashboard-selection-dropdown.secondary" }}
              icon={<FaCopy size="1.5em" />}
              onClick={handleCloneDashboard}
            >
              Clone Dashboard
            </MenuItem>

            {/* Delete dashboard */}
            {hasOwnerPerms && (
              <MenuItem
                _hover={{ bgColor: "dashboard-selection-dropdown.secondary" }}
                _active={{ bgColor: "dashboard-selection-dropdown.secondary" }}
                _focus={{ bgColor: "dashboard-selection-dropdown.secondary" }}
                icon={<FaTrash size="1.5em" />}
                onClick={handleDeleteDashboard}
              >
                <Text color="red.200">Delete Dashboard</Text>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};
