import { PermissionGrant } from "@/models/permissions/permission-grant.model";
import * as PermissionGrantModel from "@/models/permissions/permission-grant.model";
import { AxiosInstance } from "axios";
import * as utilities from "@/utilities";

const defaultClient = utilities.getAxiosInstance();

async function getPermissionGrants(
  token: string,
  client?: AxiosInstance
): Promise<PermissionGrant[]> {
  const res = await (client ?? defaultClient).get<any[]>("/permissions", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const permissions: PermissionGrant[] = res.data.map((permissionData) =>
    PermissionGrantModel.fromJson(permissionData)
  );
  return permissions;
}

async function getPermissionGrant(
  email: string,
  token: string,
  client?: AxiosInstance
): Promise<PermissionGrant> {
  const res = await (client ?? defaultClient).get(`/permissions/${email}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const permission = PermissionGrantModel.fromJson(res.data);
  return permission;
}

async function grantPermission(
  permission: PermissionGrant,
  token: string,
  client?: AxiosInstance
): Promise<PermissionGrant> {
  const res = await (client ?? defaultClient).put(
    `/permissions/${permission.email}`,
    PermissionGrantModel.toJson(permission, false),
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const createdPermission = PermissionGrantModel.fromJson(res.data);
  return createdPermission;
}

async function revokePermission(
  permission: PermissionGrant,
  token: string,
  client?: AxiosInstance
): Promise<PermissionGrant> {
  const res = await (client ?? defaultClient).delete(
    `/permissions/${permission.email}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const deletedPermission = PermissionGrantModel.fromJson(res.data);
  return deletedPermission;
}

export const permissionsApi = {
  getPermissionGrants,
  getPermissionGrant,
  grantPermission,
  revokePermission,
};
