import React, { FunctionComponent, useRef } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import {
  ConfirmationModal,
  Props as ConfirmationModalProps,
} from "../ConfirmationModal/ConfirmationModal";
import { useForm } from "react-hook-form";
import { useCreateDashboardMutation } from "@/store/dashboards/dashboards.slice";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { useAuth } from "@/hooks/auth/auth.hooks";
import { useAppNavigator } from "@/hooks/navigation/use-app-navigator";
import { useAppToasts } from "@/hooks/toasts/useAppToasts";

export interface Props
  extends Omit<
    ConfirmationModalProps,
    "renderContent" | "confirmationButtonOnClick" | "initialFocusRef"
  > {}

const NAME_INPUT_ID = "create-dashboard-modal-name-input";
const CATEGORY_INPUT_ID = "create-dashboard-modal-category-input";

interface CreateFormInputs {
  name: string;
  category: string;
}

export const CreateDashboardModal: FunctionComponent<Props> = (props) => {
  const { ...rest } = props;

  const [createDashboard] = useCreateDashboardMutation();

  const { currUser } = useAuth();

  const { errorToast } = useAppToasts();
  const { toDashboard } = useAppNavigator();

  // Weird quirk with the typings
  // https://stackoverflow.com/a/58033283
  const initRef = useRef<HTMLInputElement | null>(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<CreateFormInputs>();

  const handleSubmitPromise = () => {
    return new Promise<CreateFormInputs>((resolve, reject) => {
      handleSubmit(
        (data) => {
          resolve(data);
        },
        (errs) => {
          reject();
        }
      )();
    });
  };

  const confirmationButtonOnClickWrapped = async () => {
    try {
      const data = await handleSubmitPromise();

      const newDashboard: Dashboard = {
        id: null,
        name: data.name,
        category: data.category !== "" ? data.category : null,
        owner: currUser.email,
        collaborators: [],
        cells: [],
        dashboardLevelDefaultConfigs: {},
      };

      try {
        const createdDashboard = await createDashboard(newDashboard).unwrap();

        // Reset the form since the modal does not unmount on close
        reset();

        // This set timeout is to fix the state update on unmounted error
        setTimeout(() => {
          // Go to the newly created dashboard
          toDashboard(createdDashboard.id!);
        }, 50);

        return true;
      } catch (err) {
        errorToast("Unable to create new dashboard.");
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  const { ref: registerNameInputRef, ...registerNameInputRest } = register(
    "name",
    {
      required: "Dashboard name is required",
    }
  );

  return (
    <ConfirmationModal
      isCentered
      confirmationButtonContent="Create"
      title="Create Dashboard"
      {...rest}
      confirmationButtonOnClick={confirmationButtonOnClickWrapped}
      initialFocusRef={initRef}
      renderContent={(status) => (
        <VStack spacing={2}>
          <FormControl id={NAME_INPUT_ID} isInvalid={!!errors.name}>
            <FormLabel>Name</FormLabel>
            <Input
              placeholder="Dashboard Name"
              disabled={status === "submitting"}
              {...registerNameInputRest}
              // How to share the ref: https://react-hook-form.com/faqs#Howtosharerefusage
              ref={(e) => {
                registerNameInputRef(e);
                initRef.current = e;
              }}
            />
            <FormErrorMessage>
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl id={CATEGORY_INPUT_ID} isInvalid={!!errors.category}>
            <FormLabel>Category</FormLabel>
            <Input
              placeholder="(Uncategorized)"
              _placeholder={{
                color: "gray.400",
                fontStyle: "italic",
              }}
              disabled={status === "submitting"}
              {...register("category")}
            />
            <FormErrorMessage>
              {errors.category && errors.category.message}
            </FormErrorMessage>
          </FormControl>
        </VStack>
      )}
    />
  );
};
