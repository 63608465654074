import * as yup from "yup";
import { CellConfigFieldsRegistryItem } from "../../cell-config-fields.types";
import { BaseCurrencyConfigFieldInput } from "./BaseCurrencyConfigFieldInput";

export type BaseCurrency = typeof BASE_CURRENCY_VALUES[number];
export const BASE_CURRENCY_VALUES = ["BTC", "ETH"] as const;

export const configField: CellConfigFieldsRegistryItem<BaseCurrency> = {
  identifier: "base_currency",
  displayName: "Base Currency",
  dashboardDefaultValue: "BTC",
  valueSchema: yup
    .mixed<BaseCurrency>()
    .oneOf([...BASE_CURRENCY_VALUES])
    .required(),
  InputComponent: BaseCurrencyConfigFieldInput,
};
