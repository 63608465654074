import React, { FunctionComponent, useEffect } from "react";
import { BaseProps } from "@/types";
import {
  localDeleteDashboard,
  useGetAllDashboardsQuery,
  useGetDashboardQuery,
} from "@/store/dashboards/dashboards.slice";
import { useDashboardParams } from "@/hooks/router/router.hooks";
import { Center, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { DashboardGrid } from "./components/DashboardGrid/DashboardGird";
import { DashboardSelectionMenu } from "./components/DashboardSelectionMenu";
import { DashboardControls } from "./components/DashboardControls/DashboardControls";
import { useGetCellsQuery } from "@/store/cells/cells.slice";
import { assert } from "tsafe";
import { useDashboardEdited } from "@/hooks/dashboard/useDashboardEdited";
import { DashboardChangedToast } from "./components/DashboardChangedToast/DashboardChangedToast";
import { useDashboardInfoMessage } from "@/hooks/dashboard/useDashboardInfoMessage";
import { useAppNavigator } from "@/hooks/navigation/use-app-navigator";
import { store } from "@/store/store";
import { Helmet } from "react-helmet-async";

const DASHBOARD_EDITED_TOAST_ID = "dashboard-edited-toast";

export interface Props extends BaseProps {}

export const DashboardView: FunctionComponent<Props> = (props) => {
  const { id } = useDashboardParams();

  const {
    isLoading: isLoadingDashboard,
    isSuccess: isSuccessDashboard,
    isError: isErrorDashboard,
    data: dashboardData,
  } = useGetDashboardQuery(id, { refetchOnMountOrArgChange: true });

  const {
    isLoading: isLoadingAllDashboards,
    isSuccess: isSuccessAllDashboards,
    isError: isErrorAllDashboards,
    data: allDashboardsData,
  } = useGetAllDashboardsQuery({});

  const {
    isLoading: isLoadingAllCells,
    isSuccess: isSuccessAllCells,
    isError: isErrorAllCells,
    data: allCellsData,
  } = useGetCellsQuery();

  const toast = useToast();

  const { toHome } = useAppNavigator();

  const { edited: dashboardEdited } = useDashboardEdited(id);

  const currDashboardDeleteInfoMessage = useDashboardInfoMessage(id, "deleted");
  // Redirect to the homepage if we see that the dashboard is deleted by another
  // user.
  useEffect(() => {
    if (currDashboardDeleteInfoMessage) {
      // We need to manually delete the dashboard here so that it is removed
      // from the store state when we go back to the home page (otherwise it'll
      // still be displayed in the dashboard list).
      localDeleteDashboard(store, id);
      toast({
        description: "Dashboard was deleted by another user.",
        duration: null,
        isClosable: true,
        status: "error",
        position: "bottom-left",
      });
      toHome();
    }
  }, [currDashboardDeleteInfoMessage, toHome, id, toast]);

  const isLoading =
    isLoadingDashboard || isLoadingAllDashboards || isLoadingAllCells;
  const isSuccess =
    isSuccessDashboard && isSuccessAllDashboards && isSuccessAllCells;
  const isError = isErrorDashboard || isErrorAllDashboards || isErrorAllCells;

  useEffect(() => {
    if (dashboardEdited && !toast.isActive(DASHBOARD_EDITED_TOAST_ID)) {
      toast({
        position: "bottom-left",
        duration: null,
        render: ({ onClose }) => <DashboardChangedToast onClose={onClose} />,
      });
    }
  }, [dashboardEdited, toast]);

  if (isLoading)
    return (
      <>
        <Helmet>
          <title>Skew Dashboard | Loading...</title>
        </Helmet>

        <Center height="100%">
          <Spinner size="lg"></Spinner>
        </Center>
      </>
    );

  if (isError)
    return (
      <>
        <Helmet>
          <title>Skew Dashboard</title>
        </Helmet>

        <Center height="100%">
          <Text>Unable to load dashboard.</Text>
        </Center>
      </>
    );

  if (isSuccess) {
    assert(!!dashboardData);
    return (
      <>
        <Helmet>
          <title>Skew Dashboard | {dashboardData.name}</title>
        </Helmet>

        <Flex direction="column" height="100%" overflow="auto">
          <DashboardSelectionMenu
            allDashboards={allDashboardsData!}
            allCells={allCellsData!}
            selectedDashboard={dashboardData}
          />
          <DashboardControls dashboard={dashboardData} />
          {/* Note: this key is to avoid RGL causing some weirdness with change
        events when switching between dashboards. */}
          <DashboardGrid key={dashboardData.id} dashboard={dashboardData} />
        </Flex>
      </>
    );
  }

  console.warn("Should not reach here.");
  return null;
};
