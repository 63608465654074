import { KEYS } from "@/keys";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ConfigType } from ".";
import queryString from "query-string";
import * as Utilities from "@/utilities";

interface RandomCellMessage {
  rng: number;
}

const REDUCER_PATH = "cells__randomNumberCellApi";

export const randomNumberCellApiSlice = createApi({
  // How long to wait before closing a ws connection after it is not used.
  keepUnusedDataFor: 5,
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: KEYS.API_URL,
    prepareHeaders: Utilities.authenticatedPrepareHeaders,
  }),
  endpoints: (builder) => ({
    dataStream: builder.query<RandomCellMessage | null, Partial<ConfigType>>({
      query: ({ pollRate, rangeMax, rangeMin }) => ({
        url: "/cells/fake",
        params: {
          poll_rate: pollRate,
          range_min: rangeMin,
          range_max: rangeMax,
        },
      }),
      transformResponse: () => null,
      async onCacheEntryAdded(
        { pollRate, rangeMin, rangeMax, auth_token },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const params = queryString.stringify({
          poll_rate: pollRate,
          range_min: rangeMin,
          range_max: rangeMax,
          authorization: auth_token
        });

        await cacheDataLoaded;

        const ws = new WebSocket(
          `${KEYS.API_WS_URL}/cells/random_number_cell/ws?${params}`
        );

        try {
          const listener = (event: MessageEvent) => {
            const data = JSON.parse(event.data) as RandomCellMessage;

            updateCachedData((currRng) => {
              return data;
            });
          };

          ws.addEventListener("message", listener);
        } catch {
          // Do nothing
        }

        await cacheEntryRemoved;

        ws.close();
      },
    }),
  }),
});

export const { useDataStreamQuery } = randomNumberCellApiSlice;
