import * as Utilities from "@/utilities";

export type PermissionAction = typeof PERMISSION_ACTION_VALUES[number];
export const PERMISSION_ACTION_VALUES = ["user", "admin"] as const;

export const PERMISSION_ACTION_TYPE_WEIGHTS: {
  [permissionAction in PermissionAction]: number;
} = {
  admin: 1,
  user: 0,
};

/**
 * Returns true if the given permission action's weighting is equal or higher to
 * the target permission action's weight.
 */
export function permissionActionHasWeightOf(
  permissionAction: PermissionAction,
  targetPermissionAction: PermissionAction
): boolean {
  const permissionActionWeight =
    PERMISSION_ACTION_TYPE_WEIGHTS[permissionAction];
  const targetPermissionActionWeight =
    PERMISSION_ACTION_TYPE_WEIGHTS[targetPermissionAction];

  return permissionActionWeight >= targetPermissionActionWeight;
}

export function toDisplayString(permissionAction: PermissionAction) {
  return Utilities.capitalizeWords(permissionAction.replaceAll("_", "-"));
}

export function decode(value: string): PermissionAction {
  if (!PERMISSION_ACTION_VALUES.includes(value as any))
    throw "Corresponding permission action not found";
  return value as PermissionAction;
}

export function encode(permissionAction: PermissionAction): string {
  return permissionAction;
}
