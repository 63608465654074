import React, { useMemo, useRef, useState } from "react";
import {
  Box,
  Center,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  DashboardCellInstance,
  DashboardCellInstanceNoIdentifier,
} from "@/models/dashboard/dashboard-cell-instance.model";
import { useGetCellQuery } from "@/store/cells/cells.slice";
import {
  MdClose,
  MdError,
  MdFullscreen,
  MdFullscreenExit,
  MdSettings,
} from "react-icons/md";
import useFullscreen from "@/hooks/utils/useFullscreen";
import { FaWindowRestore } from "react-icons/fa";
import { useUpdateDashboardCellMutation } from "@/store/dashboards/dashboards.slice";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { lookupCellRegistryItem } from "@/cells";
import {
  DashboardGridCellSettingsModal,
  DashboardCellInstanceSettings,
} from "./DashboardGridCellSettingsModal/DashboardGridCellSettingsModal";
import { assert } from "tsafe";
import { DeleteDashboardCellModal } from "@/components/modals/DeleteDashboardCellModal/DeleteDashboardCellModal";
import {
  CellAdditionalDataType,
  CellConfigBaseType,
  CellRegistryItem,
  CELL_POPUP_WINDOW_SIZE_MODIFIER_DEFAULTS,
} from "@/cells/cell.types";
import NewWindow from "react-new-window";
import { useCollaboratorHasPerms } from "@/hooks/dashboard/useCollaboratorHasPerms";

// References:
// - https://github.com/react-grid-layout/react-grid-layout#custom-child-components-and-draggable-handles
// - https://stackoverflow.com/a/45116780

interface Props<C extends CellConfigBaseType> {
  draggableHandle?: string;
  dashboard: Dashboard;
  dashboardCellInstance: DashboardCellInstance<C>;
}

export const DashboardGridCell = <
  C extends CellConfigBaseType,
  A extends CellAdditionalDataType
>(
  props: Props<C>
) => {
  const { draggableHandle, dashboard, dashboardCellInstance } = props;

  const hasEditorPerms = useCollaboratorHasPerms(dashboard, "editor");

  const {
    isOpen: isDashboardSettingsModalOpen,
    onOpen: onOpenDashboardSettingsModal,
    onClose: onCloseDashboardSettingsModal,
  } = useDisclosure();

  const {
    isOpen: isDeleteDashboardCellModalOpen,
    onOpen: onOpenDeleteDashboardCellModal,
    onClose: onCloseDeleteDashboardCellModal,
  } = useDisclosure();

  const {
    data: cellData,
    isLoading: isCellDataLoading,
    isSuccess: isCellDataSuccess,
    isError: isCellDataError,
  } = useGetCellQuery(dashboardCellInstance.cellIdentifier);

  const [updateDashboardCell] = useUpdateDashboardCellMutation();

  const cellRef = useRef<HTMLDivElement>(null);

  const [isFullscreen, setFullscreen] = useState(false);
  useFullscreen(cellRef, isFullscreen, {
    onClose: () => setFullscreen(false),
  });

  const [isWindowed, setWindowed] = useState(false);

  const cellRegistryItem = useMemo(() => {
    if (!cellData) return null;
    return (
      (lookupCellRegistryItem(cellData.identifier) as CellRegistryItem<
        Partial<C>,
        A
      >) ?? null
    );
  }, [cellData]);

  const CellComponent = useMemo(() => {
    return cellRegistryItem?.CellComponent ?? null;
  }, [cellRegistryItem]);

  const handleWindowToggle = () => {
    setWindowed(() => !isWindowed);
  };

  const handleWindowUnload = () => {
    setWindowed(() => false);
  };

  const handleFullscreenToggle = () => {
    setFullscreen(!isFullscreen);
  };

  const handleOpenCellSettings = () => {
    onOpenDashboardSettingsModal();
  };

  const handleDashboardSettingsModalChanged = (
    newSettings: DashboardCellInstanceSettings<C>
  ) => {
    const updatedCell: DashboardCellInstanceNoIdentifier = {
      ...dashboardCellInstance,
      displayName: newSettings.displayName,
      config: newSettings.config,
    };

    // Update the dashboard cell's config in the API
    updateDashboardCell({
      dashboard: dashboard,
      dashboardCellIdentifier: dashboardCellInstance.identifier,
      updatedCell: updatedCell,
    });
  };

  const renderLoadingScaffold = () => (
    <Flex
      direction="column"
      bgColor="white"
      height="100%"
      shadow="md"
      rounded="md"
    >
      {/* Header */}
      <Flex
        className={draggableHandle}
        direction="row"
        justifyContent="center"
        color="white"
        bgColor="app-blue.secondary"
        roundedTop="md"
        paddingLeft="2"
        paddingRight="2"
        paddingTop="1"
        paddingBottom="1"
      >
        <Box
          gridArea="name"
          justifySelf="center"
          color="gray.100"
          fontStyle="italic"
        >
          Loading...
        </Box>
      </Flex>
      {/* Content */}
      <Box
        paddingLeft="2"
        paddingRight="2"
        paddingTop="1"
        paddingBottom="2"
        height="100%"
      >
        <Center height="100%">
          <Spinner size="lg" />
        </Center>
      </Box>
    </Flex>
  );

  const renderErrorScaffold = () => (
    <Flex
      direction="column"
      bgColor="white"
      height="100%"
      shadow="md"
      rounded="md"
    >
      {/* Header */}
      <Flex
        className={draggableHandle}
        flexDir="row"
        justifyContent="center"
        color="white"
        bgColor="app-blue.secondary"
        roundedTop="md"
        paddingLeft="2"
        paddingRight="2"
        paddingTop="1"
        paddingBottom="1"
      >
        <Box color="red.200" fontStyle="italic">
          Error
        </Box>
      </Flex>
      {/* Content */}
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        paddingLeft="2"
        paddingRight="2"
        paddingTop="1"
        paddingBottom="2"
        height="100%"
      >
        <Icon as={MdError} width="8" height="8" color="red.400" />
        <Text>Unable to load cell data.</Text>
      </Flex>
    </Flex>
  );

  const cellDefaultDisplayName = useMemo(() => {
    if (cellRegistryItem?.advancedDisplayName) {
      return cellRegistryItem.advancedDisplayName(
        dashboardCellInstance.config,
        dashboard.dashboardLevelDefaultConfigs as Partial<C> | null,
        cellRegistryItem.globalLevelDefaultConfigs
      );
    }

    return cellData?.displayName ?? null;
  }, [
    cellData,
    cellRegistryItem,
    dashboardCellInstance.config,
    dashboard.dashboardLevelDefaultConfigs,
  ]);

  // Pick the user-given display name first before defaulting to the cell's
  // default name (or its advanced display name function)
  const cellDisplayName = useMemo(() => {
    if (dashboardCellInstance.displayName)
      return dashboardCellInstance.displayName;

    // Just return an empty string if the cell's data has not been loaded yet
    return cellDefaultDisplayName ?? "";
  }, [dashboardCellInstance.displayName, cellDefaultDisplayName]);

  if (isCellDataLoading) return renderLoadingScaffold();

  if (isCellDataError) return renderErrorScaffold();

  assert(isCellDataSuccess && cellData);

  const CellRender = CellComponent ? (
    <CellComponent
      explicitConfig={dashboardCellInstance.config}
      dashboardLevelConfig={dashboard.dashboardLevelDefaultConfigs as Partial<C> | null}
      globalLevelConfig={cellRegistryItem!.globalLevelDefaultConfigs}
      additionalData={cellData.additionalData}
    />
  ) : (
    <Center height="100%">
      <Text>Cell "{cellData.displayName}" not found.</Text>
    </Center>
  );

  return (
    <Flex
      ref={cellRef}
      direction="column"
      bgColor="white"
      height="100%"
      shadow="md"
      rounded="md"
    >
      {/* Cell Config Modal */}
      <DashboardGridCellSettingsModal
        isOpen={isDashboardSettingsModalOpen}
        onClose={onCloseDashboardSettingsModal}
        cell={cellData}
        currentSettings={dashboardCellInstance}
        additionalData={cellData.additionalData}
        onSettingsChanged={handleDashboardSettingsModalChanged}
        cellDefaultDisplayName={cellDefaultDisplayName}
        cellDisplayName={cellDisplayName}
        cellRegistryItem={cellRegistryItem}
        dashboard={dashboard}
      />

      {/* Delete Dashboard Cell Modal */}
      <DeleteDashboardCellModal
        isOpen={isDeleteDashboardCellModalOpen}
        onClose={onCloseDeleteDashboardCellModal}
        dashboard={dashboard}
        dashboardCellInstance={dashboardCellInstance}
        cell={cellData}
      />

      {/* Windowed Cell Portal */}
      {isWindowed && (
        <NewWindow
          onUnload={handleWindowUnload}
          title={cellDisplayName}
          center="screen"
          features={{
            width:
              window.screen.width *
              (cellRegistryItem?.popupWindowSizeModifiers?.width ??
                CELL_POPUP_WINDOW_SIZE_MODIFIER_DEFAULTS.width),
            height:
              window.screen.height *
              (cellRegistryItem?.popupWindowSizeModifiers?.height ??
                CELL_POPUP_WINDOW_SIZE_MODIFIER_DEFAULTS.height),
          }}
        >
          {CellRender}
        </NewWindow>
      )}

      {/* Header */}
      <Flex
        className={draggableHandle}
        justifyContent="space-between"
        color="white"
        bgColor="app-blue.secondary"
        roundedTop="md"
        paddingLeft="2"
        paddingRight="2"
        paddingTop="1"
        paddingBottom="1"
      >
        <Box
          flexShrink={1}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          <Tooltip label={cellDisplayName}>{cellDisplayName}</Tooltip>
        </Box>
        <Flex
          flexDirection="row"
          flexShrink={0}
          __css={{
            gap: "0.3em",
          }}
        >
          {/* Popup window button */}
          <Tooltip label="Window Cell">
            <IconButton
              aria-label="Window Cell"
              colorScheme="transparent"
              size="xs"
              variant="ghost"
              color="white"
              onClick={handleWindowToggle}
              icon={<FaWindowRestore size="1em" />}
            />
          </Tooltip>

          {/* Fullscreen button */}
          <Tooltip label="Fullscreen Cell">
            <IconButton
              aria-label="Fullscreen Cell"
              colorScheme="transparent"
              size="xs"
              variant="ghost"
              color="white"
              onClick={handleFullscreenToggle}
              icon={
                isFullscreen ? (
                  <MdFullscreenExit size="1.5em" />
                ) : (
                  <MdFullscreen size="1.5em" />
                )
              }
            />
          </Tooltip>

          {/* Cell settings button */}
          {hasEditorPerms && (
            <Tooltip label="Cell Settings">
              <IconButton
                aria-label="Cell Settings"
                colorScheme="transparent"
                size="xs"
                variant="ghost"
                color="white"
                onClick={handleOpenCellSettings}
                icon={<MdSettings size="1.4em" />}
                // Disable cell settings in fullscreen because the modal
                // is hidden under it and can't be seen
                isDisabled={isFullscreen}
              />
            </Tooltip>
          )}

          {/* Remove cell button */}
          {hasEditorPerms && (
            <Tooltip label="Remove Cell">
              <IconButton
                aria-label="Remove Cell"
                colorScheme="transparent"
                size="xs"
                variant="ghost"
                color="white"
                onClick={onOpenDeleteDashboardCellModal}
                icon={<MdClose size="1.5em" />}
                // Disable cell removing in fullscreen because the modal
                // is hidden under it and can't be seen
                isDisabled={isFullscreen}
              />
            </Tooltip>
          )}
        </Flex>
      </Flex>
      {/* Content */}
      <Box
        paddingLeft="2"
        paddingRight="2"
        paddingTop="1"
        paddingBottom="2"
        height="100%"
        overflow="hidden"
      >
        {CellRender}
      </Box>
    </Flex>
  );
};
