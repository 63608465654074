import React, {
  FunctionComponent,
  Ref,
  useImperativeHandle,
  useMemo,
} from "react";
import { Divider, Heading, Text, VStack } from "@chakra-ui/react";
import { SubmissionHandlerRef } from "../DashboardSettingsModal";
import { DashboardDefaultFieldConfig } from "@/models/dashboard/dashboard-default-field-config.model";
import { useFieldArray, useForm } from "react-hook-form";
import {
  getCellConfigRegistryItems,
  lookupCellConfigFieldsRegistryItem,
} from "@/cell-config-fields";
import { assert } from "tsafe";
import { DashboardFieldConfigAddInput } from "./DashboardFieldConfigAddInput";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { ModalStatus } from "../../ModalBase/ModalBase";
import * as Utilities from "@/utilities";
import {
  ConfigFieldFormData,
  ConfigFieldInputItem,
} from "@/cell-config-fields/cell-config-fields.types";

export interface Props {
  dashboard: Dashboard;
  submissionHandlerRef: Ref<
    SubmissionHandlerRef<DashboardDefaultFieldConfig | null>
  >;
  status: ModalStatus;
}

interface SettingsInputs extends ConfigFieldFormData {}

function getDefaultFieldItemInputs(
  dashboard: Dashboard
): ConfigFieldInputItem[] {
  if (!dashboard.dashboardLevelDefaultConfigs) return [];

  return Object.entries(dashboard.dashboardLevelDefaultConfigs).map(
    ([identifier, value]) => {
      return {
        identifier: Utilities.camelToSnakeCase(identifier),
        value,
      };
    }
  );
}

export const DashboardFieldConfigForm: FunctionComponent<Props> = (props) => {
  const { dashboard, submissionHandlerRef, status } = props;

  const form = useForm<SettingsInputs>({
    defaultValues: {
      configFieldInputs: getDefaultFieldItemInputs(dashboard),
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    register,
  } = form;

  const fieldArray = useFieldArray({
    control,
    name: "configFieldInputs",
  });

  const {
    fields: defaultFields,
    append: appendDefaultField,
    remove: removeDefaultField,
  } = fieldArray;

  const defaultFieldIdentifiers = useMemo(
    () => defaultFields.map((f) => f.identifier),
    [defaultFields]
  );

  const handleSubmitPromise = () => {
    return new Promise<SettingsInputs>((resolve, reject) => {
      handleSubmit(
        (data) => {
          resolve(data);
        },
        (errs) => {
          reject();
        }
      )();
    });
  };

  const handleAddFieldConfig = (newIdentifier: string) => {
    const regItem = lookupCellConfigFieldsRegistryItem(newIdentifier);

    // Make sure registry item exists
    assert(regItem);

    // Make sure newIdentifier is not already in defaultFields
    const hasIdentifier = defaultFields.some(
      (f) => f.identifier === newIdentifier
    );
    assert(!hasIdentifier);

    const defaultValue = regItem.dashboardDefaultValue;
    appendDefaultField({
      identifier: newIdentifier,
      value: defaultValue,
    });
  };

  const handleRemoveFieldConfig = (idx: number) => {
    removeDefaultField(idx);
  };

  useImperativeHandle(submissionHandlerRef, () => ({
    submit: async () => {
      try {
        const formData = await handleSubmitPromise();
        reset();

        const retConfig: Record<string, any> = {};

        defaultFieldIdentifiers.forEach((identifier, idx) => {
          const identifierCamel = Utilities.snakeToCamelCase(identifier);
          retConfig[identifierCamel] = formData.configFieldInputs[idx].value;
        });

        return retConfig as DashboardDefaultFieldConfig;
      } catch (err) {
        return null;
      }
    },
  }));

  const registryItems = useMemo(
    () => getCellConfigRegistryItems(defaultFieldIdentifiers),
    [defaultFieldIdentifiers]
  );

  return (
    <VStack
      width="100%"
      as="form"
      onSubmit={handleSubmit((data) => console.log(data))}
    >
      <Heading as="h5" size="sm" fontWeight="semibold">
        Default Cell Settings
      </Heading>

      <DashboardFieldConfigAddInput
        dashboard={dashboard}
        onAddFieldConfig={handleAddFieldConfig}
        currentFieldConfigIdentifiers={defaultFieldIdentifiers}
      />

      <Divider />

      <VStack width="100%">
        {defaultFields.length > 0 ? (
          defaultFields.map((field, idx) => {
            const regItem = registryItems[idx];
            return (
              <regItem.InputComponent
                key={field.id}
                context="dashboard-settings"
                status={status}
                error={errors?.configFieldInputs?.[idx].value}
                control={control}
                name={`configFieldInputs.${idx}.value` as const}
                register={register}
                removeFieldConfig={() => handleRemoveFieldConfig(idx)}
              />
            );
          })
        ) : (
          <Text fontStyle="italic">No default cell settings.</Text>
        )}
      </VStack>
    </VStack>
  );
};
