import * as yup from "yup";
import {
  DashboardCollaboratorType,
  dashboardCollaboratorTypeHasWeightOf,
  DashboardCollaboratorTypeWithOwner,
  DASHBOARD_COLLABORATOR_TYPE_VALUES,
} from "./dashboard-collaborator-type.model";
import * as Utilities from "@/utilities";
import { Dashboard } from "./dashboard.model";

export interface DashboardCollaborator {
  email: string;
  type: DashboardCollaboratorType;
}

export const dashboardCollaboratorSchema = yup.object({
  email: yup.string().email().required(),
  // Source: https://github.com/jquense/yup/issues/1384#issuecomment-880559097
  type: yup
    .mixed<DashboardCollaboratorType>()
    .oneOf([...DASHBOARD_COLLABORATOR_TYPE_VALUES])
    .required(),
});

/**
 * Gets the collaborator type of the collaborator email in the given dashboard.
 * If the email is not a collaborator then null is returned.
 */
export function getCollaboratorType(
  dashboard: Dashboard,
  collaboratorEmail: string
): DashboardCollaboratorTypeWithOwner | null {
  if (dashboard.owner === collaboratorEmail) return "owner";
  for (const collaborator of dashboard.collaborators) {
    if (collaborator.email === collaboratorEmail) return collaborator.type;
  }
  return null;
}

/**
 * Returns true if the collaborator with the given email has a target
 * collaborator type of or higher than the given collaborator type in the
 * dashboard. If the email given is not a collaborator then false is always
 * returned.
 */
export function dashboardCollaboratorHasWeightOf(
  dashboard: Dashboard,
  collaboratorEmail: string,
  targetType: DashboardCollaboratorTypeWithOwner
): boolean {
  const collaboratorType = getCollaboratorType(dashboard, collaboratorEmail);
  if (!collaboratorType) return false;
  return dashboardCollaboratorTypeHasWeightOf(collaboratorType, targetType);
}

export function fromJson(json: any): DashboardCollaborator {
  const jsonCamelCase = Utilities.snakeToCamelCaseObject(json);

  const dashboardCollaboratorCasted =
    dashboardCollaboratorSchema.cast(jsonCamelCase);
  const dashboardCollaborator = dashboardCollaboratorSchema.validateSync(
    dashboardCollaboratorCasted
  );

  return dashboardCollaborator;
}

export function toJson(dashboardCollaborator: DashboardCollaborator): any {
  return Utilities.camelToSnakeCaseObject(dashboardCollaborator);
}
