import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

interface Config {
  prefix?: string;
  suffix?: string;
}

export function useUuid(config?: Config) {
  const [id] = useState(uuidv4());
  return `${config?.prefix ?? ""}${id}${config?.suffix ?? ""}`;
}