import React, { FunctionComponent } from "react";
import { Text } from "@chakra-ui/react";
import { DashboardCellInstance } from "@/models/dashboard/dashboard-cell-instance.model";
import {
  ConfirmationModal,
  Props as ConfirmationModalProps,
} from "../ConfirmationModal/ConfirmationModal";
import { useDeleteDashboardCellMutation } from "@/store/dashboards/dashboards.slice";
import { useAppToasts } from "@/hooks/toasts/useAppToasts";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { Cell } from "@/models/cells/cell.model";
import { assert } from "tsafe";

export interface Props
  extends Omit<
    ConfirmationModalProps,
    "renderContent" | "confirmationButtonOnClick"
  > {
  dashboard: Dashboard;
  dashboardCellInstance: DashboardCellInstance;
  cell: Cell;
}

export const DeleteDashboardCellModal: FunctionComponent<Props> = (props) => {
  const {
    dashboard,
    dashboardCellInstance,
    cell,
    ...rest
  } = props;

  assert(dashboardCellInstance.cellIdentifier === cell.identifier);

  const [deleteDashboardCell] = useDeleteDashboardCellMutation();
  const { errorToast } = useAppToasts();

  const confirmationButtonOnClick = async () => {
    try {
      await deleteDashboardCell({
        dashboard,
        dashboardCellIdentifier: dashboardCellInstance.identifier,
      }).unwrap();

      return true;
    } catch (err) {
      errorToast("Unable to delete dashboard cell.");

      return false;
    }
  };

  const displayName = dashboardCellInstance.displayName
    ? dashboardCellInstance.displayName
    : cell.displayName;

  return (
    <ConfirmationModal
      title="Delete Dashboard Cell"
      confirmationButtonColorScheme="red"
      confirmationButtonContent="Delete"
      isCentered
      {...rest}
      confirmationButtonOnClick={confirmationButtonOnClick}
      renderContent={() => <Text>Delete dashboard cell "{displayName}"?</Text>}
    />
  );
};
