import React, { FunctionComponent, useCallback, useEffect } from "react";
import { Box, Center, Text } from "@chakra-ui/react";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import {
  Layout as RGLLayout,
  Layouts as RGLLayouts,
  Responsive as ResponsiveGridLayout,
} from "react-grid-layout";
import { useMemo } from "react";
import { DashboardGridCell } from "./DashboardGridCell/DashboardGridCell";
import { useThrottledMeasure } from "@/hooks/utils/useThrottledMeasure";
import { useState } from "react";
import { DashboardCellInstance } from "@/models/dashboard/dashboard-cell-instance.model";
import { useUpdateDashboardCellLocationsMutation } from "@/store/dashboards/dashboards.slice";
import { assert } from "tsafe";
import { useCollaboratorHasPerms } from "@/hooks/dashboard/useCollaboratorHasPerms";

interface Props {
  dashboard: Dashboard;
}

enum BREAKPOINT_NAMES {
  LG = "lg",
  MD = "md",
  SM = "sm",
  XS = "xs",
}

enum BREAKPOINT_VALUES {
  LG = 1000,
  MD = 768,
  SM = 480,
  XS = 0,
}

interface DashboardCellMap {
  [cellIdentifier: string]: DashboardCellInstance | undefined;
}

const CELL_HEIGHT = 300;

const DRAGGABLE_HANDLE_CLASSNAME = "DashboardGrid__cell-handle";

// Checks equality between two RGL layouts. Note: only checks for the
// metrics that are used by our grid (i, h, w, x, y)
const layoutsEqual = (
  layouts1: RGLLayout[],
  layouts2: RGLLayout[]
): boolean => {
  if (layouts1.length !== layouts2.length) return false;

  for (let i = 0; i < layouts1.length; i++) {
    const l1 = layouts1[i];
    const l2 = layouts2[i];

    const same =
      l1.i === l2.i &&
      l1.h === l2.h &&
      l1.w === l2.w &&
      l1.x === l2.x &&
      l1.y === l2.y;

    if (!same) return false;
  }

  return true;
};

export const DashboardGrid: FunctionComponent<Props> = (props) => {
  const { dashboard } = props;
  const [measure, ref] = useThrottledMeasure<HTMLDivElement>(100);

  const [updateDashboardCellLocations] =
    useUpdateDashboardCellLocationsMutation();

  const hasEditorPerms = useCollaboratorHasPerms(dashboard, "editor");

  const [isLayoutEditable, setIsLayoutEditable] = useState(false);

  const dashboardCellMap = useMemo<DashboardCellMap>(() => {
    const cellMap: DashboardCellMap = {};

    for (const cell of dashboard.cells) cellMap[cell.identifier] = cell;

    return cellMap;
  }, [dashboard]);

  const getLayoutFromDashboard = useCallback(() => {
    const lgLayout: RGLLayout[] = dashboard.cells.map(
      (dashboardCellInstance) => {
        const currLayout: RGLLayout = {
          i: dashboardCellInstance.identifier,
          x: dashboardCellInstance.location.x,
          y: dashboardCellInstance.location.y,
          h: dashboardCellInstance.location.height,
          w: dashboardCellInstance.location.width,
        };

        return currLayout;
      }
    );

    // We only care about the large breakpoint. The smaller breakpoints can be
    // whatever because editing is disabled.
    const layouts: RGLLayouts = {
      lg: lgLayout,
    };

    return layouts;
  }, [dashboard]);

  const [layouts, setLayouts] = useState<RGLLayouts>({});

  useEffect(() => {
    setLayouts(getLayoutFromDashboard());
  }, [getLayoutFromDashboard]);

  const handleBreakpointChange = (breakpoint: string, numColumns: number) => {
    // Disable editing when we are not on the largest breakpoint. This avoids us
    // having to have logic for user cell size/location customization on smaller
    // breakpoints which is hard to implement intuitively from a UI design
    // standpoint and is more likely to just confuse the user more
    // than anything.
    setIsLayoutEditable(breakpoint === BREAKPOINT_NAMES.LG);
  };

  useEffect(() => {
    // Layout hasn't been fully initialized yet, so just ignore
    if (!layouts[BREAKPOINT_NAMES.LG]) return;

    const currLgLayout = getLayoutFromDashboard()[BREAKPOINT_NAMES.LG];
    const nextLgLayout = layouts[BREAKPOINT_NAMES.LG];

    // Check if anything changed
    if (layoutsEqual(currLgLayout, nextLgLayout)) return;

    // Update the cells of the dashboard with their new locations
    const updatedCells = [...dashboard.cells];

    for (const layout of nextLgLayout) {
      const cell = dashboardCellMap[layout.i];

      if (!cell) continue;

      const cellIdx = dashboard.cells.findIndex(
        (dashboardCellInstance) =>
          dashboardCellInstance.identifier === cell.identifier
      );

      assert(cellIdx > -1);

      updatedCells[cellIdx] = {
        ...cell,
        location: {
          x: layout.x,
          y: layout.y,
          width: layout.w,
          height: layout.h,
        },
      };
    }

    const updatedDashboard: Dashboard = {
      ...dashboard,
      cells: updatedCells,
    };

    try {
      // Save the cell location changes to the API
      updateDashboardCellLocations({
        dashboard,
        updatedLocations: updatedDashboard.cells.map((cell) => ({
          identifier: cell.identifier,
          location: cell.location,
        })),
      });
    } catch (err) {
      // Fail silently
      console.error(err);
    }
  }, [
    layouts,
    dashboard,
    dashboardCellMap,
    updateDashboardCellLocations,
    getLayoutFromDashboard,
  ]);

  const handleLayoutChange = async (
    currLayout: RGLLayout[],
    allLayouts: RGLLayouts
  ) => {
    setLayouts(allLayouts);
  };

  // For performance increase:
  // - Source: https://github.com/react-grid-layout/react-grid-layout#performance
  const cells = useMemo(() => {
    return dashboard.cells.map((dashboardCellInstance) => (
      // For whatever reason the example in the rgl docs using forwardRef does
      // not work so to get around this we just wrap the cell in a container div
      // https://github.com/react-grid-layout/react-grid-layout#custom-child-components-and-draggable-handles
      <Box key={dashboardCellInstance.identifier}>
        <DashboardGridCell
          key={dashboardCellInstance.identifier}
          draggableHandle={DRAGGABLE_HANDLE_CLASSNAME}
          dashboard={dashboard}
          dashboardCellInstance={dashboardCellInstance}
        />
      </Box>
    ));
  }, [dashboard]);

  return (
    <Box ref={ref} height="100%" overflow="auto">
      {cells.length > 0 ? (
        <ResponsiveGridLayout
          layouts={layouts}
          breakpoints={{
            [BREAKPOINT_NAMES.LG]: BREAKPOINT_VALUES.LG,
            [BREAKPOINT_NAMES.MD]: BREAKPOINT_VALUES.MD,
            [BREAKPOINT_NAMES.SM]: BREAKPOINT_VALUES.SM,
            [BREAKPOINT_NAMES.XS]: BREAKPOINT_VALUES.XS,
          }}
          isDraggable={hasEditorPerms && isLayoutEditable}
          isResizable={hasEditorPerms && isLayoutEditable}
          cols={{
            // TODO: configure w.r.t. to ServerInfo
            [BREAKPOINT_NAMES.LG]: 4,
            [BREAKPOINT_NAMES.MD]: 3,
            [BREAKPOINT_NAMES.SM]: 2,
            [BREAKPOINT_NAMES.XS]: 1,
          }}
          width={measure?.contentRect.width ?? 0}
          rowHeight={CELL_HEIGHT}
          draggableHandle={`.${DRAGGABLE_HANDLE_CLASSNAME}`}
          onBreakpointChange={handleBreakpointChange}
          onLayoutChange={handleLayoutChange}
        >
          {cells}
        </ResponsiveGridLayout>
      ) : (
        <Center height="100%">
          <Text fontStyle="italic">No cells currently in dashboard.</Text>
        </Center>
      )}
    </Box>
  );
};
