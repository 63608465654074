import * as yup from "yup";
import { lookupCellRegistryItem } from "@/cells";
import * as Utilities from "@/utilities";
import { CellAdditionalDataType, CellConfigBaseType } from "@/cells/cell.types";

export interface Cell<
  C extends CellConfigBaseType = any,
  A extends CellAdditionalDataType = any
> {
  identifier: string;
  displayName: string;
  additionalData: A | null;
  category?: string | null;
}

export const cellSchema = yup.object({
  identifier: yup.string().required(),
  displayName: yup.string().required(),
  category: yup.string().nullable(),
  additionalData: yup.object().nullable(),
});

export function fromJson<
  C extends CellConfigBaseType = any,
  A extends CellAdditionalDataType = any
>(json: any): Cell<C, A> {
  const jsonCamelCase = Utilities.snakeToCamelCaseObject(json);

  const cellIdentifier = jsonCamelCase["identifier"];
  const cellRegistry = lookupCellRegistryItem(cellIdentifier);

  if (!cellRegistry) {
    console.error(
      `Warning: cell '${cellIdentifier}' has no cell registry, not parsing cell default config.`
    );
  }

  const configSchema = cellRegistry?.configSchema
    ? cellRegistry?.configSchema
    : yup.object();

  const additionalDataSchema = cellRegistry?.additionalDataSchema
    ? cellRegistry?.additionalDataSchema
    : yup.object();

  const cellSchemaWithGenerics = cellSchema.shape({
    defaultConfig: configSchema.nullable(),
    additionalData: additionalDataSchema.nullable(),
  });

  const cellData = cellSchemaWithGenerics.cast(jsonCamelCase);
  const cellDataValidated = cellSchemaWithGenerics.validateSync(cellData);

  return cellDataValidated as Cell<C, A>;
}

export function toJson<
  C extends CellConfigBaseType = any,
  A extends CellAdditionalDataType = any
>(cell: Cell<C, A>): any {
  return Utilities.camelToSnakeCaseObject(cell);
}
