import React, { FunctionComponent, useMemo } from "react";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { MdDashboard, MdApps } from "react-icons/md";
import { FaShareAltSquare } from "react-icons/fa";
import {
  CategoryDropdown,
  CategoryDropdownMap,
  UncategorizedData,
} from "@/components/dropdowns/CategoryDropdown/CategoryDropdown";
import { Flex } from "@chakra-ui/react";
import { Cell } from "@/models/cells/cell.model";
import { useAppNavigator } from "@/hooks/navigation/use-app-navigator";
import { useAddDashboardCellMutation } from "@/store/dashboards/dashboards.slice";
import { DashboardCellInstanceNoIdentifier } from "@/models/dashboard/dashboard-cell-instance.model";
import { useAppToasts } from "@/hooks/toasts/useAppToasts";
import { assert } from "tsafe";
import { useAuth } from "@/hooks/auth/auth.hooks";
import { useCollaboratorHasPerms } from "@/hooks/dashboard/useCollaboratorHasPerms";

export interface Props {
  selectedDashboard: Dashboard;
  allDashboards: Dashboard[];
  allCells: Cell[];
}

type DashboardFilterType = "owned" | "collaborator";

// Iterates through the dashboard list and constructs a map of all the
// categories the respective dashboards. Additionally returns a list of
// uncategorized dashboards.
const getDashboardCategoryMap = (
  selfEmail: string,
  filterType: DashboardFilterType,
  dashboards: Dashboard[]
): [CategoryDropdownMap<Dashboard>, UncategorizedData<Dashboard>] => {
  const categoryMap: CategoryDropdownMap<Dashboard> = {};

  let uncategorized: UncategorizedData<Dashboard> = [];

  for (const dashboard of dashboards) {
    // Note: we are making an assuming here that `dashboards` gives a list of
    // dashboards whereas the self user is either the owner of a collaborator
    // of each one. Thus if we are only looking for the dashboards where the
    // self user is a collaborator then we can assume that if the dashboard
    // owner is NOT the self user, then the self user must be a collaborator
    // on it. This assumption helps us avoid checking the collaborator list for
    // every dashboard in `dashboards`
    let include =
      filterType === "owned"
        ? dashboard.owner === selfEmail
        : dashboard.owner !== selfEmail;

    if (include) {
      if (dashboard.category) {
        if (!categoryMap[dashboard.category])
          categoryMap[dashboard.category] = [dashboard];
        else
          categoryMap[dashboard.category] = [
            ...categoryMap[dashboard.category],
            dashboard,
          ];
      } else uncategorized.push(dashboard);
    }
  }

  // Sort dashboards in each category by name
  for (const category of Object.keys(categoryMap)) {
    categoryMap[category] = categoryMap[category].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }
  uncategorized = uncategorized.sort((a, b) => a.name.localeCompare(b.name));

  return [categoryMap, uncategorized];
};

const getCellsCategoryMap = (
  cells: Cell[]
): [CategoryDropdownMap<Cell>, UncategorizedData<Cell>] => {
  const categoryMap: CategoryDropdownMap<Cell> = {};

  let uncategorized: UncategorizedData<Cell> = [];

  for (const cell of cells) {
    if (cell.category) {
      if (!categoryMap[cell.category]) categoryMap[cell.category] = [cell];
      else categoryMap[cell.category] = [...categoryMap[cell.category], cell];
    } else uncategorized.push(cell);
  }

  // Sort dashboards in each category by name
  for (const category of Object.keys(categoryMap)) {
    categoryMap[category] = categoryMap[category].sort((a, b) =>
      a.displayName.localeCompare(b.displayName)
    );
  }
  uncategorized = uncategorized.sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );

  return [categoryMap, uncategorized];
};

export const DashboardSelectionMenu: FunctionComponent<Props> = (props) => {
  const { allDashboards, allCells, selectedDashboard } = props;

  const { currUser } = useAuth();

  const { toDashboard } = useAppNavigator();
  const [addDashboardCell] = useAddDashboardCellMutation();
  const { errorToast } = useAppToasts();
  
  const hasEditorPerms = useCollaboratorHasPerms(selectedDashboard, "editor");

  const [ownedDashboardsCategoryMap, ownedDashboardsUncategorized] =
    useMemo(() => {
      return getDashboardCategoryMap(currUser.email, "owned", allDashboards);
    }, [currUser, allDashboards]);

  const [
    collaboratorDashboardsCategoryMap,
    collaboratorDashboardsUncategorized,
  ] = useMemo(() => {
    return getDashboardCategoryMap(
      currUser.email,
      "collaborator",
      allDashboards
    );
  }, [currUser, allDashboards]);

  // TODO: filter out any unsupported cells here
  const supportedCells = allCells;

  const [cellsCategoryMap, cellsUncategorized] = useMemo(() => {
    return getCellsCategoryMap(supportedCells);
  }, [supportedCells]);

  const isOwnedDashboardCategorySelected = (category: string | null) => {
    if (category)
      return ownedDashboardsCategoryMap[category].some(
        (dashboard) => dashboard.id === selectedDashboard.id!
      );
    else
      return ownedDashboardsUncategorized.some(
        (dashboard) => dashboard.id === selectedDashboard.id!
      );
  };

  const isCollaboratorDashboardCategorySelected = (category: string | null) => {
    if (category)
      return collaboratorDashboardsCategoryMap[category].some(
        (dashboard) => dashboard.id === selectedDashboard.id!
      );
    else
      return collaboratorDashboardsUncategorized.some(
        (dashboard) => dashboard.id === selectedDashboard.id!
      );
  };

  const isDashboardSelected = (dashboard: Dashboard) =>
    dashboard.id === selectedDashboard.id;

  const onDashboardClick = (dashboard: Dashboard) => {
    assert(dashboard.id);
    toDashboard(dashboard.id);
  };

  const onCellClick = async (cell: Cell) => {
    const newCell: DashboardCellInstanceNoIdentifier = {
      cellIdentifier: cell.identifier,
      displayName: null,
      config: null,
      location: {
        x: 0,
        y: 0,
        width: 1,
        height: 1,
      },
    };

    try {
      await addDashboardCell({
        dashboard: selectedDashboard,
        cell: newCell,
      }).unwrap();
    } catch (err) {
      errorToast("Unable to add cell to dashboard.");
    }
  };

  return (
    <Flex
      className="inline-scrollbar"
      backgroundColor="app-blue.secondary"
      color="white"
      overflowX="auto"
      overflowY="hidden"
    >
      <CategoryDropdown
        name="My Dashboards"
        emptyDropdownText="(No Created Dashboards)"
        icon={MdDashboard}
        categoryMap={ownedDashboardsCategoryMap}
        uncategorized={ownedDashboardsUncategorized}
        onDataClick={onDashboardClick}
        getDataId={(dashboard) => dashboard.id!}
        getDataName={(dashboard) => dashboard.name}
        isDataSelected={isDashboardSelected}
        isCategorySelected={isOwnedDashboardCategorySelected}
      />

      <CategoryDropdown
        name="Shared With Me"
        emptyDropdownText="(No Shared Dashboards)"
        icon={FaShareAltSquare}
        categoryMap={collaboratorDashboardsCategoryMap}
        uncategorized={collaboratorDashboardsUncategorized}
        onDataClick={onDashboardClick}
        getDataId={(dashboard) => dashboard.id!}
        getDataName={(dashboard) => dashboard.name}
        isDataSelected={isDashboardSelected}
        isCategorySelected={isCollaboratorDashboardCategorySelected}
      />

      <CategoryDropdown
        name="Dashboard Cells"
        icon={MdApps}
        categoryMap={cellsCategoryMap}
        uncategorized={cellsUncategorized}
        onDataClick={onCellClick}
        getDataId={(cell) => cell.identifier}
        getDataName={(dashboard) => dashboard.displayName}
        isDisabled={!hasEditorPerms}
      />
    </Flex>
  );
};
