import Highcharts from "highcharts/highstock";
import { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import { useThrottledMeasure } from "../utils/useThrottledMeasure";

/**
 * Attaches a Highchart stock chart instance to the returned ref. Also handles
 * reflowing the chart whenever the ref size changes.
 * @param initialOptions Initial Highchart options to be passed into the
 * chart instance.
 * @param resizeThrottle Throttle value on how often to reflow the chart when
 * the ref resizes.
 */
export function useHighchartStock<E extends HTMLElement>(
  initialOptions: Highcharts.Options = {},
  resizeThrottle = 100,
): [Highcharts.Chart | null, React.RefObject<E>] {
  const [measure, ref] = useThrottledMeasure<E>(resizeThrottle);

  const [chart, setChart] = useState<Highcharts.Chart | null>(null);

  // Instantiate the chart instance
  useEffectOnce(() => {
    const hc = Highcharts.stockChart(ref.current!, initialOptions);
    setChart(hc);
  });

  useEffect(() => {
    chart?.reflow();
  }, [measure]);

  return [chart, ref];
}