import React, { FunctionComponent } from "react";
import { Text } from "@chakra-ui/react";
import { ConfirmationModal, Props as ConfirmationModalProps } from "../ConfirmationModal/ConfirmationModal";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { useDeleteDashboardMutation } from "@/store/dashboards/dashboards.slice";
import { useAppToasts } from "@/hooks/toasts/useAppToasts";
import { useAppNavigator } from "@/hooks/navigation/use-app-navigator";
import { assert } from "tsafe";

export interface Props
  extends Omit<
    ConfirmationModalProps,
    "renderContent" | "confirmationButtonOnClick"
  > {
  dashboard: Dashboard;
  redirectToHomePage?: boolean;
}

export const DeleteDashboardModal: FunctionComponent<Props> = (props) => {
  const { dashboard, redirectToHomePage = false, ...rest } = props;

  const [deleteDashboard] = useDeleteDashboardMutation();

  const { errorToast } = useAppToasts();
  const { toHome } = useAppNavigator();

  const confirmationButtonOnClick = async () => {
    assert(dashboard.id !== null);

    try {
      await deleteDashboard(dashboard.id!).unwrap();

      if (redirectToHomePage) {
        // This set timeout is to fix the state update on unmounted error
        setTimeout(() => {
          // Go to the home page
          toHome();
        }, 50);
      }

      return true;
    } catch (err) {
      errorToast("Unable to delete dashboard.");

      return false;
    }
  };

  return (
    <ConfirmationModal
      title="Delete Dashboard"
      confirmationButtonColorScheme="red"
      confirmationButtonContent="Delete"
      isCentered
      {...rest}
      confirmationButtonOnClick={confirmationButtonOnClick}
      renderContent={() => (<Text>Delete dashboard "{dashboard.name}"?</Text>)}
    />
  );
};
