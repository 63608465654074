import React, { FunctionComponent } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from "react-router-dom";
import { Pathname } from "history";
import { useAuth } from "@/hooks/auth/auth.hooks";
import { assert } from "tsafe";
import { PermissionAction } from "@/models/permissions/permission-action.model";
import * as PermissionActionModel from "@/models/permissions/permission-action.model";
import { UnauthorizedView } from "@/views/unathorized/UnauthorizedView";

// References:
// - https://reactrouter.com/web/example/auth-workflow
// - https://github.com/remix-run/react-router/blob/main/packages/react-router/modules/Route.js

type UnauthorizedAction = "redirect" | "component";

interface PropsBase extends Omit<RouteProps, "render"> {
  /**
   * The action that should be done when an unauthorized user is encountered:
   * If "redirect", then user will be redirected to `unauthorizedPathname`.
   * If "component", then the `unauthorizedComponent` will be rendered.
   *
   * @default "redirect"
   */
  unauthorizedAction?: UnauthorizedAction;

  /**
   * The path to redirect to if the user is unauthenticated.
   *
   * @default "/login"
   */
  unauthenticatedPathname?: Pathname;

  /**
   * The target permission action that the user must have.
   *
   * @default "user"
   */
  permissionAction?: PermissionAction;
}

interface PropsRedirect extends PropsBase {
  /**
   * If the user is unauthorized, redirect to {@link unauthorizedPathname}
   */
  unauthorizedAction?: "redirect";

  /**
   * The path to redirect to if the user is unauthorized.
   *
   * @default "/get-access"
   */
  unauthorizedPathname?: Pathname;
}

interface PropsComponent extends PropsBase {
  /**
   * If the user is unauthorized, render the component
   * {@link unauthorizedComponent}
   */
  unauthorizedAction?: "component";

  /**
   * The component to render if the user is unauthorized.
   *
   * @default UnauthorizedView
   */
  unauthorizedComponent?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

type Props = PropsRedirect | PropsComponent;

/**
 * A wrapper for <Route> that redirects to the login screen if the user is not
 * authenticated or authorized yet.
 */
export const PrivateRoute: FunctionComponent<Props> = (props) => {
  const {
    children,
    component,
    unauthenticatedPathname = "/login",
    unauthorizedAction = "redirect",
    permissionAction: targetPermissionAction = "user",
    ...rest
  } = props;
  const { status, currUser } = useAuth(false);

  return (
    <Route
      {...rest}
      render={({ location, match, staticContext }) => {
        // Do not render anything if the authentication system is uninitialized
        // or the user is currently authenticating
        if (status === "uninitialized" || status === "authenticating") {
          return null;
        }

        // User is unauthenticated
        if (status === "unauthenticated")
          return (
            <Redirect
              to={{
                pathname: unauthenticatedPathname,
                state: { from: location },
              }}
            />
          );

        // status === "authenticated"

        assert(currUser !== null);

        // User is unauthorized
        if (
          currUser.permissionAction === null ||
          !PermissionActionModel.permissionActionHasWeightOf(
            currUser.permissionAction,
            targetPermissionAction
          )
        ) {
          // Redirect unauthorization action, redirect to unauthorizedPathname
          if (unauthorizedAction === "redirect") {
            return (
              <Redirect
                to={{
                  pathname:
                    (props as PropsRedirect).unauthorizedPathname ??
                    "/get-access",
                  state: { from: location },
                }}
              />
            );
          }
          // Component unauthorization action, render unauthorizedComponent
          else if (unauthorizedAction === "component") {
            const unauthorizedComponentProps = {
              ...staticContext,
              location,
              match,
            };
            return React.createElement(
              (props as PropsComponent).unauthorizedComponent ??
                UnauthorizedView,
              unauthorizedComponentProps
            );
          }

          throw new Error("Should not reach here.");
        }

        if (component) {
          const componentProps = { ...staticContext, location, match };
          return React.createElement(component, componentProps);
        }

        return children;
      }}
    />
  );
};
