import React, { VoidFunctionComponent } from "react";
import "./App.scss";
import { BrowserRouter as Router } from "react-router-dom";
import RouteView from "./router/RouteView";
import { Box, ChakraProvider } from "@chakra-ui/react";
import theme from "@/theme";
import { AuthenticationInitializationLoader } from "./components/loaders/AuthenticationInitializationLoader";
import { AuthManager } from "./store/auth/auth.slice";
import { HelmetProvider } from "react-helmet-async";

const App: VoidFunctionComponent = () => {
  return (
    <Router>
      <HelmetProvider>
        <ChakraProvider theme={theme}>
          <AuthManager>
            <Box id="app" height="100%" backgroundColor="app-background">
              <AuthenticationInitializationLoader>
                <RouteView />
              </AuthenticationInitializationLoader>
            </Box>
          </AuthManager>
        </ChakraProvider>
      </HelmetProvider>
    </Router>
  );
};

export default App;
