import { CellConfigBaseType } from "@/cells/cell.types";
import { Nullish } from "./types";

/**
 * Takes in the three different levels of cell config (explicit, dashboard-level
 * and global-level) and merges them into one config.
 */
export function consolidateCellConfig<C extends CellConfigBaseType>(
  explicitConfig: Partial<C> | Nullish,
  dashboardLevelConfig: Partial<C> | Nullish,
  globalLevelConfig: Partial<C> | Nullish,
): Partial<C> {
  return {
    ...(globalLevelConfig ?? {}) as Partial<C>,
    ...(dashboardLevelConfig ?? {}),
    ...(explicitConfig ?? {}),
  };
}