import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ConfigType } from ".";
import { KEYS } from "@/keys";
import queryString from "query-string";
import * as Utilities from "@/utilities";

interface RandomPieChartCellMessageDataPoint {
  name: string;
  y: number;
}

interface RandomPieChartCellMessage {
  data: RandomPieChartCellMessageDataPoint[];
}

const REDUCER_PATH = "cells__randomPieChartCellApi";

export const randomPieChartCellApiSlice = createApi({
  // How long to wait before closing a ws connection after it is not used.
  keepUnusedDataFor: 5,
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: KEYS.API_URL,
    prepareHeaders: Utilities.authenticatedPrepareHeaders,
  }),
  endpoints: (builder) => ({
    dataStream: builder.query<
      RandomPieChartCellMessage | null,
      Partial<ConfigType>
    >({
      query: ({ pollRate, numSections }) => ({
        url: "/cells/fake",
        params: {
          poll_rate: pollRate,
          num_sections: numSections,
        },
      }),
      transformResponse: () => null,
      async onCacheEntryAdded(
        { pollRate, numSections, auth_token },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const params = queryString.stringify({
          poll_rate: pollRate,
          num_sections: numSections,
          authorization: auth_token,
        });

        await cacheDataLoaded;

        const ws = new WebSocket(
          `${KEYS.API_WS_URL}/cells/random_pie_chart_cell/ws?${params}`
        );

        try {
          const listener = (event: MessageEvent) => {
            const data = JSON.parse(event.data) as RandomPieChartCellMessage;

            updateCachedData((currRng) => {
              return data;
            });
          };

          ws.addEventListener("message", listener);
        } catch {
          // Do nothing
        }

        await cacheEntryRemoved;

        ws.close();
      },
    }),
  }),
});

export const { useDataStreamQuery } = randomPieChartCellApiSlice;
