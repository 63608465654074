import React, { FunctionComponent } from "react";
import {
  ModalBase,
  ModalBaseFooterButtons,
  Props as ModalBaseProps,
} from "../ModalBase/ModalBase";
import { PartialSome, WithPrefix } from "@/utilities";

export type ConfirmationButtonProps = WithPrefix<
  PartialSome<ModalBaseFooterButtons, "content">,
  "confirmationButton"
>;

export type CancelButtonProps = WithPrefix<
  PartialSome<ModalBaseFooterButtons, "content" | "onClick">,
  "cancelButton"
>;

export interface Props
  extends Omit<ModalBaseProps, "footerButtons">,
    ConfirmationButtonProps,
    CancelButtonProps {}

export const ConfirmationModal: FunctionComponent<Props> = (props) => {
  const {
    isOpen,
    onClose,
    title,
    confirmationButtonContent = "Okay",
    confirmationButtonOnClick,
    confirmationButtonColorScheme = "dashboard-primary-button",
    confirmationButtonOrientation,
    confirmationButtonSize,
    confirmationButtonStyleConfig,
    confirmationButtonVariant,
    cancelButtonContent = "Cancel",
    cancelButtonOnClick = () => true,
    cancelButtonColorScheme = "gray",
    cancelButtonOrientation,
    cancelButtonSize,
    cancelButtonStyleConfig,
    cancelButtonVariant,
    children,
    ...rest
  } = props;

  const footerButtons: ModalBaseFooterButtons[] = [
    {
      onClick: confirmationButtonOnClick,
      content: confirmationButtonContent,
      colorScheme: confirmationButtonColorScheme,
      variant: confirmationButtonVariant,
      orientation: confirmationButtonOrientation,
      size: confirmationButtonSize,
      styleConfig: confirmationButtonStyleConfig,
      doCloseOnClick: true,
    },
    {
      onClick: cancelButtonOnClick,
      content: cancelButtonContent,
      colorScheme: cancelButtonColorScheme,
      variant: cancelButtonVariant,
      orientation: cancelButtonOrientation,
      size: cancelButtonSize,
      styleConfig: cancelButtonStyleConfig,
      doCloseOnClick: true,
    },
  ];

  return (
    <ModalBase
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      footerButtons={footerButtons}
    >
      {children}
    </ModalBase>
  );
};
