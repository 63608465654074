import { User, fromFirebaseUser } from "@/models/user/user.model";
import { firebaseAuth, firebaseGoogleProvider } from "@/config/firebase.config";
import { permissionsApi } from "./permissions.api";
import firebase from "firebase/app";
import { assert } from "tsafe";

type AuthStateChangedSubscribe = (user: User | null) => unknown;
type AuthStateChangedUnsubscribe = () => void;

/**
 * Attempts to retrieve the permission action of the given user. Returns the
 * user with its permissionAction field filled out, or null if the user does not
 * have a permission action assigned yet.
 */
async function _retrievePermissionAction(
  user: User,
  firebaseUser: firebase.User
): Promise<User> {
  // Attempt to retrieve the permission action of the user
  try {
    const token = await firebaseUser.getIdToken();
    const permission = await permissionsApi.getPermissionGrant(
      user.email,
      token
    );
    return { ...user, permissionAction: permission.action };
  } catch (err) {
    // Failed, user does not have a permission action
    return { ...user, permissionAction: null };
  }
}

function onAuthStateChanged(
  subscribe: AuthStateChangedSubscribe
): AuthStateChangedUnsubscribe {
  // Wrap firebase's onAuthStateChanged with a function that automatically tries
  // to retrieve the user's permission action
  const unsubscribe = firebaseAuth.onAuthStateChanged(async (firebaseUser) => {
    let user = fromFirebaseUser(firebaseUser);
    if (!!user) user = await _retrievePermissionAction(user, firebaseUser!);
    subscribe(user);
  });
  return unsubscribe;
}

async function signupWithEmailPassword(
  email: string,
  password: string
): Promise<User> {
  const cred = await firebaseAuth.createUserWithEmailAndPassword(
    email,
    password
  );

  assert(cred.user !== null);

  let user = fromFirebaseUser(cred.user)!;
  user = await _retrievePermissionAction(user, cred.user);

  return user;
}

async function loginWithEmailPassword(
  email: string,
  password: string
): Promise<User> {
  const cred = await firebaseAuth.signInWithEmailAndPassword(email, password);

  assert(cred.user !== null);

  let user = fromFirebaseUser(cred.user)!;
  user = await _retrievePermissionAction(user, cred.user);

  return user;
}

async function loginWithGoogle(): Promise<User> {
  const cred = await firebaseAuth.signInWithPopup(firebaseGoogleProvider);

  assert(cred.user !== null);

  let user = fromFirebaseUser(cred.user!)!;
  user = await _retrievePermissionAction(user, cred.user!);

  return user;
}

async function logout(): Promise<void> {
  await firebaseAuth.signOut();
}

export const authenticationApi = {
  onAuthStateChanged,
  signupWithEmailPassword,
  loginWithEmailPassword,
  loginWithGoogle,
  logout,
};
