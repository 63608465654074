import * as yup from "yup";
import {
  DashboardInfoMessageType,
  DASHBOARD_INFO_MESSAGE_TYPE_VALUES,
} from "./dashboard-info-message-type.model";
import * as Utilities from "@/utilities";

export interface DashboardInfoMessage {
  type: DashboardInfoMessageType;
  dashboardId: string;
  triggerEmail: string;
}

export const dashboardInfoTypeSchema = yup.object({
  type: yup
    .mixed<DashboardInfoMessageType>()
    .oneOf([...DASHBOARD_INFO_MESSAGE_TYPE_VALUES])
    .required(),
  dashboardId: yup.string().required(),
  triggerEmail: yup.string().required(),
});

export function fromJson(json: any): DashboardInfoMessage {
  const jsonCamelCase = Utilities.snakeToCamelCaseObject(json);

  const dashboardInfoMessageCasted =
    dashboardInfoTypeSchema.cast(jsonCamelCase);
  const dashboardInfoMessage = dashboardInfoTypeSchema.validateSync(
    dashboardInfoMessageCasted
  ) as DashboardInfoMessage;

  return dashboardInfoMessage;
}

export function toJson(dashboardInfoMessage: DashboardInfoMessage): any {
  return Utilities.camelToSnakeCaseObject(dashboardInfoMessage);
}
