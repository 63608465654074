import React, { FunctionComponent } from "react";
import {
  Button,
  CloseButton,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdClose, MdRefresh } from "react-icons/md";

export interface Props {
  onClose?: () => unknown;
}

export const DashboardChangedToast: FunctionComponent<Props> = (props) => {
  const { onClose } = props;

  const onRefreshDashboard = () => {
    window.location.reload();
  };

  return (
    <VStack
      backgroundColor="blue.100"
      padding="3"
      borderRadius="md"
      shadow="lg"
    >
      <Text fontWeight="semibold">Dashboard was updated.</Text>
      <Button
        aria-label="Refresh Dashboard"
        colorScheme="dashboard-primary-button"
        size="sm"
        color="white"
        onClick={onRefreshDashboard}
        leftIcon={<MdRefresh size="1.5em" />}
      >
        Refresh
      </Button>

      <CloseButton
        aria-label="Close"
        size="sm"
        onClick={onClose}
        position="absolute"
        top="1"
        right="3"
      />
    </VStack>
  );
};
