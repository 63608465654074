import {
  DashboardCellInstance,
  dashboardCellInstanceSchema,
} from "./dashboard-cell-instance.model";
import * as DashboardCellInstanceModel from "./dashboard-cell-instance.model";
import * as yup from "yup";
import * as Utilities from "@/utilities";
import {
  DashboardCollaborator,
  dashboardCollaboratorSchema,
} from "./dashboard-collaborator.model";
import {
  DashboardDefaultFieldConfig,
  dashboardDefaultFieldConfigSchema,
} from "./dashboard-default-field-config.model";

export interface Dashboard {
  id?: string | null;
  name: string;
  category?: string | null;
  owner: string;
  collaborators: DashboardCollaborator[];
  cells: DashboardCellInstance[];
  // TODO: rename to dashboardLevelDefaultConfigs
  dashboardLevelDefaultConfigs: DashboardDefaultFieldConfig | null;
}

export const dashboardSchema = yup
  .object({
    id: yup.string().nullable(),
    name: yup.string().required(),
    category: yup.string().nullable(),
    owner: yup.string().email().required(),
    collaborators: yup.array().of(dashboardCollaboratorSchema).required(),
    cells: yup.array().of(dashboardCellInstanceSchema).required(),
    dashboardLevelDefaultConfigs: dashboardDefaultFieldConfigSchema.nullable(),
  })
  .from("_id", "id");

export function fromJson(json: any): Dashboard {
  const jsonCamelCase = Utilities.snakeToCamelCaseObject(json);

  const dashboardData = dashboardSchema.cast(jsonCamelCase);

  // Manually load in cells in order for generics to work properly
  const cells = dashboardData.cells!.map((cellData) =>
    DashboardCellInstanceModel.fromJson(cellData)
  );
  // We know `cells` has the right type so to avoid having to coerce Typescript
  // into accepting it, we just cast to any[] here.
  dashboardData.cells = cells as any[];

  const dashboardDataValidated = dashboardSchema.validateSync(dashboardData);

  return dashboardDataValidated as Dashboard;
}

export function toJson(dashboard: Dashboard, includeId = true): any {
  const dashboardCpy = { ...dashboard };
  if (!includeId) delete dashboardCpy["id"];

  return Utilities.camelToSnakeCaseObject(dashboardCpy);
}

export function toJsonPartial(
  dashboard: Partial<Dashboard>,
  includeId = true
): any {
  const dashboardCpy = { ...dashboard };
  if (!includeId) delete dashboardCpy["id"];

  return Utilities.camelToSnakeCaseObject(dashboardCpy);
}
