import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { cellsApiSlice } from "@/store/cells/cells.slice";
import { authSlice } from "@/store/auth/auth.slice";
import { dashboardsApiSlice } from "./dashboards/dashboards.slice";
import { permissionsApiSlice } from "./permissions/permissions.slice";

import * as RandomNumberCell from "@/cells/cell_registry/random_number_cell";
import * as RandomPieChartCell from "@/cells/cell_registry/random_pie_chart_cell";
import * as RandomTimeseriesChartCell from "@/cells/cell_registry/random_timeseries_chart_cell";
import * as TradingBreakdownCell from "@/cells/cell_registry/trading_breakdown_cell";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    [cellsApiSlice.reducerPath]: cellsApiSlice.reducer,
    [dashboardsApiSlice.reducerPath]: dashboardsApiSlice.reducer,
    [permissionsApiSlice.reducerPath]: permissionsApiSlice.reducer,
    [RandomNumberCell.storeReducerPath]: RandomNumberCell.storeReducer,
    [RandomPieChartCell.storeReducerPath]: RandomPieChartCell.storeReducer,
    [RandomTimeseriesChartCell.storeReducerPath]:
      RandomTimeseriesChartCell.storeReducer,
    [TradingBreakdownCell.storeReducerPath]: TradingBreakdownCell.storeReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    cellsApiSlice.middleware,
    dashboardsApiSlice.middleware,
    permissionsApiSlice.middleware,
    RandomNumberCell.storeMiddleware,
    RandomPieChartCell.storeMiddleware,
    RandomTimeseriesChartCell.storeMiddleware,
    TradingBreakdownCell.storeMiddleware,
  ],
});

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
