import { DashboardInfoMessageType } from "@/models/dashboard/dashboard-info-message/dashboard-info-message-type.model";
import { DashboardInfoMessage } from "@/models/dashboard/dashboard-info-message/dashboard-info-message.model";
import { useDashboardInfoListenerQuery } from "@/store/dashboards/dashboards.slice";
import { isArray } from "highcharts";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../auth/auth.hooks";

/**
 * Gives the latest dashboard info message. If `ignoreSelf` is true then
 * any messages triggered by the currently logged in user are ignored.
 * The type of info message taken is specified using `includeType`.
 */
export function useDashboardInfoMessage(
  dashboardId: string,
  includeType?: DashboardInfoMessageType[] | DashboardInfoMessageType,
  ignoreSelf = true
): DashboardInfoMessage | null {
  const { token: authToken, currUser } = useAuth();

  const userEmail = currUser.email;

  const { data: infoMessages } = useDashboardInfoListenerQuery({
    dashboardId: dashboardId,
    auth_token: authToken,
  });

  // Flatten out to an array of include type values
  const includeTypeArr: DashboardInfoMessageType[] | null = useMemo(() => {
    if (!includeType) return null;

    if (isArray(includeType)) {
      if (includeType.length === 0) return null;
      return includeType as DashboardInfoMessageType[];
    } else {
      return [includeType as DashboardInfoMessageType];
    }
  }, [includeType]);

  const [currInfoMessage, setCurrInfoMessage] =
    useState<DashboardInfoMessage | null>(null);

  useEffect(() => {
    if (infoMessages && infoMessages.length > 0) {
      const newInfoMessage = infoMessages[infoMessages.length - 1];
      if (!ignoreSelf || newInfoMessage.triggerEmail !== userEmail) {
        if (!includeTypeArr || includeTypeArr.includes(newInfoMessage.type))
          setCurrInfoMessage(newInfoMessage);
      }
    }
  }, [infoMessages, ignoreSelf, userEmail, includeTypeArr]);

  return currInfoMessage;
}
