import { useEffect, useMemo, useState } from "react";
import { useDashboardInfoMessage } from "./useDashboardInfoMessage";

/**
 * Listens to the dashboard info message Websocket stream and gives a flag
 * that indicates if the dashboard was changed by another users. Can also
 * clear the edit flag by calling the `clearEdited` function that is returned.
 */
export function useDashboardEdited(dashboardId: string) {
  const currInfoMessage = useDashboardInfoMessage(dashboardId, "changed");

  const [isClearEditedFlag, setIsClearEditedFlag] = useState(false);

  const clearEdited = () => {
    setIsClearEditedFlag(true);
  };

  const editedTrueVal = useMemo(() => {
    return !!currInfoMessage;
  }, [currInfoMessage]);

  // Clear the edited flag whenever the next info message comes in
  useEffect(() => {
    setIsClearEditedFlag(false);
  }, [currInfoMessage]);

  const edited = useMemo(() => {
    if (isClearEditedFlag) return false;
    return editedTrueVal;
  }, [editedTrueVal, isClearEditedFlag]);

  return {
    edited,
    clearEdited,
  };
}
