import {
  CellAdvancedDisplayNameFunc,
  CellRegistryItem,
} from "@/cells/cell.types";
import { tradingBreakdownCellApiSlice } from "./tradingBreakdownCell.slice";
import {
  additionalDataSchema,
  configSchema,
  ConfigType,
} from "./tradingBreakdownCell.models";
import { TradingBreakdownCell } from "./TradingBreakdownCell";
import { TradingBreakdownCellExtraConfig } from "./TradingBreakdownCellExtraConfig";
import * as PollRate from "@/cell-config-fields/registry/poll_rate_config_field";
import * as BaseCurrency from "@/cell-config-fields/registry/base_currency_config_field";
import { Nullish } from "@/utilities";
import * as Utilities from "@/utilities";
// export { TradingBreakdownCell as Cell } from "./TradingBreakdownCell";
// export { TradingBreakdownCellExtraConfig as ExtraConfig } from "./TradingBreakdownCellExtraConfig";
export type {
  ConfigType,
  AdditionalDataType,
} from "./tradingBreakdownCell.models";
// export {
//   configSchema,
//   additionalDataSchema,
// } from "./tradingBreakdownCell.models";

export const identifier = "trading_breakdown_cell";

export const storeReducer = tradingBreakdownCellApiSlice.reducer;
export const storeReducerPath = tradingBreakdownCellApiSlice.reducerPath;
export const storeMiddleware = tradingBreakdownCellApiSlice.middleware;

export const advancedDisplayName: CellAdvancedDisplayNameFunc<ConfigType> = (
  explicitConfig,
  dashboardLevelDefaultConfig,
  globalLevelDefaultConfig
) => {
  const baseCurrencyStr: BaseCurrency.BaseCurrency | Nullish =
    (explicitConfig?.baseCurrency ??
      dashboardLevelDefaultConfig?.baseCurrency ??
      globalLevelDefaultConfig?.baseCurrency) as
      | BaseCurrency.BaseCurrency
      | Nullish;
  if (!Utilities.isNullish(baseCurrencyStr))
    return `Trading Breakdown (${baseCurrencyStr})`;
  else return "Trading Breakdown";
};

export const cellRegistryItem: CellRegistryItem<ConfigType, any> = {
  CellComponent: TradingBreakdownCell,
  CellExtraConfigComponent: TradingBreakdownCellExtraConfig,
  configSchema: configSchema,
  additionalDataSchema: additionalDataSchema,
  advancedDisplayName: advancedDisplayName,
  commonConfigFields: [PollRate.configField, BaseCurrency.configField],
  globalLevelDefaultConfigs: {
    pollRate: 60 * 1000,
    baseCurrency: "BTC"
  },
};
