import React, { FunctionComponent } from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  Select,
} from "@chakra-ui/react";
import {
  DashboardCollaboratorType,
  DASHBOARD_COLLABORATOR_TYPE_VALUES,
} from "@/models/dashboard/dashboard-collaborator-type.model";
import * as DashboardCollaboratorTypeModel from "@/models/dashboard/dashboard-collaborator-type.model";
import { Dashboard } from "@/models/dashboard/dashboard.model";
import { useAuth } from "@/hooks/auth/auth.hooks";
import { useForm, ValidateResult } from "react-hook-form";
import * as Validators from "@/validators";
import { MdAdd } from "react-icons/md";

interface CollaboratorAddInputs {
  email: string;
  collaborationType: DashboardCollaboratorType;
}

export interface Props {
  dashboard: Dashboard;
  onCollaboratorAdd?: (
    email: string,
    collaborationType: DashboardCollaboratorType
  ) => unknown;
}

const EMAIL_INPUT_ID = "collaborator-add-form-email-input";
const COLLABORATOR_TYPE_INPUT_ID = "collaborator-add-form-collab-type-input";

export const CollaboratorAddInput: FunctionComponent<Props> = (props) => {
  const { dashboard, onCollaboratorAdd } = props;

  const { currUser } = useAuth();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<CollaboratorAddInputs>();

  const onSubmitHandler = async (values: CollaboratorAddInputs) => {
    if (onCollaboratorAdd)
      onCollaboratorAdd(values.email, values.collaborationType);
    reset();
  };

  const validateEmailNotCollaborator = (email: string): ValidateResult => {
    const ERROR_MESSAGE = "Email already is a collaborator.";
    if (email === dashboard.owner) return ERROR_MESSAGE;
    const isCollaborator = dashboard.collaborators.some(
      (collaborator) => email === collaborator.email
    );
    if (isCollaborator) return ERROR_MESSAGE;
    return undefined;
  };

  return (
    <Flex
      flexDirection="row"
      width="100%"
      as="form"
      onSubmit={handleSubmit(onSubmitHandler)}
      noValidate
    >
      {/* Email input */}
      <FormControl
        isInvalid={!!errors.email}
        flexShrink={1}
        flexGrow={2}
        marginRight="1.5"
      >
        <Input
          id={EMAIL_INPUT_ID}
          placeholder="Enter Email..."
          {...register("email", {
            required: "Email is required",
            validate: {
              email: Validators.isEmail(),
              emailNotCollaborator: validateEmailNotCollaborator,
            },
          })}
          type="email"
          isDisabled={isSubmitting}
        />
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>

      {/* Collaboration type input */}
      <FormControl
        isInvalid={!!errors.collaborationType}
        flexShrink={2}
        flexGrow={1}
        marginRight="1.5"
      >
        <Select
          id={COLLABORATOR_TYPE_INPUT_ID}
          {...register("collaborationType", {
            required: "Required",
          })}
          disabled={isSubmitting}
        >
          {DASHBOARD_COLLABORATOR_TYPE_VALUES.map((type, idx) => (
            <option key={`${type}-${idx}`} value={type}>
              {DashboardCollaboratorTypeModel.toDisplayString(type)}
            </option>
          ))}
        </Select>
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>

      <IconButton
        aria-label="Add Collaborator"
        icon={<MdAdd size="1.5rem" />}
        type="submit"
        colorScheme="dashboard-primary-button"
        isDisabled={isSubmitting}
      />
    </Flex>
  );
};
