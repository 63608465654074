import { CellRegistryItem } from "@/cells/cell.types";
import { RandomNumberCell } from "./RandomNumberCell";
import { configSchema, ConfigType } from "./randomNumberCell.models";
import { randomNumberCellApiSlice } from "./randomNumberCell.slice";
import { RandomNumberCellExtraConfig } from "./RandomNumberCellExtraConfig";
import * as PollRate from "@/cell-config-fields/registry/poll_rate_config_field";
export type { ConfigType } from "./randomNumberCell.models";

export const identifier = "random_number_cell";

export const storeReducer = randomNumberCellApiSlice.reducer;
export const storeReducerPath = randomNumberCellApiSlice.reducerPath;
export const storeMiddleware = randomNumberCellApiSlice.middleware;

export const cellRegistryItem: CellRegistryItem<ConfigType, any> = {
  CellComponent: RandomNumberCell,
    CellExtraConfigComponent: RandomNumberCellExtraConfig,
    configSchema: configSchema,
    popupWindowSizeModifiers: {
      width: 1/4,
      height: 1/4,
    },
    commonConfigFields: [
      PollRate.configField,
    ],
    globalLevelDefaultConfigs: {
      pollRate: 15 * 1000,
      rangeMin: 0,
      rangeMax: 100,
    }
};