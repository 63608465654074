import React, { FunctionComponent } from "react";
import { Text } from "@chakra-ui/react";
import {
  ConfirmationModal,
  Props as ConfirmationModalProps,
} from "../ConfirmationModal/ConfirmationModal";
import { useAppToasts } from "@/hooks/toasts/useAppToasts";
import { PermissionGrant } from "@/models/permissions/permission-grant.model";
import { useRevokePermissionMutation } from "@/store/permissions/permissions.slice";

export interface Props
  extends Omit<
    ConfirmationModalProps,
    "renderContent" | "confirmationButtonOnClick"
  > {
  permission: PermissionGrant;
}

export const PermissionRevokeModal: FunctionComponent<Props> = (props) => {
  const { permission, ...rest } = props;

  const [revokePermission] = useRevokePermissionMutation();

  const { errorToast } = useAppToasts();

  const confirmationButtonOnClick = async () => {
    try {
      await revokePermission(permission.email).unwrap();

      return true;
    } catch (err) {
      errorToast("Unable to revoke permission.");

      return false;
    }
  };

  return (
    <ConfirmationModal
      title="Revoke Permission"
      confirmationButtonColorScheme="red"
      confirmationButtonContent="Revoke"
      isCentered
      {...rest}
      confirmationButtonOnClick={confirmationButtonOnClick}
      renderContent={() => (
        <Text as="span">
          Revoke permissions for{" "}
          <Text fontWeight="bold" as="span">
            {permission.email}
          </Text>
          ?
        </Text>
      )}
    />
  );
};
