import React, { FunctionComponent } from "react";
import { Box, Flex, IconButton, Text, Tooltip } from "@chakra-ui/react";
import { MdExitToApp } from "react-icons/md";
import { useAuth } from "@/hooks/auth/auth.hooks";

export const AuthenticationControls: FunctionComponent = (props) => {
  const { currUser, logout } = useAuth(false);

  if (!currUser) return null;

  return (
    <Flex 
      flexGrow={0}
      flexShrink={1}
      flexDirection="row"
      alignItems="center"
      minWidth={0}
    >
      <Tooltip label={currUser.email}>
        <Text 
          color="gray.200" 
          fontSize="sm" 
          flexGrow={0}
          flexShrink={1}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          marginRight="2"
        >
          {currUser.email}
        </Text>
      </Tooltip>
      <Tooltip label="Logout">
        <IconButton
          aria-label="Logout"
          colorScheme="transparent"
          size="sm"
          variant="ghost"
          color="white"
          onClick={logout}
          icon={<MdExitToApp size="1.4em"/>}
          flexGrow={0}
          flexShrink={0}
        />
      </Tooltip>
    </Flex>
  );
};
