import { User } from "@/models/user/user.model";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authenticationApi } from "@/api/authentication.api";
import { FunctionComponent, useEffect } from "react";
import { useAppDispatch } from "@/hooks/store/store.hooks";
import { RootState } from "../store";
import firebase from "firebase/app";

const TOKEN_REFRESH_RATE = 1000 * 60 * 5; // 5 minutes

export type AuthStatus =
  | "uninitialized"
  | "unauthenticated"
  | "authenticating"
  | "authenticated";

interface AuthState {
  status: AuthStatus;
  currUser: User | null;
  token: string | null;
}

interface EmailPasswordPayload {
  email: string;
  password: string;
}

interface AuthUserChangedPayload {
  user: User | null;
  token: string | null;
}

const initialState: AuthState = {
  status: "uninitialized",
  currUser: null,
  token: null,
};

export const AuthManager: FunctionComponent = ({ children }) => {
  const dispatch = useAppDispatch();

  const getAuthToken = async () => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return token ?? null;
  };

  // Listen for changes to the authenticated user
  useEffect(() => {
    // Update the user every time it changes and fetch its token
    const unsubscribe = authenticationApi.onAuthStateChanged(async (user) => {
      const token = await getAuthToken();

      dispatch(authUserChanged({ user, token }));
    });
    return unsubscribe;
  }, [dispatch]);

  // Periodically refresh the authentication token
  useEffect(() => {
    const refreshId = setInterval(async () => {
      const token = await getAuthToken();
      dispatch(tokenChanged(token));
    }, TOKEN_REFRESH_RATE);

    return () => clearInterval(refreshId);
  }, [dispatch]);

  return <>{children}</>;
};

// --- Async Thunks ---

export const signupWithEmailPassword = createAsyncThunk(
  "auth/signupWithEmailPassword",
  async ({ email, password }: EmailPasswordPayload) => {
    try {
      const user = await authenticationApi.signupWithEmailPassword(
        email,
        password
      );

      return user;
    } catch (err) {
      console.log(`signupWithEmailPassword failed: ${err}`);

      throw err;
    }
  }
);

export const loginWithEmailPassword = createAsyncThunk(
  "auth/loginWithEmailPassword",
  async ({ email, password }: EmailPasswordPayload) => {
    try {
      const user = await authenticationApi.loginWithEmailPassword(
        email,
        password
      );

      return user;
    } catch (err) {
      console.log(`loginWithEmailPassword failed: ${err}`);

      // Rethrow error
      throw err;
    }
  }
);

export const loginWithGoogle = createAsyncThunk(
  "auth/loginWithGoogle",
  async () => {
    try {
      const user = await authenticationApi.loginWithGoogle();

      return user;
    } catch (err) {
      console.log(`loginWithGoogle failed: ${err}`);

      // Rethrow error
      throw err;
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    await authenticationApi.logout();
  } catch (err) {
    console.log(`logout failed: ${err}`);

    // Rethrow error
    throw err;
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authUserChanged(state, action: PayloadAction<AuthUserChangedPayload>) {
      const { user, token } = action.payload;

      state.currUser = user;
      state.token = token;
      state.status = user !== null ? "authenticated" : "unauthenticated";
    },
    tokenChanged(state, action: PayloadAction<string | null>) {
      const token = action.payload;

      state.token = token;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(signupWithEmailPassword.pending, (state, action) => {
        state.status = "authenticating";
      })
      .addCase(signupWithEmailPassword.rejected, (state, action) => {
        state.status = "unauthenticated";
        state.currUser = null;
      })
      .addCase(loginWithEmailPassword.pending, (state, action) => {
        state.status = "authenticating";
      })
      .addCase(loginWithEmailPassword.rejected, (state, action) => {
        state.status = "unauthenticated";
        state.currUser = null;
      })
      .addCase(loginWithGoogle.pending, (state, action) => {
        state.status = "authenticating";
      })
      .addCase(loginWithGoogle.rejected, (state, action) => {
        state.status = "unauthenticated";
        state.currUser = null;
      });
  },
});

// --- Selectors ---

export const selectAuthData = (state: RootState) => state.auth;

// --- Actions ---

export const { authUserChanged, tokenChanged } = authSlice.actions;
