import React, { FunctionComponent } from "react";
import { Flex, Link } from "@chakra-ui/react";
import { AuthenticationControls } from "./components/AuthenticationControls";
import {Link as RouterLink} from "react-router-dom";
import { useAppNavigator } from "@/hooks/navigation/use-app-navigator";

interface Props {}

export const AppHeader: FunctionComponent<Props> = (props) => {
  const { homePath } = useAppNavigator();

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      padding="3"
      backgroundColor="app-blue.primary"
    >
      <Link 
        as={RouterLink} 
        to={homePath()} 
        color="gray.100"
        colorScheme="transparent"
        _focus={{
          outline: "none"
        }}
        _hover={{
          textDecoration: "none"
        }}
        flexGrow={0}
        flexShrink={0}
        marginRight="2"
      >
        Skew Dashboard
      </Link>
      <AuthenticationControls />
    </Flex>
  );
};
