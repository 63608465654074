import React, { FunctionComponent } from "react";
import { Flex } from "@chakra-ui/react";
import { AppHeader } from "@/components/nav/AppHeader/AppHeader";

interface Props {}

export const AppLayout: FunctionComponent<Props> = (props) => {
  return (
    <Flex flexDirection="column" height="100%">
      <AppHeader />
      {props.children}
    </Flex>
  );
};
