import { extendTheme } from "@chakra-ui/react";
import Color from "color";

export default extendTheme({
  colors: {
    "app-background": "#FAFAFF",
    "app-blue": {
      "tertiary": "#3A4353",
      "secondary": "#2B3546",
      "primary": "#050b14",
    },
    "app-light-blue": "#B2D6FF",
    "app-light-bg-blue": "#DEEFFF",
    "transparent": {
      50: "rgba(0, 0, 0, 0)",
      100: "rgba(0, 0, 0, 0)",
      200: "rgba(0, 0, 0, 0)",
      300: "rgba(0, 0, 0, 0)",
      400: "rgba(0, 0, 0, 0)",
      500: "rgba(0, 0, 0, 0)",
      600: "rgba(0, 0, 0, 0)",
      700: "rgba(0, 0, 0, 0)",
      800: "rgba(0, 0, 0, 0)",
      900: "rgba(0, 0, 0, 0)",
    },
    "dashboard-selection-button": {
      50: "#3F4F6A",
      100: Color("#2B3546").lighten(0.45).rgb().toString(),
      200: Color("#2B3546").lighten(0.4).rgb().toString(),
      300: Color("#2B3546").lighten(0.35).rgb().toString(),
      400: Color("#2B3546").lighten(0.3).rgb().toString(),
      500: "#2B3546",
      600: Color("#2B3546").darken(0.3).rgb().toString(),
      700: Color("#2B3546").darken(0.35).rgb().toString(),
      800: Color("#2B3546").darken(0.4).rgb().toString(),
      900: Color("#2B3546").darken(0.45).rgb().toString(),
    },
    "dashboard-selection-dropdown": {
      "primary": "#434D5F",
      "secondary": Color("#434D5F").lighten(0.2).rgb().toString(),
    },
    "dashboard-primary-button": {
      50: "#5175B4",
      100: Color("#375485").lighten(0.45).rgb().toString(),
      200: Color("#375485").lighten(0.4).rgb().toString(),
      300: Color("#375485").lighten(0.35).rgb().toString(),
      400: Color("#375485").lighten(0.3).rgb().toString(),
      500: "#375485",
      600: Color("#375485").darken(0.3).rgb().toString(),
      700: Color("#375485").darken(0.35).rgb().toString(),
      800: Color("#375485").darken(0.4).rgb().toString(),
      900: Color("#375485").darken(0.45).rgb().toString(),
    },
    "page-scrollbar": {
      "track": "#f1f1f1",
      "thumb": "#888",
      "thumb-hover": "#555",
    },
    "inline-scrollbar": {
      "track": Color("#2B3546").darken(0.1).rgb().toString(),
      "thumb": "#45546E",
      "thumb-hover": Color("#45546E").lighten(0.4).rgb().toString(),
    }
  },
  fonts: {
    heading: "'Inter', sans-serif",
    body: "'Inter', sans-serif",
  },
  styles: {
    global: {
      body: {
        height: "100%",
      },
      html: {
        height: "100%",
      },
      "#root": {
        height: "100%",
      },
    },
  },
});
