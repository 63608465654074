import React, { FunctionComponent } from "react";
import { Center, Spinner } from "@chakra-ui/react";
import { useAuth } from "@/hooks/auth/auth.hooks";

/**
 * A wrapper component that renders a spinner while the authentication context
 * is uninitialized (i.e. the persistence is being loaded in).
 */
export const AuthenticationInitializationLoader: FunctionComponent = (
  props
) => {
  const { status } = useAuth(false);

  if (status === "uninitialized")
    return (
      <Center height="100%" width="100%">
        <Spinner size="lg" />
      </Center>
    );

  return <>{props.children}</>;
};
